import React from 'react';
import { Safety } from '../Home/Components/Safety';
import { Footer,Navbar } from '../../common';
import { Container, Typography, Box,Grid } from '@mui/material';
import img from "./aboutus.jpg"

export const Aboutus = () => {
  return (
        <div className='bg-main'>
            <div className='home-body overflow-hidden'>
            <Navbar isSearchVisible={false}/>       
            </div>
            <div className='px-3 py-2'>
        
            <Container maxWidth="xl" sx={{ marginTop: 4}}>
            <Typography variant="h2" style={{fontWeight:500}} gutterBottom>
                Hakkımızda
            </Typography>
                <Grid container spacing={5} className='mb-4'>
                <Grid item xs={12} md={6} >
                    <Box >
                        <Typography style={{textAlign:'justify',fontWeight:500}} variant="body1" paragraph>
                        Samet, Antalya'daki Korsan Koyu'nu keşfetti ve burada kamp yapmaya karar verdi. Koyda denize girebileceği ve çadırını kurabileceği bir kamp yeri bulmak için birçok web sitesinde saatlerini harcadı. Ancak, kamp alanını bulduktan sonra beklenmedik bir sorunla karşılaştı. Koyun yolunun bozuk ve taşlık olduğunu bilmiyordu ve bu detayı öğrenmek için lastiğinin patlamasını beklemek zorunda kaldı. Lastiği değiştirdikten sonra kamp alanına ulaştığında, internetten edindiği bilgilerin eksik olduğunu fark etti. Kamp alanı sahibiyle konuştuğunda, rezervasyon sisteminin basit ve etkisiz olduğunu ve bu yüzden yıllık %25 gelir kaybına neden olan rezervasyon takibi, fazlalığı gibi aksaklıkları yaşadıklarını öğrendi. Bu hayal kırıklığıyla birlikte, Samet doğayla bağlantı kurmak isteyen insanlara yardımcı olacak ve doğru kamp noktalarını bulmalarını sağlayacak bir teknoloji geliştirmeye karar verdi.
                        </Typography>
                        <Typography style={{textAlign:'justify',fontWeight:500}} variant="body1" paragraph>
                        Kamplasana, doğa severlerin kamp deneyimlerini uçtan uca optimize etmeyi amaçlayan bir platformdur. Doğa severler için kamp alanları ve kamp turları bulma, rezervasyon yapma ve kamp deneyimlerini kişiselleştirme sürecini kolaylaştırmayı hedefler.
                        </Typography>
                        <Typography style={{textAlign:'justify',fontWeight:500}} variant="body1" paragraph>
                        Platformun temel özellikleri arasında kamp alanları, kamp turları, glampingler, bungalovların listelenmesi, rezervasyon işlemleri, kullanıcı hesap yönetimi ve yapay zeka destekli kamp önerileri yer alır. Kamplasana, kullanıcıların ilgi alanlarına, konumlarına ve geçmiş deneyimlerine göre kişiselleştirilmiş kamp önerileri sunarak, kullanıcıların en uygun kamp alanlarını bulmalarına yardımcı olur. Ayrıca, platformda etkileşimli bir topluluk bulunur, bu da doğa severlerin birbirleriyle iletişime geçmesini, beraber kamp yapmasını, deneyimlerini paylaşmasını ve önerilerde bulunmasını sağlar.
                        </Typography>
                        <Typography style={{textAlign:'justify',fontWeight:500}} variant="body1" paragraph>
                        Kamplasana'nın sağladığı çözümler sayesinde, doğa severler kamp deneyimlerini daha verimli ve keyifli hale getirebilirler. Kullanıcılar, platform üzerinden kamp alanları ve turları arasında kolayca gezinebilir, tercih ettikleri kamp alanlarını rezerve edebilir ve kişisel tercihlerine göre özelleştirilmiş kamp önerileri alabilirler. Ayrıca, etkileşimli topluluk sayesinde doğa severler birbirleriyle iletişime geçebilir, deneyimlerini paylaşabilir ve kamp yapmak istedikleri kişilerle buluşabilirler.
                        </Typography>
                        <Typography style={{textAlign:'justify',fontWeight:500}} variant="body1" paragraph>
                        Kamplasana'nın amacı, doğa severlerin kamp deneyimlerini daha kolay ve keyifli hale getirerek doğayla olan bağlarını güçlendirmektir. Platform, kullanıcıların doğa içinde geçirdikleri zamanı optimize ederek, unutulmaz kamp deneyimleri yaşamalarını sağlar. Bu sayede, doğa severlerin doğayla olan bağlarını güçlendirmeleri ve kamp deneyimlerinden maksimum fayda sağlamaları hedeflenir.
                        </Typography>
                    </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <Box
                        component="img"
                        src={img}
                        alt="Camping"
                        sx={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: 2,
                        boxShadow: 3,
                        }}
                    />
                    </Grid>
                    
                </Grid>
            </Container>
            </div>
            <div className='bg-lgreen py-5'>
                <div className='home-body overflow-hidden'>
                    <Safety/>
                </div>
            </div>
            <div className='bg-light'>
                <div className='home-body overflow-hidden'>
                </div>    
            </div>
            <Footer current={''}/>

    </div>
  )
}
