import React, { useEffect } from 'react'
import {useState,useCallback} from 'react';
import { DateRange } from 'react-date-range';
import { useNavigate } from 'react-router-dom';
import Autocomplete from '@mui/joy/Autocomplete';
import PlaceIcon from '@mui/icons-material/Place';
import { useTranslation } from "react-i18next";
import { formatDate, formatDateMonth, getDate} from '../../../utils';
import { GetCities } from '../../../services/GetCities';


export const Hero = () => {
    let navigate = useNavigate();
    const [date, setDate] = useState([
        {
          startDate: new Date(),
          endDate: null,
          key: 'selection'
        }
      ]);
    const [openDate, setOpenDate] = useState(false);
    const { t, i18n } = useTranslation();
    const [formatted,setformated] = useState(t('AddDate'));
    const [open, setOpen] = React.useState(false);
    const [value,setValue] = useState('');
    const [checkin,setCheckin] = useState('');
    const [checkout,setCheckout] = useState('');
    const [cities,setCities] = useState([]);
    

    useEffect(() => {
      setformated(t('AddDate'));
    }, [t])
    useEffect(() => {
        async function setAsyncValue() {
            let response = await GetCities();
            setCities(response);
        }
        setAsyncValue();
    },[])

    function handleDateChange(item){
        setDate([item.selection]);
        let x = formatDateMonth([item.selection][0].startDate);
        let y = formatDateMonth([item.selection][0].endDate);
        setformated(getDate(x,y));
        setCheckin(formatDate([item.selection][0].startDate));
        setCheckout(formatDate([item.selection][0].endDate));
        if(x===y ){
            setOpenDate(true);
        }
        else{
            setOpenDate(false);
        }  
    }
    return (
        <div>
            <div className='container mt-5'>
                <div className='search-pt'>
                    <div className='search-bar'>
                        <div className='container-fluid'>
                        <div className="row ">
                        <div className="text-hero-cont">
                            <h2 className="text-hero-h2">{t('FirstHeroTitle')}</h2>
                        </div>
                        <div className="mb-4">
                            <div className="text-hero-cont-sub">
                                <div className="ahmos">
                                    <h1 className="text-hero-h1">{t('FirstHeroSubTitle')}</h1>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div  style={{cursor:'pointer'}} className='search-bar-cont row'>
                            <div className="lsItem p-3 col-sm-12 col-md-4 col-12">
                                    <button onClick={()=>{setOpenDate(!openDate)}} className='date-btn gTOYSM'>
                                        <div style={{   pointerEvents:'none'}}>
                                                <label className="iFVbKH">
                                                    <div className="kxnURc">
                                                        <div width="25px" className="hizCvo">
                                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="eOjNfz" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm436-44v-36c0-26.5-21.5-48-48-48h-48V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H160V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v36c0 6.6 5.4 12 12 12h424c6.6 0 12-5.4 12-12z"></path>
                                                            </svg>
                                                        </div>
                                                        <div display="flex" className="iBgmKo">
                                                            <div color="#666" className="dCwTKI">
                                                                <span className=" fBSHxh">{formatted}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                                <div display="flex" className="esbamx"></div>
                                        </div>
                                    </button>
                                    <div className='datepicker'>
                                    {openDate && (
                                        <DateRange 
                                            onChange={handleDateChange}
                                            minDate={new Date()}
                                            moveRangeOnFirstSelection={false}
                                            ranges={date}
                                            rangeColors={['#d66']}
                                            dis
                                        />
                                    )}
                                    </div>
                                
                            </div>
                            <div className='lsItem p-3 col-sm-12  col-md-4 col-12'>
                            <Autocomplete
                              startDecorator={<PlaceIcon />}
                                placeholder={t('SearchLocation')}
                                options={cities}
                                getOptionLabel={(option) => option.text}
                                onInputChange={(_, value) => {
                                    if (value.length === 0) {
                                    if (open) setOpen(false);
                                    } else {
                                    if (!open) setOpen(true);
                                    setValue(value)
                                    }
                                }}
                                onClose={() => setOpen(false)}
                                sx={{ width: 'auto', height: '60px', borderColor: '#adada6' }}
                            />
                            </div>
                            <div className='lsItem p-3 col-sm-12 col-md-4 col-12'>
                                <button onClick={()=>{
                                    localStorage.setItem('title',value);
                                    navigate(`../camps?province=${value}&checkin=${checkin}&checkout=${checkout}`); 
                                }} aria-label="Search" display="flex" height="fit-content" type="submit" className="jasfVq w-100  haJtUJ">
                                    <div display="flex" color="primary" className="dRHBlY iZFMpY fOtxOz">
                                        <svg width="32px" height="32px" viewBox="0 0 24 24" fill="white" xmlns="https://www.w3.org/2000/svg" color="white"><path fillRule="evenodd" clipRule="evenodd" d="M13.1578 14.5722C12.1067 15.3197 10.8214 15.7592 9.43346 15.7592C5.88036 15.7592 3 12.8788 3 9.32573C3 5.77263 5.88036 2.89227 9.43346 2.89227C12.9866 2.89227 15.8669 5.77263 15.8669 9.32573C15.8669 10.7691 15.3916 12.1015 14.5889 13.1749L20.0709 18.6569C20.4614 19.0474 20.4614 19.6806 20.0709 20.0711C19.6804 20.4617 19.0472 20.4617 18.6567 20.0711L13.1578 14.5722ZM13.8669 9.32573C13.8669 11.7743 11.882 13.7592 9.43346 13.7592C6.98493 13.7592 5 11.7743 5 9.32573C5 6.8772 6.98493 4.89227 9.43346 4.89227C11.882 4.89227 13.8669 6.8772 13.8669 9.32573Z" fill="currentColor"></path>
                                        </svg>
                                        <span className="sc-bdvvtL sc-gKclnd  gbEozA">{t('Search')}</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                        </div>

            
                    </div>
                </div>
                <div className="img-pt">
                    <span className='img-cont' >
                        <img className='hero-image w-100 img-fluid' alt="Tent on a cliffside overlooking the pacific ocean" sizes="100vw" 
                         src='https://cdn.pixabay.com/photo/2017/08/02/00/08/camping-2568900_1280.jpg'/>
                    </span>
                </div>
            </div>
            <div className='hero-sm'>
                <div>

                </div>
            </div>
        </div>
    )
}