import React, { useState } from 'react';
import { Footer,Navbar} from '../../common';
import { useNavigate } from "react-router-dom";
import Face6Icon from '@mui/icons-material/Face6';
import Face5Icon from '@mui/icons-material/Face5';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import BadgeIcon from '@mui/icons-material/Badge';
import { Domain } from '../../Domain';
import {Button,Modal} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isAtLeast16YearsOld } from '../../utils';

export const SignupHost = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [name,setName] = useState('');
    const [surname,setSurname] = useState('');
    const [password,setPassword] = useState('');
    const [confirm,setConfirm] = useState('');
    const [birthdate,setBirthdate] = useState(new Date());
    const [iban,setIban] = useState('TR');
    const [bankName,setBankName] = useState('');
    const [NID,setNationalId] = useState('');
    const [email,setEmail]= useState('');
    const prefix = "TR";

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        window.location.reload();
    }
    const handleShow = () => setShow(true);
    const [success,setSuccess] = useState(false);
    const [message,setMessage] = useState('');

    const allowSubmit = ()=>{
        if(name.length < 3){
            return false;
        }
        else if(surname.length<3){
            return false;

        }
        else if(password.length<8){
            return false;

        }
        else if(confirm.length<8){
            return false;
        }
        else if(!validateEmail(email)){
            return false;
        }
        else if(password !== confirm){
            return false
        }
        else if(NID.length !== 11 ){
            return false;
        }
        else if(iban.length !== 26){
            return false;
        }
        else if(bankName.length < 3 ){
            return false;
        }
        else if(!isAtLeast16YearsOld(birthdate)){
            return false
        }
        else{
            return true;
        }
    }

    const handleChange = (e) => {
        const inputValue = e.target.value;
        // Remove "TR" if it's already in the input to handle only the numeric part
        let numericPart = inputValue.replace(/^TR/, '');

        // Ensure the remaining part is numeric
        if (/^\d*$/.test(numericPart)) {
            setIban("TR" + numericPart);
        }
    };
    const handleTCChange = (e) => {
        const inputValue = e.target.value;

        // Only allow numbers
        if (/^\d*$/.test(inputValue)) {
            setNationalId(inputValue);
        }
    };


   const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };

    const Signup = ()=>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        var raw = JSON.stringify({
            "firstName": name,
            "lastName": surname,
            "email": email,
            "password": password,
            "passwordConfirmation": confirm,
            "role": "campingSeller",
            "birthday": birthdate,
            "bankAcountName":bankName,
            "IBAN": iban,
            "nationalID": NID,
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${Domain}/api/users`, requestOptions)
        .then(response => response.json())
        .then(result =>{
            if(result.email){
                setMessage(t('AccountCreatedMessage'));
                setSuccess(true);
                handleShow();
            }
            else{
                setMessage(t('ErrorMessage'));
                setSuccess(false);
                handleShow();
            }
        })
        .catch(error => {
            setMessage(t('ErrorMessage'));
            setSuccess(false);
            handleShow();
        });
    }
    return (
            <div className='login-area bg-retarded'>
                <div className="guest-area-top">
                    <div className="container">
                        <div className="guest-header">
                            <Navbar isSearchVisible={false}/>
                            <div className="guest-top-title mt-2 p-2 ">{t('SignupHeader')}</div>
                            <p className='p-2'>{t('Signup8')}</p>
                        </div>
                    </div>
                </div>
                <div  className="container mb-3">
                    <div className="row gx-0">
                        <div className="col-lg-6 order-1">
                            <div className="login-left-area">
                                <span style={{fontWeight:500}}>{t('Signup9')}</span>
                                <div className="login-left-title">{t('Signup10')}</div>
                                <a  onClick={()=>{navigate('../signup-camper')}} className="button brown large"> {t('Signup6btn')}</a>
                            </div>
                        </div>
                        <div className="col-lg-6 order-2">
                            <div className="login-right-area">
                                <div className="guest-login-area">
                                    <div className="guest-login-body">
                                    <div className="form-area">
                                            <p>{t("FirstName")}</p>
                                            <input type="text" onChange={(e)=>{setName(e.target.value)}} placeholder={t('placeHolderFirstname')} className="mat-input-element mat-form-field-autofill-control form-text cdk-text-field-autofill-monitored cdk-text-field-autofilled"/>
                                            <Face5Icon className='mt-3'/>
                                            { <>{name.length>2 ? <></> :<span style={{color: "red"}}>{t('warningName')} </span> }</>}
                                        </div>
                                        <div className="form-area">
                                            <p>{t("LastName")}</p>
                                            <input type="text" onChange={(e)=>{setSurname(e.target.value)}} placeholder={t('placeHolderLastname')} className="mat-input-element mat-form-field-autofill-control form-text cdk-text-field-autofill-monitored cdk-text-field-autofilled"/>
                                            <Face6Icon className='mt-3'/>
                                            { <>{surname.length>2 ? <></> :<span style={{color: "red"}}>{t('warningName')}</span> }</>}
                                        </div>
                                        <div className="form-area">
                                            <p>{t("mail1")}</p>
                                            <input type="text" onChange={(e)=>{setEmail(e.target.value.toLowerCase())}} placeholder={t('placeHolderEmail')} matinput="" formcontrolname="email" className="mat-input-element mat-form-field-autofill-control form-text cdk-text-field-autofill-monitored cdk-text-field-autofilled"/>
                                            <svg style={{width:24,height:24}} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                <path d="M5.66634 5.6665H28.333C29.8913 5.6665 31.1663 6.9415 31.1663 8.49984V25.4998C31.1663 27.0582 29.8913 28.3332 28.333 28.3332H5.66634C4.10801 28.3332 2.83301 27.0582 2.83301 25.4998V8.49984C2.83301 6.9415 4.10801 5.6665 5.66634 5.6665Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M31.1663 8.5L16.9997 18.4167L2.83301 8.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                            <em></em>
                                            { <>{validateEmail(email) ? <></> :<span style={{color: "red"}}>{t('warningEmail')}</span> }</>}
                                        </div>
                                        <div className="form-area">
                                            <p>{t("password21")}</p>
                                            <input type="password"  onChange={(e)=>{setPassword(e.target.value)}} placeholder="********" matinput="" formcontrolname="email" className="mat-input-element mat-form-field-autofill-control form-text cdk-text-field-autofill-monitored cdk-text-field-autofilled"/>
                                            <svg style={{width:24,height:24}}  viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                <mask id="mask0_644_1408" maskUnits="userSpaceOnUse" x="7" y="18" width="27" height="13">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M7.44531 18.5991H33.9997V30.0521H7.44531V18.5991Z" fill="currentColor"></path>
                                                </mask>
                                                <g mask="url('/giris#mask0_644_1408')">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M11.5457 20.7527C10.4641 20.7527 9.58394 21.6398 9.58394 22.7293V25.9232C9.58394 27.0117 10.4641 27.8998 11.5457 27.8998H29.8978C30.9795 27.8998 31.8615 27.0122 31.8615 25.9232V22.7293C31.8615 21.6403 30.98 20.7527 29.8978 20.7527H11.5457ZM29.8983 30.0525H11.5457C9.28451 30.0525 7.44531 28.1993 7.44531 25.9227V22.7289C7.44531 20.4523 9.28451 18.5991 11.5457 18.5991H29.8978C32.1595 18.5991 34.0001 20.4523 34.0001 22.7289V25.9227C34.0001 28.1993 32.1595 30.0525 29.8983 30.0525Z" fill="currentColor"></path>
                                                </g>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.6602 22.8228C14.4852 22.8228 15.1544 23.496 15.1544 24.3276C15.1544 25.1582 14.4852 25.8314 13.6602 25.8314C12.8352 25.8314 12.166 25.1582 12.166 24.3276C12.166 23.496 12.8352 22.8228 13.6602 22.8228Z" fill="currentColor"></path>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M18.1875 22.8228C19.0126 22.8228 19.6817 23.496 19.6817 24.3276C19.6817 25.1582 19.0126 25.8314 18.1875 25.8314C17.3625 25.8314 16.6934 25.1582 16.6934 24.3276C16.6934 23.496 17.3625 22.8228 18.1875 22.8228Z" fill="currentColor"></path>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M22.7139 22.8203C23.5394 22.8203 24.209 23.494 24.209 24.3256C24.209 25.1572 23.5394 25.8309 22.7139 25.8309C21.8879 25.8309 21.2188 25.1572 21.2188 24.3256C21.2188 23.494 21.8879 22.8203 22.7139 22.8203Z" fill="currentColor"></path>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M27.2422 22.8228C28.0663 22.8228 28.7354 23.4955 28.7354 24.3257C28.7354 25.1568 28.0667 25.8309 27.2422 25.8309C26.4153 25.8309 25.7471 25.1568 25.7471 24.3257C25.7471 23.4955 26.4153 22.8228 27.2422 22.8228Z" fill="currentColor"></path>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.8675 11.9069C13.2773 11.9069 12.7982 11.4246 12.7982 10.8304V6.06998C12.7982 3.90965 11.0536 2.15315 8.90787 2.15315C6.76214 2.15315 5.01751 3.90965 5.01751 6.06998V10.8304C5.01751 11.4246 4.53846 11.9069 3.94821 11.9069C3.35795 11.9069 2.87891 11.4246 2.87891 10.8304V6.06998C2.87891 2.72254 5.58258 0 8.90787 0C12.2332 0 14.9368 2.72206 14.9368 6.06998V10.8304C14.9368 11.4251 14.4578 11.9069 13.8675 11.9069Z" fill="currentColor"></path>
                                                <mask id="mask1_644_1408"  maskUnits="userSpaceOnUse" x="0" y="9" width="19" height="17">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M0 9.77393H18.4423V25.7584H0V9.77393Z" fill="currentColor"></path>
                                                </mask>
                                                <g mask="url('/giris#mask1_644_1408')">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M5.37978 25.7584H3.08244C1.38249 25.7584 0 24.3651 0 22.6526V12.8802C0 11.1673 1.38249 9.77393 3.08244 9.77393H15.3599C17.0599 9.77393 18.4424 11.1673 18.4424 12.8802V18.3459C18.4424 18.9401 17.9638 19.4224 17.3731 19.4224C16.7828 19.4224 16.3038 18.9401 16.3038 18.3459V12.8802C16.3038 12.3544 15.8803 11.9271 15.3599 11.9271H3.08244C2.56157 11.9271 2.1386 12.3548 2.1386 12.8802V22.6526C2.1386 23.178 2.56205 23.6053 3.08244 23.6053H5.37978C5.97003 23.6053 6.44908 24.0881 6.44908 24.6819C6.44908 25.2761 5.97003 25.7584 5.37978 25.7584Z" fill="currentColor"></path>
                                                </g>
                                            </svg>
                                            <em></em>
                                            { <>{password.length>8 ? <></> :<span style={{color: "red"}}>{t('warningPass')}</span> }</>}
                                        </div>
                                        <div className="form-area">
                                            <p>{t("ConfirmPassword")}</p>
                                            <input type="password" onChange={(e)=>{setConfirm(e.target.value)}} placeholder="********" matinput="" formcontrolname="email" className="mat-input-element mat-form-field-autofill-control form-text cdk-text-field-autofill-monitored cdk-text-field-autofilled"/>
                                            <svg style={{width:24,height:24}}  viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                <mask id="mask0_644_1408" maskUnits="userSpaceOnUse" x="7" y="18" width="27" height="13">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M7.44531 18.5991H33.9997V30.0521H7.44531V18.5991Z" fill="currentColor"></path>
                                                </mask>
                                                <g mask="url('/giris#mask0_644_1408')">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M11.5457 20.7527C10.4641 20.7527 9.58394 21.6398 9.58394 22.7293V25.9232C9.58394 27.0117 10.4641 27.8998 11.5457 27.8998H29.8978C30.9795 27.8998 31.8615 27.0122 31.8615 25.9232V22.7293C31.8615 21.6403 30.98 20.7527 29.8978 20.7527H11.5457ZM29.8983 30.0525H11.5457C9.28451 30.0525 7.44531 28.1993 7.44531 25.9227V22.7289C7.44531 20.4523 9.28451 18.5991 11.5457 18.5991H29.8978C32.1595 18.5991 34.0001 20.4523 34.0001 22.7289V25.9227C34.0001 28.1993 32.1595 30.0525 29.8983 30.0525Z" fill="currentColor"></path>
                                                </g>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.6602 22.8228C14.4852 22.8228 15.1544 23.496 15.1544 24.3276C15.1544 25.1582 14.4852 25.8314 13.6602 25.8314C12.8352 25.8314 12.166 25.1582 12.166 24.3276C12.166 23.496 12.8352 22.8228 13.6602 22.8228Z" fill="currentColor"></path>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M18.1875 22.8228C19.0126 22.8228 19.6817 23.496 19.6817 24.3276C19.6817 25.1582 19.0126 25.8314 18.1875 25.8314C17.3625 25.8314 16.6934 25.1582 16.6934 24.3276C16.6934 23.496 17.3625 22.8228 18.1875 22.8228Z" fill="currentColor"></path>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M22.7139 22.8203C23.5394 22.8203 24.209 23.494 24.209 24.3256C24.209 25.1572 23.5394 25.8309 22.7139 25.8309C21.8879 25.8309 21.2188 25.1572 21.2188 24.3256C21.2188 23.494 21.8879 22.8203 22.7139 22.8203Z" fill="currentColor"></path>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M27.2422 22.8228C28.0663 22.8228 28.7354 23.4955 28.7354 24.3257C28.7354 25.1568 28.0667 25.8309 27.2422 25.8309C26.4153 25.8309 25.7471 25.1568 25.7471 24.3257C25.7471 23.4955 26.4153 22.8228 27.2422 22.8228Z" fill="currentColor"></path>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.8675 11.9069C13.2773 11.9069 12.7982 11.4246 12.7982 10.8304V6.06998C12.7982 3.90965 11.0536 2.15315 8.90787 2.15315C6.76214 2.15315 5.01751 3.90965 5.01751 6.06998V10.8304C5.01751 11.4246 4.53846 11.9069 3.94821 11.9069C3.35795 11.9069 2.87891 11.4246 2.87891 10.8304V6.06998C2.87891 2.72254 5.58258 0 8.90787 0C12.2332 0 14.9368 2.72206 14.9368 6.06998V10.8304C14.9368 11.4251 14.4578 11.9069 13.8675 11.9069Z" fill="currentColor"></path>
                                                <mask id="mask1_644_1408"  maskUnits="userSpaceOnUse" x="0" y="9" width="19" height="17">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M0 9.77393H18.4423V25.7584H0V9.77393Z" fill="currentColor"></path>
                                                </mask>
                                                <g mask="url('/giris#mask1_644_1408')">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M5.37978 25.7584H3.08244C1.38249 25.7584 0 24.3651 0 22.6526V12.8802C0 11.1673 1.38249 9.77393 3.08244 9.77393H15.3599C17.0599 9.77393 18.4424 11.1673 18.4424 12.8802V18.3459C18.4424 18.9401 17.9638 19.4224 17.3731 19.4224C16.7828 19.4224 16.3038 18.9401 16.3038 18.3459V12.8802C16.3038 12.3544 15.8803 11.9271 15.3599 11.9271H3.08244C2.56157 11.9271 2.1386 12.3548 2.1386 12.8802V22.6526C2.1386 23.178 2.56205 23.6053 3.08244 23.6053H5.37978C5.97003 23.6053 6.44908 24.0881 6.44908 24.6819C6.44908 25.2761 5.97003 25.7584 5.37978 25.7584Z" fill="currentColor"></path>
                                                </g>
                                            </svg>
                                            <em></em>
                                            { <>{password===confirm ? <></> :<span style={{color: "red"}}>{t('warningPass2')}</span> }</>}
                                        </div>
                                        <div className="form-area">
                                            <p>{t("birthDate21")}</p>
                                            <input type="date" onChange={(e)=>{setBirthdate(e.target.value)}} className="mat-input-element mat-form-field-autofill-control form-text cdk-text-field-autofill-monitored cdk-text-field-autofilled"/>
                                            { <>{ isAtLeast16YearsOld(birthdate) ? <></> :<span style={{color: "red"}}>{t('AgeWarning')}</span> }</>}
                                        </div>
                                        <div className="form-area">
                                            <p> </p>
                                            <input type="text" onChange={(e)=>{setBankName(e.target.value);}} placeholder={t('placeHolderbankname')} className="mat-input-element mat-form-field-autofill-control form-text cdk-text-field-autofill-monitored cdk-text-field-autofilled"/>
                                            <BadgeIcon className='mt-3'/>
                                            { <>{bankName.length >= 3 ? <></> :<span style={{color: "red"}}>{t('warningBankName')}</span> }</>}
                                        </div>

                                        <div className="form-area">
                                            <p>IBAN *</p>
                                            <input 
                                                className="mat-input-element mat-form-field-autofill-control form-text cdk-text-field-autofill-monitored cdk-text-field-autofilled"
                                                type="text"
                                                maxLength={26} 
                                                onChange={handleChange}
                                                value={iban}
                                                placeholder="TR************************" 
                                            />
                                            <CreditCardIcon className='mt-3'/>
                                            { <>{iban.length === 26 ? <></> :<span style={{color: "red"}}>{t('warningIban2')}</span> }</>}
                                        </div>

                                        <div className="form-area">
                                            <p>T.C *</p>
                                            <input type="text" maxLength={11} value={NID} onChange={handleTCChange} placeholder="55798050924"  className="mat-input-element mat-form-field-autofill-control form-text cdk-text-field-autofill-monitored cdk-text-field-autofilled"/>
                                            <FingerprintIcon className='mt-3'/>
                                            { <>{NID.length === 11 ? <></> :<span style={{color: "red"}}>{t('warningID')}</span> }</>}
                                        </div>
                                        <div className="buttons between">
                                            <a href='../forget-pass' className="forgot-password">{t('forgotPassword')}</a>
                                            <button onClick={()=>{
                                                if(allowSubmit()){
                                                    Signup();
                                                }
                                            }} className="button brown large">{t('signup')}</button>
                                        </div>                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>{success ? t("SuccessTitle"):t('WarningTitle')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{message}</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("close")}
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
    )
}
