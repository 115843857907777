export function formatDate(dateStr){
    const date = new Date(dateStr);

    // Get the individual date components
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-indexed
    const year = date.getFullYear();
  
    // Pad day and month with leading zeros if needed
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;
  
    return formattedDay + '-' + formattedMonth + '-' + year;
}