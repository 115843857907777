import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const Poi = () => {
    const { t,  } = useTranslation();
    const {mapCamps,loaded} = useSelector(state => state.CampsReducer);
    const navigate = useNavigate();
    return (
        <div className='my-5 container'>
            <div className='mt-5 row'>
                <h2>{t('POIHeader')} </h2>
            </div>
            <div className='my-3 row'>
                {loaded && mapCamps.length > 0 && mapCamps.map((item)=>(
                    <div className='col-md-4 col-12 p-3'>
                        <a className='poi-a'   onClick={()=>{navigate(`../camp/${item.title}`)}}>
                <div className='poi-a-div'>
                                <span className='img-poi-span'> 
                                    <img className='img-poi' src={item.image[0].path}/>
                                </span>
                            </div>
                        </a>
                        <div className="poi-title-cont">
                            <div className="poi-title-cont-div">
                                <h3 className="poi-title">{item.title}</h3>
                                <span className="poi-subheader">{item.location.province}, {item.location.district}</span>
                            </div>
                        </div>
                    </div>

                ))
            }
            </div>
            
        </div>
    )
}
