import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useNavigate } from 'react-router-dom';
import { Domain } from '../../../Domain';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';


export default function CheckoutCard(props) {
  const [guestCount, setguestCount] = useState(props.data.person ? props.data.person : 1);
  const [childrenCount,setChildrenCount] = useState(props.data.children ? props.data.children : 0);
  const {tour} = props.data;
  const checkin = new Date(props.data.checkin);
  const checkout = new Date(props.data.checkout);
  const weekday = ["Sun","Mon","Tues","Wed","Thurs","Fri","Sat"];
  const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sept","Oct","Nov","Dec"];
  const [show, setShow] = useState(false);
  const [message,setMessage] = useState("");
  const [availabe,setAvailabe] = useState(false);
  let navigate = useNavigate();
  
  const getPeopleCount = () =>{
    return  childrenCount + guestCount;
  }
  const getDateDiff = ()=>{
    var timeDifference = checkout - checkin;
    var millisecondsInADay = 1000 * 60 * 60 * 24;
    var daysDifference = timeDifference / millisecondsInADay;
    return daysDifference +1;
  }
  const getFinalPrice = ()=>{
    return (tour.price  * getDateDiff());
  }
  const increment_guest = () => {
    setguestCount(guestCount + 1);
  };
  const increment_children = () =>{
    setChildrenCount(childrenCount + 1);
  }
  const decrement_children = () =>{
    if (childrenCount > 0) {
      setChildrenCount(childrenCount - 1);
    }
  }
  const decrement_guest = () => {
    if (guestCount > 1) {
      setguestCount(guestCount - 1);
    }
  };

  const checkAvailability = (data) => {
    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);
    // const formattedCheckin= moment(checkin).format('YYYY-MM-DD');
    // const formattedCheckout= moment(checkout).format('YYYY-MM-DD');

    // var raw = JSON.stringify({
    //   "campingTourId": tour.campingTourId,
    //   "checkIn": formattedCheckin,
    //   "checkOut": formattedCheckout,
    //   "numberOfPerson": guestCount
    // });

    // var requestOptions = {
    //   method: 'POST',
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: 'follow'
    // };

    // fetch(`${Domain}/api/tickets/checkavability`, requestOptions)
    //   .then(response => response.text())
    //   .then(result => {
    //     if(result==='Forbidden') {
    //       navigate('/login');
    //     }
    //     else{
    //       const parsed = JSON.parse(result);
    //       if(parsed.avability===true){
    //         navigate('../../checkout/query',{state: data});
    //       }
    //       else{
    //         setMessage('Camp not available at this date');
    //         setShow(true);
    //       }
    //     }

    //   })
    //   .catch(error => console.log('error', error));
    data.title = String(getDateDiff()) + " days Booking for " + String(getPeopleCount());
    navigate('../../checkout/queryTour',{state: data});

  }
  

  return (
    <>
    <Card  sx={{maxWidth:'auto'}} >
      <CardContent>
        <h5 className='px-3 pt-3 fw-bolder safety-text'>
            Your Reservation
        </h5>
        {props.data.checkin != '' && props.data.checkout != '' &&
          <div className='px-3 pt-2'>
              <div className='border bg-light'>
                  <CardMedia
                  component="img"
                  height="200"
                  image='https://hipcamp-res.cloudinary.com/f_auto,c_limit,w_1120,q_auto/campground-photos/ve69vsusa2xydhrathym.jpg'
                  alt="green iguana"
                  />
                  <div className='text-center fw-bold pt-3 pb-2'>{weekday[checkin.getDay()]}, {month[checkin.getMonth()]} {checkin.getDate()} to {weekday[checkout.getDay()]}, {month[checkout.getMonth()]} {checkout.getDate()}</div>
                  <div className='text-center fw-bold ps-2 pb-3'>{getDateDiff()} days Booking for {getPeopleCount()}</div>
              </div>
          </div>
        }

        {
          props.stage !='final' && 
          <>
            <div className='row mt-3 py-3 border-top px-3'>
              <div className='col-6 safety-text fw-bold'>
                Camping
              </div>
              <div className='col-6 safety-text fw-bold'>
                ₺{tour.price}/night
              </div>
            </div>
            <div className='row pt-3  border-top px-3'>
              <div className='col-4 ps-4 safety-text fw-bold'>
                Guests
              </div>
              <div className='col-8 ps-5 safety-text fw-bold'>
                <div className="guestCounter">
                    <RemoveCircleIcon style={{cursor:'pointer'}} onClick={decrement_guest}/>
                    <span className="mx-4 guestCount">{guestCount}</span>
                    <AddCircleIcon style={{cursor:'pointer'}} onClick={increment_guest}/>
                </div>
                  
              </div>
            </div>
            <div className='row py-3 px-3'>
              <div className='col-4 ps-4 safety-text fw-bold'>
                Children
              </div>
              <div className='col-8 ps-5 safety-text fw-bold'>
                <div className="guestCounter">
                    <RemoveCircleIcon style={{cursor:'pointer'}} onClick={decrement_children}/>
                    <span className="mx-4 me-4 guestCount">{childrenCount}</span>
                    <AddCircleIcon style={{cursor:'pointer'}} onClick={increment_children}/>
                </div>
                  
              </div>
              </div>
            {props.data.checkin != '' && props.data.checkout != '' &&
              <>
            <div className='row px-3'>
              <div className='col-8 ps-4 safety-text '>
              <p className='mb-1'> Camping subtotal</p>
              </div>
              <div className='col-4 safety-text '>        
                 <p className='mb-1'>₺{tour.price}  * {getDateDiff()}</p>
                 <p className='mb-2 border-bottom'>₺{tour.price * getDateDiff()}</p>
              </div>
            </div>
              </>
            }
 
          </>
        }
        
        <div className='row px-3 py-3'>
          <div className='col-8 ps-4 safety-text '>
          <p className='mb-1 fw-bold'> Total</p>
          </div>
          <div className='col-4 safety-text '>        
          <p className='mb-1'>₺{ props.data.checkin != '' && props.data.checkout != '' ? getFinalPrice() : tour.price}</p>
          </div>
        </div>
        <div className='row px-5 py-3'>
            <button onClick={()=>{
              const data = {
                "tour":tour,
                "checkin":props.data.checkin,
                "checkout":props.data.checkout,
                "total":getFinalPrice(),
                "peoplecount":getPeopleCount(),
                "person":guestCount,
                "children":childrenCount,
              }
              if(props.Checkout){
                props.Checkout();
              }
              else{
                checkAvailability(data);

              }
            }} className='w-100 btn btn-dark'>Checkout</button>
        </div>
      </CardContent>
    </Card>
    <Modal
            show={show}
            onHide={()=>{setShow(false)}}
            backdrop="static"
            keyboard={false}
            >
            <Modal.Header>
              <Modal.Title>Kamplasana!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>{message}</h4>
            </Modal.Body>
            <Modal.Footer>
              <button onClick={()=>{
                setShow(false);
                window.location.reload();
              }} className="btn btn-outline-dark">Okay</button>
            </Modal.Footer>
    </Modal>  
    </>

    
  );
}