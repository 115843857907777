import React, { useEffect, useState } from 'react';
import { Domain } from '../../../Domain';
import { useDispatch,useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import {HostDashboard} from '../../../common';
import Box from '@mui/material/Box';
import { Toolbar } from '@mui/material';
import { GetMe } from '../../../services/GetMe';
import { t } from 'i18next';
export const HostCampsManagement = () => {

    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [search,setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [error,seterror] = useState(false);
    const [success,setSuccess] = useState(false);
    const [name,setName] = useState("");
    const [edit,setEdit] = useState(false);
    const [id,setId] = useState('');
    const [camps,setCamps] = useState([]);
    const [userInfo,setUserInfo] = useState("empty");

    const DeleteCampingArea = (id)=>{
      var myHeaders = new Headers();
      myHeaders.append("x-refresh", `Bearer ${localStorage.getItem('refreshToken')}`);
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);
      seterror(false);
      var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`${Domain}/api/campingareas/${id}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          if(result === 'Forbidden'){
              seterror(true);
              localStorage.clear();
              navigate('/login');
          }
          else{
              window.location.reload();
          }
        })
        .catch(error => console.log('error', error));
    }


    useEffect(() => {
      if(!localStorage.getItem('accessToken')){
        navigate('/login');
      }
      else{
      }
    }, [])

    useEffect(() => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      const userInfo = JSON.parse(localStorage.getItem('user'));


      fetch(`${Domain}/api/campingareas/ownerId/${userInfo._id}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            if(result==="Forbidden") { 
              localStorage.clear();
              navigate('/login');
            }           
            else{
              const res = JSON.parse(result);
              setCamps(res);
            }
        })
        .catch(error => console.log('error', error));
    }, [])
    

    const handleSearch = (event) => {
        setSearch(event.target.value);
        const results = camps.filter(
          (item) =>
            item.title.toLowerCase().includes(event.target.value.toLowerCase())
        );
        setSearchResults(results);
    };
    
    return (
        <>
          <Box sx={{ display: 'flex' }}>
          <HostDashboard /> 
          <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            padding: 3,
            overflow: 'auto',
          }}
        >
          <Toolbar />
            <div className='container p-5 rounded '>
                <div className="events-header">
                    <h1 style={{fontWeight:'600'}} className="ehtitle"> {t('MyCamps')} </h1>
                    <button onClick={()=>{
                      navigate('new');

                    }} className='btn btn-outline-dark'> {t('CreateCamp')} </button>
                </div>
                <div className=" row no-gutters justify-content-center">
                <div className='col-12 bar'>
                            <div className="input-group mb-3">
                            <input type="text" className="ps-5 form-control search-inpt " placeholder={t("Search")} aria-label="Search" aria-describedby="basic-addon2" onChange={(e)=>handleSearch(e)}/>
                            <div className="input-group-append">
                            <button className="btn btn-outline-secondary my-0 search-btn" type="button"><i className="fas fa-search fa-lg fa-fw"></i></button>
                            </div>
                            </div>
                        </div>
                </div>
                <div className='container rounded bg-dark p-5'>
                    <div style={{fontWeight:'600'}} className='row p-3 text-light border-bottom border-white'>
                            <div className='col-4'> {t('campId')}</div>
                            <div className='col-4'> {t('campTitle')}</div>
                            <div className='col-2 text-center'> {t('campPrice')}</div>
                            <div className='col-2 text-end'> {t('Actions')}</div>
                    </div>  
                    {
                        search.length>0 ? 
                          camps.length> 0 && searchResults.map((camp)=>(
                          <div className='row p-3 text-light border-bottom border-white'>
                          <div className='col-4'> {camp._id} </div>
                          <div className='col-4'> {camp.title} </div>
                          <div className='col-2 text-center'> {camp.price} TL</div>
                          <div className='col-2 text-end'>      
                              <ButtonGroup  size="sm">
                              <Button onClick={()=>{ 
                                  navigate('edit/'+camp._id);
                                  }}  variant="light" ><i className="fa fa-edit" aria-hidden="true"></i></Button>                          
                            
                              <Button onClick={()=>{
                                  navigate('comments/'+camp._id,{state:{"camp":camp,"role":"host"}});
                                }}   variant="secondary"><i className="fa fa-comment" aria-hidden="true"></i></Button>      
                              <Button onClick={()=>{
                                  DeleteCampingArea(camp.campingAreaId);
                              }}   variant="danger"><i className="fa fa-trash" aria-hidden="true"></i></Button> 
                              </ButtonGroup>
                          </div>
                      </div>               
                      )):
                    camps.length> 0 && camps.map((camp)=>(
                      <div className='row p-3 text-light border-bottom border-white'>
                          <div className='col-4'> {camp._id} </div>
                          <div className='col-4'> {camp.title} </div>
                          <div className='col-2 text-center'> {camp.price} TL </div>
                          <div className='col-2 text-end'>      
                              <ButtonGroup  size="sm">
                              <Button onClick={()=>{ 
                                navigate(''+camp._id,{state:camp});
                              }}  variant="light" ><i className="fa fa-edit" aria-hidden="true"></i></Button>      
                              
                              <Button onClick={()=>{
                                  navigate('comments/'+camp._id,{state:{"camp":camp,"role":"host"}});
                                }}   variant="secondary"><i className="fa fa-comment" aria-hidden="true"></i></Button>                          
                              <Button onClick={()=>{
                                  DeleteCampingArea(camp.campingAreaId);
                              }}   variant="danger"><i className="fa fa-trash" aria-hidden="true"></i></Button>                              </ButtonGroup>
                          </div>
                      </div>             
                    ))
                    }
                </div>
            </div>
            </Box>
          </Box>
        </>
    )
}
