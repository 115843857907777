import React, { useEffect,useState } from 'react'
import { Navbar,Footer, LoadingModal} from '../../common'
import { useNavigate , useParams} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Animation from "../../resources/lottiefiles/CampfireGuy.json"
import Lottie from 'lottie-react';
import { Domain } from '../../Domain';


export const VerifyUser = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [loaded,setLoaded] = useState(false);
  let {user_id,verificationCode} = useParams();

  useEffect(() => {

    const myHeaders = new Headers();

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow"
    };
    fetch(`${Domain}/api/users/verify/${user_id}/${verificationCode}`, requestOptions)
      .then((response) => {
        if(response.status === 404){
            navigate('/error');
        }
        else if(response.status >= 200 && response.status < 300){
            setLoaded(true);
        }   
      })
      .catch((error) =>{ 
        console.error(error);
        navigate('/error');
    });  

  }, [])
  

  return (
    loaded ?
    <div className='bg-main'>
        <Navbar isSearchVisible={false}></Navbar>
        <div className='container p-5'>
            <div className='row g-5 p-4'>
                <div className='col-lg-6 col-sm-12 py-2 '>
                    <Lottie  style={{maxHeight:350}}  animationData={Animation} />
                </div>
                <div style={{display:'flex',   flexDirection: 'column',alignItems:'center',justifyContent:'center'}} className='col-lg-6 col-sm-12'>
                    <h3 style={{fontWeight:700}}> <u>{t('VerificationBody')}</u></h3>
                    <button onClick={()=>navigate('../home')} className='w-100 p-3 mt-3 btn btn-outline-dark'> {t('returnHome')}</button>                
                </div>
            </div>
            
        </div>
        <Footer current={'home'}/>    
    </div>
    : 
    <>
    <LoadingModal visible={!loaded}/>

    </>
  )
}
