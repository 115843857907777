import React, { useEffect, useState } from 'react';
import { Domain } from '../../../Domain';
import { useNavigate,useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import {AdminDashboard} from '../../../common';
import Box from '@mui/material/Box';
import { Button, Toolbar } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useTranslation } from "react-i18next";


export const EditHost = () => {

    let navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const data = location.state;
    
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [error,seterror] = useState(false);
    const [success,setSuccess] = useState(false);
    const [edit,setEdit] = useState(false);
    const [id,setId] = useState('');

    const [firstname,setFirstname] = useState(data.firstName);
    const [lastName,setLastName] = useState(data.lastName);
    const [email,setEmail] = useState(data.email);
    const [role,setRole] = useState("");
    const [birthday,setBirthday] = useState(data.birthday);
    const [iban,setIban] = useState(data.IBAN);
    const [accountName,setAccountName] = useState(data.bankAcountName);
    const [nationalId,setNationalId] = useState(data.nationalID);

    const updateProfileInfo = ()=>{

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

      var raw = JSON.stringify({
        "firstName": firstname,
        "lastName": lastName,
        "birthday": birthday,
      });

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch("http://localhost:1337/api/users", requestOptions)
        .then(response => response.text())
        .then(result => {
            if (result =='Forbidden') {
            }
            else{
              
            }
        })
        .catch(error => console.log('error', error));
    }

    const updateBankInfo = ()=>{
    
    }
    return (
        <>
          <Box sx={{ display: 'flex' }}>
          <AdminDashboard /> 
          <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            padding: 3,
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Grid sx={{backgroundColor:'#fff',paddingBottom:5,paddingRight:5, marginTop:3,width:'auto',borderRadius:2,marginLeft:2}} container spacing={4}>
            <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h4" >
                    Edit Host
                </Typography>
            </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e)=>{setFirstname(e.target.value);}}
            value={firstname}
            required
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={lastName}
            onChange={(e)=>{setLastName(e.target.value);}}
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            value={email}
            onChange={(e)=>{setEmail(e.target.value.toLowerCase());}}
            required
            id="city"
            name="city"
            label="Email Address"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
          value={birthday}
            onChange={(e)=>{setBirthday(e.target.value);}}
            required
            id="BirthDate"
            name="BirthDate"
            label={t('birthDate21')}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={accountName}
            onChange={(e)=>{setAccountName(e.target.value);}}
            required
            id="Bank Account Name"
            name="Bank Account Name"
            label="Bank Account Name"
            fullWidth
            autoComplete="shipping address-level2"
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            value={nationalId}
            onChange={(e)=>{setNationalId(e.target.value);}}
            required
            label="T.C"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={iban}
            onChange={(e)=>{setIban(e.target.value);}}
            required
            label="IBAN"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  sx={{ mt: 3, ml: 1 }}
                >
                    Update
                </Button>
              </Grid>
        </Grid>
        </Box>
        </Box>
        </>
    )
}
