import React, { useEffect, useState } from 'react';
import { Domain } from '../../../Domain';
import { useNavigate } from 'react-router-dom';
import {Button,ButtonGroup} from 'react-bootstrap';
import {AdminDashboard} from '../../../common';
import { Toolbar,Box} from '@mui/material';
export const BlogManagement = () => {

    let navigate = useNavigate();
    const [search,setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [error,seterror] = useState(false);
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(`${Domain}/api/blogs`, requestOptions)
        .then(response => response.text())
        .then(result =>{ 
            if(result =='Forbidden'){
                localStorage.clear();
                navigate('/login');
            }
            else{
                const res =JSON.parse(result);
                setBlogs(res);
            }
        })
        .catch(error => console.log('error', error));
    }, []);

    const DeleteBlog = (id)=>{
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);
      seterror(false);
      var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`${Domain}/api/admin/blogs/${id}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          if(result === 'Forbidden'){
              seterror(true);
              localStorage.clear();
              navigate('/login');
          }
          else{
              window.location.reload();
          }
        })
        .catch(error => console.log('error', error));
    }

    const handleSearch = (event) => {
        setSearch(event.target.value);
        const results = blogs.filter(
          (item) =>
            item.title.toLowerCase().includes(event.target.value.toLowerCase())
        );
        setSearchResults(results);
    };
    
    return (
        <>
          <Box sx={{ display: 'flex' }}>
          <AdminDashboard /> 
          <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            padding: 3,
            overflow: 'auto',
          }}
        >
          <Toolbar />
            <div className='container p-5 rounded '>
                <div className="events-header">
                    <h1 style={{fontWeight:'600'}} className="ehtitle"> Current Blogs </h1>
                    <button onClick={()=>{
                      navigate('new');

                    }} className='btn btn-outline-dark'> New Blog </button>
                </div>
                <div className=" row no-gutters justify-content-center">
                <div className='col-12 bar'>
                            <div className="input-group mb-3">
                            <input type="text" className="ps-5 form-control search-inpt " placeholder="Search" aria-label="Search" aria-describedby="basic-addon2" onChange={(e)=>handleSearch(e)}/>
                            <div className="input-group-append">
                            <button className="btn btn-outline-secondary my-0 search-btn" type="button"><i className="fas fa-search fa-lg fa-fw"></i></button>
                            </div>
                            </div>
                        </div>
                </div>
                <div className='container rounded bg-dark p-5'>
                    <div style={{fontWeight:'600'}} className='row p-3 text-light border-bottom border-white'>
                            <div className='col-4'> Blog id</div>
                            <div className='col-4 text-center'> Blog Title</div>
                            <div className='col-3 text-end'> Actions</div>
                    </div>  
                    {
                        search.length>0 ? 
                          blogs.length> 0 && searchResults.map((blog)=>(
                          <div className='row p-3 text-light border-bottom border-white'>
                          <div className='col-4'> {blog._id} </div>
                          <div className='col-4 text-center'> {blog.title} </div>
                          <div className='col-3 text-end'>      
                              <ButtonGroup  size="sm">
                              <Button onClick={()=>{ 
                                  navigate('edit/'+blog._id);
                                  }}  variant="light" ><i className="fa fa-edit" aria-hidden="true"></i></Button>                          
                              <Button onClick={()=>{
                                  DeleteBlog(blog.blogId);
                              }}  variant="danger"><i className="fa fa-trash" aria-hidden="true"></i></Button>
                              </ButtonGroup>
                          </div>
                      </div>               
                      )):
                    blogs.length> 0 && blogs.map((blog)=>(
                      <div className='row p-3 text-light border-bottom border-white'>
                          <div className='col-4'> {blog._id} </div>
                          <div className='col-4  text-center'> {blog.title} </div>
                          <div className='col-3 text-end'>      
                              <ButtonGroup  size="sm">
                              <Button onClick={()=>{ 
                                navigate(''+blog._id,{state:blog});
                              }}  variant="light" ><i className="fa fa-edit" aria-hidden="true"></i></Button>                          
                              <Button onClick={()=>{
                                  DeleteBlog(blog.blogId);
                              }}   variant="danger"><i className="fa fa-trash" aria-hidden="true"></i></Button>
                              </ButtonGroup>
                          </div>
                      </div>             
                    ))
                    }

                </div>
            </div>
            </Box>
          </Box>
        </>
    )
}
