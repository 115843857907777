import { Domain } from "../Domain";

export async function GetIlce(CityId){
    const requestOptions = {
        method: "GET",
        redirect: "follow"
      };
      
    return fetch(`${Domain}/api/cities/${CityId}`, requestOptions)
      .then((response) => response.text())
        .then((result) =>{
            const ilceler = JSON.parse(result);
            return ilceler;
        })
        .catch((error) => console.error(error));
}
