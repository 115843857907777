import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CabinIcon from '@mui/icons-material/Cabin';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HomeIcon from '@mui/icons-material/Home';
import { useTranslation } from 'react-i18next';

export const MainListItems = () =>{
  const { t } = useTranslation();

return(
  <React.Fragment>
    <ListItemButton href='/host/dashboard'>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary={t('Dashboard')} />
    </ListItemButton>
    <ListItemButton href='/home'>
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText primary={t("Home")} />
    </ListItemButton>
    <ListItemButton href='/host/profile'>
      <ListItemIcon>
        <PersonIcon/>
      </ListItemIcon>
      <ListItemText primary={t("Profile")} />
    </ListItemButton>
    <ListItemButton href='/host/camps'>
      <ListItemIcon>
        <CabinIcon/>
      </ListItemIcon>
      <ListItemText primary={t("Camps")} />
    </ListItemButton>
    {/* <ListItemButton href='/host/reservations'>
      <ListItemIcon>
        <CalendarMonthIcon/>
      </ListItemIcon>
      <ListItemText primary={t("Reservations")} />
    </ListItemButton> */}
    <ListItemButton href='/home' onClick={()=>{
      localStorage.clear();
    }}>
      <ListItemIcon>
        <ExitToAppIcon/>
      </ListItemIcon>
      <ListItemText primary={t('logout')} />
    </ListItemButton>
  </React.Fragment>
)
  }