import React, { useEffect, useState } from 'react';
import { Domain } from '../../../Domain';
import { useNavigate,useLocation } from 'react-router-dom';
import {AdminDashboard} from '../../../common';
import Box from '@mui/material/Box';
import { IconButton, Toolbar } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ToggleButtonGroup from '@mui/joy/ToggleButtonGroup';
import Button from '@mui/joy/Button';
import Modal from 'react-bootstrap/Modal';
import {CloudinaryWidget} from '../../../common';


export const NewBlog = () => {

    let navigate = useNavigate();
    const location = useLocation();
    
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [error,seterror] = useState(false);
    const [success,setSuccess] = useState(false);
    const [Categories, setCategories] = useState([]);
    const [SelectedCategories, setSelectedCategories] = useState({});
    const [Images, setImages] = useState([]);

    function removeObjectsByParameter(objects, parameter, value) {
      return objects.filter(obj => obj[parameter] !== value);
    }
    const [uploadedImageUrl, setUploadedImageUrl] = useState(null);

    const handleUploadSuccess = (url) => {
      setUploadedImageUrl(url);
    };

    useEffect(() => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`${Domain}/api/admin/category`, requestOptions)
        .then(response => response.text())
        .then(result => {
          if(result==='Forbidden'){
              localStorage.clear();
              navigate('/login');
          }
          else{
              const resp = JSON.parse(result);
              const filteredObjects = removeObjectsByParameter(resp, 'feature', '88');
              setCategories(filteredObjects);
          }
        })
        .catch(error => console.log('error', error));
    }, [])
    
    const createBlog = () => {
      var myHeaders = new Headers();
      myHeaders.append("x-refresh", localStorage.getItem('refreshToken'));
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);
      const keysArray = Object.keys(SelectedCategories) ;

      var raw = JSON.stringify({
        "title": title,
        "body": body,
        "image": Images,
        "category": keysArray
      });
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      
      fetch(`${Domain}/api/blogs`, requestOptions)
        .then(response => response.text())
        .then(result => {})
        .catch(error => console.log('error', error));
    }

    const [title,setTitle] = useState("");
    const [body,setBody] = useState("");
    const [value, setValue] = React.useState(['default']);
    
    return (
        <>
          <Box sx={{ display: 'flex' }}>
          <AdminDashboard /> 
          <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            padding: 3,
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Grid sx={{backgroundColor:'#fff',paddingBottom:5,paddingRight:5, marginTop:3,width:'auto',borderRadius:2,marginLeft:2}} container spacing={4}>
            <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h4" >
                    Create Blog
                </Typography>
            </Grid>
        <Grid item xs={12}>
          <TextField
            onChange={(e)=>{setTitle(e.target.value);}}
            value={title}
            required
            id="Title"
            name="Title"
            label="Title"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} >
          <TextField
            value={body}
            placeholder="Write a short description of 120 words about the Blog in a html format <h4> for heading and <p> for normal paragraphs"
            multiline
            fullWidth
            rows={7}
            onChange={(e)=>{setBody(e.target.value);}}
            required
            label="Body"
            variant="standard"
          />
        </Grid>
        <Grid xs={12}>
                <Typography paddingLeft={4} paddingTop={3} fontWeight={600} variant="h5" >
                    Categories
                </Typography>
            </Grid>
        <Grid item xs={12}>
          <div className='container-md' style={{flexWrap:'wrap'}}>
          <ToggleButtonGroup
            spacing={2}
            className="groupsButton"
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
             variant='outline'
          >
            {
              Categories.map(category =>  (
                <Button className="togglers" variant="solid" onClick={(e)=>{
                  var temp = SelectedCategories;
                  var attribName = category.code;
                  if(temp[attribName]===undefined){
                    temp[attribName] = true
                  }
                  else{
                    delete temp[attribName];
                  }
                  setSelectedCategories(temp);
                }} value={category.code}>{category.name}</Button>

              ))
            }
          </ToggleButtonGroup>
          </div>

        </Grid>
        <Grid xs={12}>
                <Typography paddingLeft={4} paddingTop={3} fontWeight={600} variant="h5" >
                    Images
                </Typography>
            </Grid>
        <Grid xs={12} paddingLeft={4}>
             <div>
                <CloudinaryWidget
                  apiKey="359728572815919"
                  cloudName="dyku0cdi3"
                  folder={`Blogs/${title}`}
                  maxWidth={800}
                  maxHeight={600}
                  setImages={setImages}
                  images={Images}        
                  onUploadSuccess={handleUploadSuccess}
                />
              </div>
        </Grid>
        
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  sx={{ mt: 3, ml: 1 }}
                  onClick={()=>{createBlog();}}
                >
                    Create
                </Button>
              </Grid>
        </Grid>
        </Box>
        </Box>
        <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                <Modal.Title> {"Create Blog"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {
                  error ? <div>an Error Ocurred!</div> : success?
                  <div> Host Created Successfully!</div> : <></>

                }
                </Modal.Body>
                <Modal.Footer>
                <button onClick={()=>{

                    if(success||error){
                      setSuccess(false);
                      seterror(false);
                      handleClose();
                      if(success){
                        navigate('/admin/blogs');
                      }
                      else{
                        window.location.reload();
                      }
                    }
                    else{
                      handleClose();
                    }
                  }} className="btn btn-outline-dark"> {"Okay"}</button>  
                  
                </Modal.Footer>
                </Modal>    
        </>
    )
}
