import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: t('ChartTitle'),    
      font: {
        size: 20,
        family: 'Helvetica Neue'
    }
    },
   
  },
};

const labels = [t('Jan'),t('Feb'),t('Mar'),t('Apr'),t('May'),t('Jun'),t('Jul'),t('Aug'),t('Sep'),t('Oct'),t('Nov'),t('Dec')];



export const ProfitChart =({dataSet}) => {
  const {t} = useTranslation();
  const data = {
    labels,
    datasets: [
      {
        label: t('Profit'),
        data: dataSet,
        borderColor: '#EF791F',
        backgroundColor: '#fff',
      },
    ],
  };

  return (<Line lang='tr' options={options} data={data} />);
}
