import * as React from 'react';
import {Divider,IconButton,Typography,List,Toolbar} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar,Drawer} from '../comp/SidebarSkeleton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MainListItems } from './comp/ListItems';
import { useEffect } from 'react';
import { GetMe } from '../../../services/GetMe';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

export const HostDashboard =()=> {
  const [open, setOpen] = React.useState(true);
  const [user,setUser] = React.useState([]);
  const navigate = useNavigate();
  const toggleDrawer = () => {
    setOpen(!open);
  };
  useEffect(() => {
    const temp = JSON.parse(localStorage.getItem('user'));
    if(temp?.role !== "campingSeller"){
      navigate('/home');
    }

  }, [])
  

  return (
      <>
        <AppBar position="absolute" style={{zIndex:100,backgroundColor:'#EF791F'}} open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {t('HostDashboard')}
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" style={{zIndex:2}} open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <MainListItems/>
          </List>
        </Drawer>
      </>
  );
}