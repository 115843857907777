import "./common.css";
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Domain } from "../../../Domain";
import { useTranslation } from "react-i18next";

export default function PersonalHeader(tab) {
    let navigate = useNavigate();
    const [name,setName] = useState('');
    const { t, i18n } = useTranslation();

    useEffect(() => {

        const getInfo = ()=>{
    
          var myHeaders = new Headers();
          myHeaders.append("Authorization",`Bearer ${localStorage.getItem('accessToken')}`);
      
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
      
          fetch(`${Domain}/api/users/me`, requestOptions)
            .then(response => response.text())
            .then(result => {
              if(result === "Unauthorized"){
                localStorage.clear();
                  navigate('/login');
              }
              const Object = JSON.parse(result);
              setName(Object.name);
            })
            .catch(error => console.log('error', error));
        }
        getInfo();
     },[]);
    return (
        <div className="my-profile-header">
            <div className="container">
                <div className="row gx-0">
                    <div className="col-md-5 col-lg-6">
                        <div className="header-profile">
                           
                            <div className="header-profile-right">
                                <span >{name} {t('welcomeSign')}!</span>
                                <button onClick={()=>{localStorage.clear();window.location.reload();}} type="button" className="header-profile-right-button">{t('logout')}</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 col-lg-6">
                        <div className="my-profile-menu">
                            <ul >
                                <li routerlinkactive="active" className={tab.tab === "profile" ? "active" : ""}>
                                    <a href="/profile">
                                        <em >
                                            <div role="img" svgicon="person-pencil" className="mat-icon notranslate mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="person-pencil">
                                                <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                    <path d="M13 3C9.145 3 6 6.145 6 10C6 12.41 7.23 14.55 9.094 15.813C5.527 17.343 3 20.883 3 25H5C5 20.57 8.57 17 13 17C15.145 17 17.063 17.879 18.5 19.25L13.781 23.969L13.719 24.281L13.031 27.813L12.719 29.281L14.188 28.969L17.718 28.281L18.031 28.219L28.125 18.125C29.285 16.965 29.285 15.035 28.125 13.875C27.5652 13.3197 26.8103 13.0056 26.0218 12.9998C25.2333 12.994 24.4739 13.297 23.906 13.844L19.938 17.813C19.0565 16.9686 18.0292 16.291 16.906 15.813C17.8575 15.1707 18.637 14.3049 19.1764 13.2915C19.7158 12.2782 19.9986 11.148 20 10C20 6.145 16.855 3 13 3ZM13 5C15.773 5 18 7.227 18 10C18 12.773 15.773 15 13 15C10.227 15 8 12.773 8 10C8 7.227 10.227 5 13 5ZM26 15C26.254 15 26.52 15.082 26.719 15.281C26.8134 15.3721 26.8885 15.4813 26.9398 15.6021C26.9911 15.7229 27.0175 15.8528 27.0175 15.984C27.0175 16.1152 26.9911 16.2451 26.9398 16.3659C26.8885 16.4867 26.8134 16.5959 26.719 16.687L17.031 26.375L15.25 26.75L15.625 24.969L25.313 15.281C25.512 15.082 25.746 15 26 15Z" fill="currentColor"></path>
                                                </svg>
                                            </div>
                                        </em>
                                        <span className='ms-3'>{t("Profile")}</span>
                                    </a>
                                </li>
                                <li routerlinkactive="active" className={tab.tab === "MyReservations" ? "active" : ""}>
                                    <a href="/reservations">
                                        <em >
                                            <div role="img" svgicon="incele" className="mat-icon notranslate mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="incele">
                                                <svg width="100%" height="100%" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                <path d="M6.79711 21.4957C4.60834 21.4953 2.70061 20.0055 2.16983 17.8821L0.030305 9.33115C-0.0435403 9.04515 0.0192477 8.74108 0.200334 8.50772C0.381421 8.27436 0.660383 8.13803 0.955762 8.13855H4.98198L6.70886 1.22147C6.79408 0.89084 7.04921 0.630783 7.37816 0.539264C7.7071 0.447746 8.05988 0.538669 8.30361 0.777784C8.54734 1.0169 8.64499 1.36788 8.55978 1.69851L6.94738 8.13855H15.9539L14.3415 1.69851C14.2563 1.36788 14.3539 1.0169 14.5977 0.777784C14.8414 0.538669 15.1942 0.447746 15.5231 0.539264C15.8521 0.630783 16.1072 0.89084 16.1924 1.22147L17.9193 8.13855H21.9455C22.2397 8.13786 22.5177 8.27292 22.6991 8.50458C22.8804 8.73624 22.9447 9.03859 22.8734 9.324L20.7267 17.8821C20.1959 20.0055 18.2882 21.4953 16.0994 21.4957H6.79711ZM18.881 17.4194L20.7247 10.0467H2.17743L4.02596 17.4194C4.34443 18.6935 5.48907 19.5873 6.80233 19.5875H16.1046C17.4179 19.5873 18.5625 18.6935 18.881 17.4194Z" fill="currentColor"></path>
                                                </svg>
                                            </div>
                                        </em>
                                        <span className='ms-3' >{t("Reservations")}</span>
                                    </a>
                                </li>
                                <li routerlinkactive="active" className={tab.tab === "MyTickets" ? "active" : ""}>
                                    <a href="/tickets">
                                        {/* <TourIcon sx={{fontSize:50,userSelect: 'none', backgroundRepeat: 'no-repeat',display: 'inline-block',fill: 'currentcolor',color: '#4b4b4b'}}  className="mat-icon notranslate mat-icon-no-color"/> */}
                                        <em>
                                        <div role="img" svgicon="heart-white" className="mat-icon notranslate mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="heart-white">
                                        <svg className=" mat-icon-no-color css-5lou5-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="TourIcon"><path d="M21 4H7V2H5v20h2v-8h14l-2-5 2-5zm-6 5c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2z"></path></svg>
                                        </div>
                                        </em>
                                        
                                        <span className='ms-3' >{t("Tours")}</span>
                                    </a>
                                </li>
                                <li routerlinkactive="active" className={tab.tab === "Fav" ? "active" : ""}>
                                    <a href="/favorites">
                                        <em >
                                            <div role="img" svgicon="heart-white" className="mat-icon notranslate mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="heart-white">
                                                <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                <path d="M21.4247 3.00048C20.2973 3.01215 19.141 3.23361 18.0097 3.68786L17.9583 3.70979L17.907 3.7364C17.1898 4.10379 16.5362 4.56337 15.9551 5.10801C12.8279 2.38721 7.84924 2.18974 4.44313 5.27868L4.40888 5.30999L4.37775 5.34288C1.38978 8.4824 1.45975 13.3174 3.25082 17.1378L3.26639 17.1675L3.28195 17.1989C5.39138 21.1282 9.33033 24.467 12.9199 27.2119L12.9292 27.2197L12.937 27.226C13.1679 27.3963 13.565 27.7485 14.0234 28.095C14.4818 28.4416 14.9997 28.8214 15.7574 28.9671L15.927 29L16.0983 28.9859C16.8935 28.9169 17.2055 28.6215 17.7279 28.2907C18.2504 27.9599 18.8287 27.5399 19.423 27.0804C20.5944 26.1745 21.8064 25.1304 22.6045 24.3857C22.6148 24.3761 22.6317 24.3623 22.6419 24.3528C22.6435 24.3514 22.6449 24.3495 22.6465 24.3481C24.3277 22.905 25.9931 21.2413 27.4048 19.2845C27.4052 19.2839 27.4059 19.2835 27.4063 19.2829C27.409 19.2793 27.4115 19.2756 27.4141 19.272C29.6531 16.2395 30.7392 12.153 29.4438 8.12685L29.4205 8.05481L29.3893 7.98747C27.9349 4.82452 24.8068 2.96553 21.4247 3.00048ZM21.4652 5.47753C23.902 5.42115 26.0379 6.73371 27.0982 8.97857C28.0779 12.1214 27.231 15.3235 25.4062 17.7891L25.4016 17.7954L25.3969 17.8017C24.1257 19.5663 22.5862 21.111 20.9904 22.4771L20.9702 22.4959L20.95 22.5146C20.2314 23.1875 19.015 24.2357 17.9039 25.095C17.3482 25.5247 16.8156 25.9087 16.4018 26.1707C16.2462 26.2692 16.0983 26.3364 15.9769 26.3883C15.8493 26.3182 15.6873 26.2194 15.5193 26.0924C15.181 25.8367 14.8207 25.5101 14.4281 25.2187C14.422 25.214 14.4156 25.2093 14.4095 25.2046C10.926 22.5393 7.25664 19.3029 5.4922 16.0402C4.05974 12.9618 4.12225 9.27714 6.14593 7.11217C8.89754 4.65108 12.9855 5.18613 15.0243 7.71186L16.0173 8.941L16.9777 7.68682C17.5303 6.96568 18.1897 6.4144 18.9903 5.9958C19.8294 5.66604 20.6631 5.49608 21.4652 5.47753V5.47753Z" fill="currentColor"></path>
                                                </svg>
                                            </div>
                                        </em>
                                        <span className='ms-3' >{t("Favorites")}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}