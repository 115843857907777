import React, { useEffect, useState } from 'react';
import { Domain } from '../../../Domain';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {AdminDashboard} from '../../../common';
import Box from '@mui/material/Box';
import { Toolbar } from '@mui/material';

export const CategoriesManagement = () => {

    let navigate = useNavigate();
    const [search,setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [Categories, setCategories] = useState([]);

    function removeObjectsByParameter(objects, parameter, value) {
      return objects.filter(obj => obj[parameter] !== value);
    }

    const options = [
      { label: 'feature', value: 22 },
      { label: 'co-working area', value: 33 },
      { label: 'standard amenities', value: 44 },
      { label: 'additional support', value: 55 },
      { label: 'activities', value: 66 },
      { label: 'terrain', value: 77 },
    ];    

    const handleSearch = (event) => {
        setSearch(event.target.value);
        const results = Categories.filter(
          (item) =>
            item.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
            item.feature.toLowerCase().includes(event.target.value.toLowerCase())
        );
        setSearchResults(results);
    };
    useEffect(() => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`${Domain}/api/category`, requestOptions)
        .then(response => response.text())
        .then(result => {
          if(result==='Forbidden'){
              localStorage.clear();
              navigate('/login');
          }
          else{
              const resp = JSON.parse(result);
              const filteredObjects = removeObjectsByParameter(resp, 'feature', '88');
              setCategories(filteredObjects);
          }
        })
        .catch(error => console.log('error', error));
    }, [navigate])

    const DeleteCat = (code) => {

      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

      var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`${Domain}/api/category/${code}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          if(result === 'Forbidden'){
            localStorage.clear();
            navigate('/login');
          }
          else{
            window.location.reload();
          }        
        })
        .catch(error => console.log('error', error));

    }
    

    return (
      Categories.length >= 0 &&
        <>
          <Box sx={{ display: 'flex' }}>
          <AdminDashboard /> 
          <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            padding: 3,
            overflow: 'auto',
          }}
        >
          <Toolbar />
            <div className='container p-5 rounded '>
                <div className="events-header">
                    <h1 style={{fontWeight:'600'}} className="ehtitle"> Current Categories </h1>
                    <button onClick={()=>{
                      navigate('new');

                    }} className='btn btn-outline-dark'> New Category </button>
                </div>
                <div className=" row no-gutters justify-content-center">
                <div className='col-12 bar'>
                            <div className="input-group mb-3">
                            <input type="text" className="ps-5 form-control search-inpt " placeholder="Search" aria-label="Search" aria-describedby="basic-addon2" onChange={(e)=>handleSearch(e)}/>
                            <div className="input-group-append">
                            <button className="btn btn-outline-secondary my-0 search-btn" type="button"><i className="fas fa-search fa-lg fa-fw"></i></button>
                            </div>
                            </div>
                        </div>
                </div>
                <div className='container rounded bg-dark p-5'>
                    <div style={{fontWeight:'600'}} className='row p-3 text-light border-bottom border-white'>
                            <div className='col-3'> Category id</div>
                            <div className='col-3'> Category Name</div>
                            <div className='col-2'> Category Feature</div>
                            <div className='col-2'> Category Code</div>

                            <div className='col-2 text-end'> Actions</div>
                    </div>  
                    {
                        search.length>0 ? 
                          Categories.length> 0 && searchResults.map((Category)=>(
                          <div className='row p-3 text-light border-bottom border-white'>
                          <div className='col-3'> {Category._id} </div>
                          <div className='col-3 text-center'> {Category.name} </div>
                          <div className='col-2  text-center'> {Category.feature} </div>
                          <div className='col-2'> {Category.code} </div>

                          <div className='col-2 text-end'>      
                              <ButtonGroup  size="sm">
                              <Button onClick={()=>{ 
                                navigate(''+Category._id,{state:Category});
                              }}  variant="light" ><i className="fa fa-edit" aria-hidden="true"></i></Button>                          
                              <Button onClick={()=>{
                                  DeleteCat(Category.code);
                              }}  variant="danger"><i className="fa fa-trash" aria-hidden="true"></i></Button>
                              </ButtonGroup>
                          </div>
                      </div>               
                      )):
                    Categories.length> 0 && Categories.map((Category)=>(
                      
                      <div className='row p-3 text-light border-bottom border-white'>
                          <div className='col-3'> {Category._id} </div>
                          <div className='col-3  text-center'> {Category.name} </div>
                          <div className='col-2 text-center'> {options.find(option => option.value == Category.feature)?.label || 'Unknown feature'} </div> {/* Display the label */}
                          <div className='col-2'> {Category.code} </div>

                          <div className='col-2 text-end'>      
                              <ButtonGroup  size="sm">
                              <Button onClick={()=>{ 
                                navigate(''+Category._id,{state:Category});
                              }}  variant="light" ><i className="fa fa-edit" aria-hidden="true"></i></Button>                          
                              <Button onClick={()=>{
                                  DeleteCat(Category.code);
                              }}   variant="danger"><i className="fa fa-trash" aria-hidden="true"></i></Button>
                              </ButtonGroup>
                          </div>
                      </div>             
                    ))
                    }

                </div>
            </div>
            </Box>
          </Box>
        </>
    )
}
