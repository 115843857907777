import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import Logo from '../../../resources/images/LogoName.png';

export const CheckoutNav = () => {
    const navigate = useNavigate();

    return (
        <div style={{height:100}} className='bg-main px-3 pt-2 container-fluid nav-cont border-bottom'>
            <div className='col-5 col-md-3 col-sm-4'>
            <div style={{cursor:'pointer'}} onClick={()=>{navigate('../home')}}  className="logo"><img style={{width:150,borderBottomLeftRadius:20,borderBottomRightRadius:20}} src={Logo}/></div>
            </div>
            <div className='col-7 col-md-6 col-sm-6 '>
                <div style={{display:'flex',fontWeight:700}}>
                    <i style={{  color: "#00000080",lineHeight: 50}} className="mt-1 fa fa-lock align-bottom " aria-hidden="true"></i>
                     <div  style={{  color: "#00000080"}} className='ps-3'>Secure Checkout</div>
                </div>
            </div>
        </div>  
    )
}
