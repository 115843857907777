export function isValidDateFormat(dateString) {
    // Regular expression to check if the date string is in DD-MM-YYYY format
    const regex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/;

    // Test the date string against the regular expression
    if (!regex.test(dateString)) {
        return false;
    }

    // Extract the day, month, and year from the date string
    const [day, month, year] = dateString.split('-').map(Number);

    // Create a date object with the extracted values
    const date = new Date(year, month - 1, day);

    // Check if the date object is valid and matches the original day, month, and year
    return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
}