import React from 'react';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
export const FirstInfo = () => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <div className='container overflow-hidden my-5'>
      <div className='row g-5'>
        
        <div className='col-md-6 col-12 side-img-cont'>
          <img src='https://cdn.pixabay.com/photo/2023/04/24/03/16/camping-7947056_1280.jpg' className='img-col-6'/>
        </div>
        <div className='col-md-6 col-12 pb-5'>
          <h3 style={{fontSize:25, fontWeight:'700'}}> {t('FirstInfoTitle')} </h3>
          <p style={{textAlign:'justify'}} className='pt-2'> 
          {t('FirstInfoDescription')}

          <br></br>
          </p>
          <button onClick={()=>{navigate('../signup-host')}} className='btn btn-dark'> {t('FirstInfoButton')} </button>
        </div>
      </div>
    </div>
  )
}
