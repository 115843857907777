import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

export const ReserveRow = ({reserve,DeleteReservation,navigate,transferMoney}) => {
  return (
    <div className='row p-3 text-light border-bottom border-white'>
        <div className='col-2'> {reserve._id} </div>
        <div className='col-3 text-center' style={{flex:'auto'}}> {reserve.updatedAt} </div>
        <div className='col-1  text-center'> {reserve.status} </div>
        <div className='col-2 text-center'> {reserve.totalPrice} TL</div>
        <div className='col-2 text-end'>   
        <ButtonGroup  variant="contained"  className='m-0' size="small">
            <Button  color="primary" onClick={()=>{  navigate('edit/'+reserve._id)}} >
                <i className="fa fa-edit"  aria-hidden="true"></i>
            </Button>        
            <Button  color="warning" onClick={()=>{transferMoney(reserve.pnr);}} >
                Transfer
            </Button>                  
            <Button color="error" onClick={()=>{ DeleteReservation(reserve.reserveId)}} >
                <i className="fa fa-trash" aria-hidden="true"></i>
            </Button>
        </ButtonGroup>
        </div>
    </div>   
  )
}
