import React from 'react'
import { useTranslation } from 'react-i18next';
import Lottie from "lottie-react";
import Animation from "../../resources/lottiefiles/Bus-Forest.json"
import Logo from '../../resources/images/LogoName.png';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import PhoneIcon from '@mui/icons-material/Phone';
import BusinessIcon from '@mui/icons-material/Business';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PinterestIcon from '@mui/icons-material/Pinterest';
export const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={`container-fluid px-lg-5 px-md-4 px-sm-0 pt-4 bg-light`}>
      <footer className="">
        <div className="row mt-2 g-0">
          <div className="col-12 gx-0 col-sm-6 col-md-3 mb-3">
            <h5>{t('Kurumsal')}</h5>
            <ul className="nav flex-column">
            <li className="nav-item"><a href="../home" className="nav-link px-2 text-secondary">{t('Home')}</a></li>
                <li className="nav-item"><a href="../tours" className="nav-link px-2 text-secondary">{t('Tours')}</a></li>
                <li className="nav-item"><a href="../blogs"  className="nav-link px-2 text-secondary">{t('Blog')}</a></li>
                <li className="nav-item"><a href="../login"  className="nav-link px-2 text-secondary">{t('login')}</a></li>
                <li className="nav-item"><a href="../signup"  className="nav-link px-2 text-secondary">{t('signup')}</a></li>
            </ul>
            <div style={{cursor:'pointer',}} onClick={()=>{navigate('../home')}}  className="logo"><img style={{width:160, marginTop:30,borderBottomLeftRadius:20,borderBottomRightRadius:20}} src={Logo}/></div>

          </div>

          <div className="col-12 col-sm-6 col-md-4 mb-3">
            <h5>{t('ContactUs')}</h5>
            <ul className="nav flex-column">
              <li className="nav-item mt-3"> <PhoneIcon sx={{color:"darkorange"}}/> +90 531 836 60 20</li>
              <li className="nav-item mt-2 mb-3"> <PhoneIcon sx={{color:"darkorange"}}/> +90 552 488 75 40 {"(For English)"}</li>

              <li className="nav-item mb-3">
                <div className=' d-flex flex-row gap-2'>
                <BusinessIcon sx={{color:"darkorange"}}/>
                <div className='flex-column'>
                  <p style={{marginBottom:4}}> <b> Kamplasana </b></p> 
                  <p style={{marginBottom:4}}> Fulya, Yeşilçimen Sokağı Polat Tower Residence Bağımsız Bölüm 12/430, 34394 Şişli/İstanbul
</p> 

              </div>
                </div>
          
            </li>
              <li className="nav-item mb-3"> <MailOutlineIcon sx={{color:"darkorange"}}/> info@kamplasana.com</li>
            </ul>
          </div>

          <div className="col-md-5 mb-3">
            <form>
              <h5>{t('SubscribeEmailHeader')}</h5>
              <p>{t('SubscribeDesc')}</p>
              <div className="d-flex flex-column flex-sm-row w-100 gap-3">
                <label htmlFor="newsletter1" className="visually-hidden">{t('placeHolderEmail')}</label>
                <input id="newsletter1" type="text" className="form-control" placeholder={t("placeHolderEmail")}/>
                <button className="btn btn-danger butn--fill butn" style={{backgroundColor: "#e67e22",borderColor:"#fff"}}  type="button">{t("Subscribe")}</button>

              </div>
              <div className="d-flex flex-column  justify-content-center w-100">
                <Lottie  style={{maxHeight:200}}  animationData={Animation} />
              </div>
            </form>
          </div>
        </div>

        <div className="d-flex flex-column flex-sm-row justify-content-between py-5 mt-5  border-top">
          <p>© 2024 Kamplasana, Inc. All rights reserved.</p>
          <ul className="list-unstyled d-flex">
          <li className="ms-3"><a className="link-dark" href="https://www.tiktok.com/@kamplasana"><IconButton>          
            <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="800px" height="800px" viewBox="0 0 24 24">
              <path d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z"/></svg>
            </IconButton></a>
          </li>
          <li className="ms-3"><a className="link-dark" href="https://tr.pinterest.com/Kamplasana/"><IconButton><PinterestIcon sx={{height:24,width:24}}/></IconButton></a></li>
          <li className="ms-3"><a className="link-dark" href="https://www.facebook.com/profile.php?id=61561916229595"><IconButton><FacebookIcon sx={{height:24,width:24}}/></IconButton></a></li>
          <li className="ms-3"><a className="link-dark" href="https://www.instagram.com/kamplasana/"><IconButton><InstagramIcon sx={{height:24,width:24}}/></IconButton></a></li>
          <li className="ms-3"><a className="link-dark" href="https://x.com/kamplasana"><IconButton><TwitterIcon sx={{height:24,width:24}}/></IconButton></a></li>

          </ul>
        </div>

      </footer>
    </div>
  )
}
