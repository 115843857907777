import React, { useEffect, useState } from 'react';
import { Domain } from '../../../Domain';
import { useNavigate,useLocation } from 'react-router-dom';
import {TourDashboard} from '../../../common';
import Box from '@mui/material/Box';
import { Button, Toolbar } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { formatDateYearFirst } from '../../../utils';

export const TourProfile = () => {

    let navigate = useNavigate();
    const location = useLocation();
    const [data,setData] = useState({});
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [error,seterror] = useState(false);
    const [success,setSuccess] = useState(false);

    const { t, i18n } = useTranslation();

    const [firstname,setFirstname] = useState(data.firstName);
    const [lastName,setLastName] = useState(data.lastName);
    const [email,setEmail] = useState(data.email);
    const [birthday,setBirthday] = useState(formatDateYearFirst(data.birthday));
    const [iban,setIban] = useState(data.IBAN);
    const [accountName,setAccountName] = useState(data.bankAcountName);
    const [nationalId,setNationalId] = useState(data.nationalID);

    useEffect(() => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`${Domain}/api/users/me`, requestOptions)
        .then(response => response.text())
        .then(result => {
          if(result==="Forbidden") {
            localStorage.clear();
            navigate('/login');
          }
          else{
            const res = JSON.parse(result);
            setFirstname(res.firstName);
            setLastName(res.lastName);
            setEmail(res.email);
            setIban(res.IBAN);
            setData(res);
            setBirthday(formatDateYearFirst(res.birthday));
            setAccountName(res.bankAcountName);
            setNationalId(res.nationalID);

          }
        })
        .catch(error => console.log('error', error));
      
  
    }, [])
    

    const editUser = ()=>{
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);
      
      var raw = JSON.stringify({
        "firstName": firstname,
        "lastName": lastName,
        "birthday": birthday
      });
      
      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      
      fetch(`${Domain}/api/users`, requestOptions)
        .then(response => response.text())
        .then(result => {
          if(result==="Forbidden") {
            localStorage.clear();
            navigate('/login');
          }
          else{
              const res = JSON.parse(result);
              if(res._id === data._id){
                setSuccess(true);
              }
              else{
                seterror(true);
              }
              handleShow();
          }
        })
        .catch(error => console.log('error', error));

    }
    const editUserBank = () => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

      var raw = JSON.stringify({
        "bankAcountName": accountName,
        "IBAN": iban
      });

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${Domain}/api/users/bankinfo`, requestOptions)
        .then(response => response.text())
        .then(result => {
          if(result==="Forbidden") {
            localStorage.clear();
            navigate('/login');
          }
          else{
              const res = JSON.parse(result);
              if(res._id === data._id){
                setSuccess(true);
              }
              else{
                seterror(true);
              }
              handleShow();
          }
        })
        .catch(error => console.log('error', error));


    }
    // const paymentTransfer = () =>{
    //   var myHeaders = new Headers();
    //   myHeaders.append("Content-Type", "application/json");
    //   myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

    //   var raw = JSON.stringify({
    //     "totalPrice": "50.99",
    //     "campingSellerId": JSON.parse(localStorage.getItem('user'))._id
    //   });

    //   var requestOptions = {
    //     method: 'POST',
    //     headers: myHeaders,
    //     body: raw,
    //     redirect: 'follow'
    //   };

    //   fetch(`${Domain}/api/payments/transfer`, requestOptions)
    //     .then(response => response.text())
    //     .then(result => {
    //       console.log(result);
    //     })
    //     .catch(error => console.log('error', error));
    // }


    return (
      data._id !== undefined &&
        <>
          <Box sx={{ display: 'flex' }}>
          <TourDashboard /> 
          <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            padding: 3,
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Grid sx={{backgroundColor:'#fff',paddingBottom:5,paddingRight:5, marginTop:3,width:'auto',borderRadius:2,marginLeft:2}} container spacing={4}>
            <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h4" >
                 {t('PersonalInfo')} 
               </Typography>
            </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e)=>{setFirstname(e.target.value);}}
            value={firstname}
            required
            id="firstName"
            name="firstName"
            label={t("FirstName")}
            fullWidth
            autoComplete="given-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={lastName}
            onChange={(e)=>{setLastName(e.target.value);}}
            required
            id="lastName"
            name="lastName"
            label={t("LastName")}
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            disabled
            value={email}
            onChange={(e)=>{setEmail(e.target.value.toLowerCase());}}
            required
            label={t("mail1")}
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6} style={{marginTop:-40}}>
        <div className="form-area">
        <p>{t("birthDate21")}</p>
            <input type="date" value={birthday} onChange={(e)=>{setBirthday(e.target.value)}} formcontrolname="email" className="mat-input-element mat-form-field-autofill-control form-text cdk-text-field-autofill-monitored cdk-text-field-autofilled"/>
        </div>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  sx={{ mt: 3, ml: 1 }}
                  onClick={()=>{editUser();}}
                >
                    {t('update')}
                </Button>
              </Grid>
        </Grid>
        <Grid sx={{backgroundColor:'#fff',paddingBottom:5,paddingRight:5, marginTop:3,width:'auto',borderRadius:2,marginLeft:2}} container spacing={4}>
            <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h4" >
                  {t('BankInfo')}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
          <TextField
            value={accountName}
            onChange={(e)=>{setAccountName(e.target.value);}}
            required
            id="Bank Account Name"
            name="Bank Account Name"
            label={t('bankAccountName')}
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            value={nationalId}
            disabled
            onChange={(e)=>{setNationalId(e.target.value);}}
            required
            label="T.C"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={iban}
            onChange={(e)=>{setIban(e.target.value);}}
            required
            label="IBAN"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>

          {/* <Button
            variant="contained"
            sx={{ mt: 3, ml: 1 }}
            onClick={()=>{paymentTransfer()}}
          >
              Transfer Money to bank
          </Button> */}
          <Button
            variant="contained"
            sx={{ mt: 3, ml: 1 }}
            onClick={()=>{editUserBank()}}
          >
              {t('update')}
          </Button>
        </Grid>
        </Grid>
        </Box>
        
        </Box>
        <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                <Modal.Title> {error ?  "Warning!" : "Success"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {
                  error ? <div>an Error Ocurred!</div> : success?
                  <div> User Updated Successfully!</div> : <></>

                }
                </Modal.Body>
                <Modal.Footer>
                  {<button onClick={()=>{
                    if(success||error){
                      setSuccess(false);
                      seterror(false);
                      handleClose();
                      window.location.reload();
                    }
                    else{
                      handleClose();
                    }
                  }} className="btn btn-outline-dark"> Okay</button>  
                  }
                </Modal.Footer>
        </Modal>   
        </>
    )
}
