import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import {Select,Option} from '@mui/joy';

export function SortingSelector() {
  const { t } = useTranslation();
  const cacheValue = localStorage.getItem('sortby') ? localStorage.getItem('sortorder') + localStorage.getItem('sortby') : "OrderBy";
  const [value, setValue] = React.useState(t(cacheValue));

  const handleChange = (event, newValue) => {
    if(newValue !== null) {
      const{sortBy,order} = newValue;
      setValue(t(order+sortBy));
      localStorage.setItem('sortorder',order);
      localStorage.setItem('sortby',sortBy);
      window.location.reload();
    }
  };

  return (
    <div>
      <FormControl sx={{ m: 1 }} variant="standard">
        <Select
          onChange={handleChange}
          placeholder={value}
          value={value}
        >
          <Option value={{sortBy:'createdAt',order:'asc'}}>{t('asccreatedAt')}</Option>
          <Option value={{sortBy:'createdAt',order:'desc'}}>{t('desccreatedAt')}</Option>
          <Option value={{sortBy:'price',order:'asc'}}>{t('ascprice')}</Option>
          <Option value={{sortBy:'price',order:'desc'}}>{t('descprice')}</Option>
        </Select>
      </FormControl>
    </div>
  );
}