import React, { useEffect, useState } from 'react';
import { Safety } from '../Home/Components/Safety';
import { SafetyPartners } from '../Home/Components/SafetyPartners';
import { Footer,LoadingModal,Navbar} from '../../common';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import './details.css';
import Avatar from '@mui/material/Avatar';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import CheckoutCard from './components/CheckoutCard';
import { Domain } from '../../Domain';
import { useNavigate, useParams } from 'react-router-dom';
import NetworkWifiIcon from '@mui/icons-material/NetworkWifi';
import KeyIcon from '@mui/icons-material/Key';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TodayIcon from '@mui/icons-material/Today';
import HikingIcon from '@mui/icons-material/Hiking';
import { DateRange} from 'react-date-range';
import { addDays } from 'date-fns';
import { CommentSection } from './components/CommentSection';
import Textarea from '@mui/joy/Textarea';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import { Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel'

import Rating from '@mui/material/Rating';
import { convertDateToTime, formatDate,stringAvatar } from '../../utils';
import { ComingSoon } from './components/ComingSoon';
import { useTranslation } from 'react-i18next';
import imagedefault from "../../resources/images/iconDef.png"
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const customMarker = new L.icon({
  iconUrl: icon,    
  iconSize: [20, 30],
  iconAnchor: [20, 30]
});

export const TourDetails = () => {
  let {id} = useParams();
  const width = window.innerWidth;
  let navigate = useNavigate();

  const [rate,setRate] = useState(0);
  const [comment,setComment] = useState("");

  const [tour,setTour] = useState('empty');
  const [checkin,setX] = useState('');
  const [checkout,setY] = useState('');
  const [show,setShow] = useState(false);
  const [modalimage,setModalImage] = useState('');
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);
  const [selectedindex,setIndex] = useState(-1);



  

  useEffect(() => {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      fetch(`${Domain}/api/campingtours/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => 
          {setTour(result);})
        .catch(error => console.log('error', error));
  },[]);

  const {firstName,lastName} = JSON.parse(localStorage.getItem('user')) != undefined ?JSON.parse(localStorage.getItem('user')) : {"firstName":"A","lastName":"B"};

  const AddComment = ()=>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);
    const a7a = String(rate);
    var raw = JSON.stringify({
      "rate": a7a,
      "text": comment
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${Domain}/api/campingTours/add/comment/${tour.campingTourId}`, requestOptions)
      .then(response => response.text())
      .then(result => {
        if(result==='Forbidden'){
          navigate('/login');
        }
        else{
          window.location.reload();

        }
      })
      .catch(error => console.log('error', error));
  }
  const {t} = useTranslation();


  return (
    tour === 'empty'? <LoadingModal visible={tour === 'empty'}/>
    :
    <>
        <div >
            <Navbar isSearchVisible={false}/>
            <div className='pt-5  bg-light home-body overflow-hidden'>
                <Breadcrumb className='px-2'>
                    <Breadcrumb.Item href="#">Türkiye</Breadcrumb.Item>
                    <Breadcrumb.Item href="#">{tour.location.province}</Breadcrumb.Item>
                    <Breadcrumb.Item href="#">{tour.location.district}</Breadcrumb.Item>
                </Breadcrumb>
                <h2 style={{fontWeight:700}} className='px-2 mb-4 '>{tour.title}</h2>
    
                <div className='px-2 mb-5'>
                { tour !== '' && tour.image.length > 0 &&
                  <ImageList className='hide-scrollbars' sx={ tour.image.length <= 1 ? { width: 'auto',height:600} :{  width: 'auto',maxHeight:400,}} cols={tour.image.length == 1 ? 2:4} rowHeight={200}>
                  {tour.image.map((item,index) => (
                    <ImageListItem  cols={ index % 5 == 0 ? 2 : 1} rows={index % 5 == 0 ? 2 : 1}>
                    <img
                      onClick={()=>{
                        setIndex(index);
                        setShow(true)
                      }}
                      src={item.path}
                      style={tour.image.length == 1 ? {maxHeight:600,objectFit:'cover', padding:20 } : index % 5 == 0 ? {maxHeight:400, objectFit:'fill' } : {maxHeight:200,paddingBottom:5}}
                      loading="lazy"
                    />
                  </ImageListItem>
                  ))}
                </ImageList>
                }
                </div>
                <div className='row g-2'>
                    <div className='col-lg-8'>
                      <div className='px-2 mt-5 g-3 row'>
                        <div className='col-lg-4 col-md-6 col-sm-6  col-12'>
                          <div className='row pb-5 pe-3'>
                            <div className='col-3  m-0'>
                            <Avatar style={{width:'50px',height:'50px'}}>
                            </Avatar>
                            </div>
                            <div className='col-9 '>
                            <h2 className='' style={{fontSize:18,fontWeight:700}}>{t('TourOwner')}</h2> 
                            <h4 style={{fontSize:20}} className=''> {tour.owner.firstName} {tour.owner.lastName}</h4>
                            </div>
                            <button className='btn btn-outline-dark w-100 h-auto m-auto mt-2'> Contact Host</button>
                          </div>
                        </div>
                        <div className='col-lg-8 col-md-6 col-sm-6 col-12'>
                            <p className=' pb-3' style={{fontWeight:500,textAlign:'justify',wordWrap:'break-word'}}> {tour.description} </p>
                        </div>
                      </div>
                      <div className='px-2 mt-2 row g-2'>
                        <h4  style={{fontWeight:700}}>{t('offersPlace1')}</h4>
                        <h6 className='text-muted' style={{fontWeight:200}}>{t('offersPlaceDesc')}</h6>
                        {
                          tour !== '' && tour.standardAmenities.map((item)=>(
                            <div  className='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 g-2'>
                                <img style={{width:"70px",height:"70px"}} className='p-3  d-inline ' src={item.iconUrl.length > 0 ? item.iconUrl: imagedefault}/>
                                <h6 style={{fontWeight:500}} className='mt-2  d-inline'> {item.name}</h6>
                            </div>
                          ))
                        }
                        {
                          tour !== '' && tour.publicArea.map((item)=>(
                            <div  className='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 g-2'>
                              {item.name === 'Wi-fi' ? <NetworkWifiIcon className='p-3  d-inline ' style={{width:"70px",height:"70px"}} /> :  <img style={{width:"70px",height:"70px"}} className='p-3  d-inline ' src={item.iconUrl.length > 0 ? item.iconUrl: imagedefault}/>                                                            }
                              <h5 style={{fontWeight:500}} className='mt-2 d-inline'> {item.name}</h5>
                            </div>
                          ))
                        }
                        {
                          tour !== '' && tour.activities.map((item)=>(
                            <div  className='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 g-2'>
                              <img style={{width:"70px",height:"70px"}} className='p-3  d-inline ' src={item.iconUrl.length > 0 ? item.iconUrl: imagedefault}/>
                              <h5 style={{fontWeight:500}} className='mt-2 d-inline'> {item.name}</h5>
                            </div>
                          ))
                        }
                        {
                          tour !== '' && tour.terrain.map((item)=>(
                            <div  className='col-xl-4 col-lg-6 col-md-4 col-sm-6 col-12 g-2'>
                                <img style={{width:"70px",height:"70px"}} className='p-3  d-inline ' src={item.iconUrl.length > 0 ? item.iconUrl: imagedefault}/>
                                <h5 style={{fontWeight:500}} className='mt-2 d-inline'> {item.name}</h5>
                            </div>
                          ))
                        }
                        <hr/>
                      </div>
                      <div className='px-2 mt-2  row'>
                        <div className='col-lg-12 col-md-8 col-12 pb-3'>
                            <h4  style={{fontWeight:700}}>{t('DetailsHeader')}</h4>
                            <h6 className='text-muted' style={{fontWeight:200}}>{t('DetailsDesc')}</h6>                              <div className='row pt-3 g-3'>
                            <div className='row pt-3 g-3'>
                                <div className='col-lg-6 col-md-12'>
                                  <KeyIcon className='px-1  d-inline ' style={{width:"40px",height:"40px"}} />
                                  <h6 style={{fontWeight:500,fontSize:16}} className='mt-2 d-inline'> {t("CheckInRule")} : <strong>{convertDateToTime(tour.checkInRule)}</strong></h6> 
                                </div> 
                                <div className='col-lg-6 col-md-12'>
                                  <KeyOffIcon className='px-1  d-inline'style={{width:"40px",height:"40px"}} />
                                  <h6 style={{fontWeight:500,fontSize:16}} className='mt-2 d-inline'> {t("CheckOutRule")} : <strong>{convertDateToTime(tour.checkOutRule)}</strong></h6> 
                                </div>
                                <div className='col-lg-6 col-md-12'>

                                  <HighlightOffIcon className='px-1  d-inline ' style={{width:"40px",height:"40px"}} />
                                  <h6 style={{fontWeight:500,fontSize:16}} className='mt-2 d-inline'> {t("Cancellation Policy")} : <strong>{t("Moderate")}</strong></h6>                                
                                </div>
                                <div className='col-lg-6 col-md-12'>
                                  <TodayIcon className='px-1  d-inline ' style={{width:"40px",height:"40px"}} />
                                  <h6 style={{fontWeight:500,fontSize:16}} className='mt-2 d-inline'> {t("Minimum Days1")} : <strong> {tour.minimumDays} {t("day")}</strong></h6>                                
                                </div>
                              </div>
                              </div>
                        </div>
                        <hr/>
                      </div>
                      {/* Comment section */}
                     <div className='px-2 g-3 row'>
                        <div className='col-lg-12 col-md-8 col-12 pb-3'>
                        <h4 className='my-3' style={{fontWeight:700}}>{t("Comments")}</h4>
                        <div className='ps-2 pt-3 row g-3'>
                          <div className='col-sm-1 col-12'>
                          <Avatar style={{cursor:'pointer'}} {...stringAvatar(firstName,lastName)}  />
                          </div>
                          <div className='col-sm-11 col-12'>
                          <Textarea
                            placeholder={t('addComment')}
                            onChange={(e)=>{
                              setComment(e.target.value);
                            }}
                            value={comment}
                            minRows={3}
                            endDecorator={
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: 'var(--Textarea-paddingBlock)',
                                  pt: 'var(--Textarea-paddingBlock)',
                                  borderTop: '1px solid',
                                  borderColor: 'divider',
                                  flex: 'auto',
                                }}
                              >
                                <Typography> Rating :</Typography>
                                <Rating name="simple-controlled"
                                  value={rate}
                                  onChange={(event, newValue) => {
                                    setRate(newValue);
                                  }} />
                                <Button onClick={()=>{AddComment();
                                }} sx={{ ml: 'auto' }}>{t('Submit')}</Button>
                              </Box>
                            }
                            sx={{
                              width:'auto',
                              minWidth: 200,
                            }}
                          />
                          </div>
                        
                        </div>

                        <ul id="comments-list" className="comments-list">
                          {tour.comments.length > 0 && tour.comments.map(comment => (
                            <CommentSection comment={comment}/>
                          ))}
                        </ul>
                        </div>    

                      </div>
                      
                      {/* Todo: checkout reserv */}
                      {/* <div className='px-5 g-3 row'>
                      <div className='col-lg-12 col-md-8 col-12 pb-3'>
                      <h4 className='my-3' style={{fontWeight:700}}>Book the Lodge</h4>
                        <div className='date-picker-details'>
                          <DateRange
                              onChange={(item) => {
                                  setDate([item.selection]);
                                  let x = formatDate([item.selection][0].startDate);
                                  let y = formatDate([item.selection][0].endDate);
                                  setX(x);
                                  setY(y);                                                                      
                              }}
                              months={width>799 ? 2 : 1}
                              minDate={new Date()}
                              moveRangeOnFirstSelection={false}
                              ranges={date}
                              rangeColors={['#d66']}
                              direction='horizontal'
                          />  
                        </div>
                        <div className=' d-flex flex-row-reverse'>
                        <button onClick={()=>{setDate([{startDate: new Date(), endDate: addDays(new Date(), 2),key: 'selection'}])}} className=' btn btn-outline-dark'>Clear Dates</button>
                        </div>
                      </div>     
                                
                    </div> */}

                    </div>
                    <div  className=' my-3 ps-5 pe-5 col-lg-4'>
                        <ComingSoon/>
                        {/* <CheckoutCard data={{'tour':tour,'checkin':checkin,'checkout':checkout,}}/> */}
                    </div>
                </div>
                <div className='px-2 mb-5 mt-5 row'>
                <hr/>
                <h3 className='pb-3'>{t('LocationOnMap')}</h3>
                  <MapContainer  center={[tour.coordinate.latitude, tour.coordinate.longitude]} zoom={5} style={{ height: '400px', width: '100%' }}>
                    <TileLayer
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {
                          tour.coordinate?.latitude !== undefined &&  tour.coordinate?.longitude !== undefined &&
                          <Marker
                            icon={customMarker}
                            position={[parseFloat(tour.coordinate.latitude),parseFloat(tour.coordinate.longitude)]}> 
                            <Popup>{t('YourLocation')}</Popup>
                          </Marker>
                        }
                  </MapContainer>
                </div>
            </div>
            </div>
            <div className='bg-lgreen py-5'>
                <div className='home-body overflow-hidden'>
                    <Safety/>
                </div>
            </div>
            {/* <div className='bg-light'>
                <div className='home-body overflow-hidden'>
                    <SafetyPartners/>
                </div>    
            </div> */}
            <Footer current={''}/>
            {
              tour !== '' && tour.image.length > 0 &&
              <Lightbox
              slides={tour.image.map(item => ({ src: item.path }))}
              open={selectedindex >= 0}
              index={selectedindex}
              close={() => setIndex(-1)}
            />
            }
    </>
  )
}