import React from 'react';
import Modal from 'react-bootstrap/Modal';

export const ResponsiveNavbar = ({setShow,show,navigate,Logo,t,logged}) => {
  return (
    <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
            <Modal.Title>                    
            <div style={{cursor:'pointer'}} onClick={()=>{navigate('../home')}}  className="logo"><img style={{width:200,padding:20,borderBottomLeftRadius:20,borderBottomRightRadius:20}} src={Logo}/></div>
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='container-fluid'>
        <div onClick={()=>{navigate('../about')}} className='modal_links row'>{t('Aboutus')} </div>
        <div onClick={()=>{navigate('../camps')}} className='modal_links row'>{t('Camps')}</div>
          <div onClick={()=>{navigate('../tours')}} className='modal_links row'>{t('Tours')}</div>
          <div onClick={()=>{navigate('../blogs')}} className='modal_links row'>{t('Blog')} </div>
          { logged ?
            <>
              <div className='modal_links row'>{t('Profile')}</div>
              <div className='modal_links row'>{t('Favorites')}</div>
            </>
            :
            <>
              <div onClick={()=>{navigate('../login')}} className='modal_links row'> {t('login')} </div>
              <div onClick={()=>{navigate('../signup-camper')}} className='modal_links row signup-fluidtext'> {t('signup')} </div>
            </>
          }
        </div>
        </Modal.Body>
    </Modal>
  )
}