import React, { useEffect, useState } from 'react'
import {AdminDashboard, UserChart,Deposits,TopHost,TotalUsers} from '../../../common';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import Paper from '@mui/material/Paper';
import { Domain } from '../../../Domain';
import { useNavigate } from 'react-router-dom';

export const AdminDashboardMain = () => {

  const [users,setUsers] = useState([]);
  const [hosts,setHosts] = useState([]);
  let navigate = useNavigate();
  const [reserves, setReserves] = useState([]);

  const today = new Date();
  const [MonthPayout,setMonthlyPayout] = useState(0);
  const [MonthReserve,setMonthlyReserves] = useState(0);
  const [YearDataSet,setYearlyDataSet] = useState("empty");
  const [loaded,setLoaded] = useState(false);

  console.log(YearDataSet);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    //Fetch Hosts
    fetch(`${Domain}/api/admin/hosts`, requestOptions)
      .then(response => response.text())
      .then(result => {
          if (result === 'Forbidden'){
            navigate('/login');
          }
          else{
            const hosts = JSON.parse(result);
            setHosts(hosts);
          }
      })
      .catch(error => console.log('error', error));
    //Fetch Users
    fetch(`${Domain}/api/admin/users`, requestOptions)
      .then(response => response.text())
      .then(result => {
          if (result === 'Forbidden'){
            navigate('/login');
          }
          else{
            const users = JSON.parse(result);
            setUsers(users);
          }
      })
    .catch(error => console.log('error', error));

    //Fetch reserves
    fetch(`${Domain}/api/admin/reserves`, requestOptions)
    .then(response => response.text())
    .then(result =>{ console.log(result);
        if(result =='Forbidden'){
            localStorage.clear();
            navigate('/login');
        }
        else{
            const res =JSON.parse(result);
            setReserves(res);
        }
    })
    .catch(error => console.log('error', error));
   setLoaded(true);
   GetMonthlyPayout();

  }, []);
  useEffect(() => {
    GetMonthlyPayout();
  }, [users,hosts,reserves]);

  console.log(MonthPayout);

  const GetMonthlyPayout = () => {
    const monthsOfYear = {"January": 0, "February": 0, "March": 0, "April": 0, "May": 0, "June": 0, "July": 0, "August": 0, "September":0, "October":0, 
          "November":0, "December":0}

    users.forEach(element => {
      const dateTobeChecked = new Date(element.createdAt);
      if(dateTobeChecked.getFullYear()===today.getFullYear()){
        switch(dateTobeChecked.getMonth()){
            case(0):
                monthsOfYear.January = monthsOfYear.January + 1
                break;
            case(1):
                monthsOfYear.February = monthsOfYear.February + 1
                break;
            case(2):
                monthsOfYear.March = monthsOfYear.March + 1;
                break;
            case(3):
                monthsOfYear.April  = monthsOfYear.April + 1;
                break;
            case(4):
                monthsOfYear.May = monthsOfYear.May + 1;
                break;
            case(5):
                monthsOfYear.June = monthsOfYear.June + 1;
                    break;
            case(6):
                monthsOfYear.July = monthsOfYear.July + 1;
                    break;
            case(7):
                monthsOfYear.August = monthsOfYear.August + 1;
                  break;
            case(8):
                monthsOfYear.September = monthsOfYear.September + 1;
              break;
            case(9):
                monthsOfYear.October = monthsOfYear.October +1;
                break;
            case(10):
                monthsOfYear.November = monthsOfYear.November + 1;
                break;
            case(11):
                monthsOfYear.December = monthsOfYear.December + 1;
        }
      }

    });
    hosts.forEach(element => {
      const dateTobeChecked = new Date(element.createdAt);
      if(dateTobeChecked.getFullYear()===today.getFullYear()){
        switch(dateTobeChecked.getMonth()){
            case(0):
                monthsOfYear.January = monthsOfYear.January + 1
                break;
            case(1):
                monthsOfYear.February = monthsOfYear.February + 1
                break;
            case(2):
                monthsOfYear.March = monthsOfYear.March + 1;
                break;
            case(3):
                monthsOfYear.April  = monthsOfYear.April + 1;
                break;
            case(4):
                monthsOfYear.May = monthsOfYear.May + 1;
                break;
            case(5):
                monthsOfYear.June = monthsOfYear.June + 1;
                    break;
            case(6):
                monthsOfYear.July = monthsOfYear.July + 1;
                    break;
            case(7):
                monthsOfYear.August = monthsOfYear.August + 1;
                  break;
            case(8):
                monthsOfYear.September = monthsOfYear.September + 1;
              break;
            case(9):
                monthsOfYear.October = monthsOfYear.October +1;
                break;
            case(10):
                monthsOfYear.November = monthsOfYear.November + 1;
                break;
            case(11):
                monthsOfYear.December = monthsOfYear.December + 1;
        }
      }

    });
    let monthlyIncome = 0;
    let monthlyReservations = 0;
    reserves.forEach(element => {
      const dateTobeChecked = new Date(element.createdAt);
      if(dateTobeChecked.getFullYear()===today.getFullYear()){
        if(dateTobeChecked.getMonth()===today.getMonth()) {
          monthlyIncome += element.totalPrice;
          monthlyReservations++;
        }
      }
    });
    setYearlyDataSet(monthsOfYear);
    setMonthlyPayout(monthlyIncome);
    setMonthlyReserves(monthlyReservations);
  }


  return (
    YearDataSet !== "empty" &&
    <Box sx={{ display: 'flex' }}>
      <AdminDashboard />
      <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* Chart */}
              <Grid item xs={12} md={4} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <TopHost />
                </Paper>
              </Grid>
              {/* Recent Deposits */}
              <Grid item xs={12} md={4} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <Deposits goToHost={()=>{navigate('/admin/reservations')}} profit={MonthPayout} reserveno={MonthReserve} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <TotalUsers goToHost={()=>{navigate('/admin/hosts')}} users={users.length} hosts={hosts.length}/>
                </Paper>
              </Grid>
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <UserChart dataSet={YearDataSet}/>
                </Paper>
              </Grid>
            </Grid>
            {/* <Copyright sx={{ pt: 4 }} /> */}
          </Container>
        </Box>
    </Box>
  )
}
