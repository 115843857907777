import React, { useEffect, useState } from 'react';
import { Domain } from '../../../Domain';
import { useNavigate,useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import {AdminDashboard} from '../../../common';
import Box from '@mui/material/Box';
import { Button, Toolbar } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useTranslation } from "react-i18next";

export const EditUser = () => {

    let navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const data = location.state;
    
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [error,seterror] = useState(false);
    const [success,setSuccess] = useState(false);
    const [edit,setEdit] = useState(false);
    const [id,setId] = useState('');

    const [firstname,setFirstname] = useState(data.firstName);
    const [lastName,setLastName] = useState(data.lastName);
    const [birthday,setBirthday] = useState(data.birthday);
    console.log(data);

    const UpdateUser = () =>{

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("accessToken")}`);

      var raw = JSON.stringify({
        "id": data._id,
        "firstName":firstname,
        "lastName": lastName,
        "birthday": birthday
      });

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${Domain}/api/admin/users`, requestOptions)
        .then(response => response.text())
        .then(result =>{ 
          console.log(result);
          if(result==="Forbidden") {
            localStorage.clear();
            navigate('/login');

          }
          else{
              const res = JSON.parse(result);
              if(res._id === data._id){
                setSuccess(true);
              }
              else{
                seterror(true);
              }
              handleShow();
          }
          
        })
        .catch(error => console.log('error', error));
    }


    return (
        <>
          <Box sx={{ display: 'flex' }}>
          <AdminDashboard /> 
          <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            padding: 3,
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Grid sx={{backgroundColor:'#fff',paddingBottom:5,paddingRight:5, marginTop:3,width:'auto',borderRadius:2,marginLeft:2}} container spacing={4}>
            <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h4" >
                    Update user
                </Typography>
            </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e)=>{setFirstname(e.target.value);}}
            value={firstname}
            required
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={lastName}
            onChange={(e)=>{setLastName(e.target.value);}}
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
          value={birthday}
            onChange={(e)=>{setBirthday(e.target.value);}}
            required
            id="BirthDate"
            name="BirthDate"
            label={t('birthDate21')}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  sx={{ mt: 3, ml: 1 }}
                  onClick={()=>{UpdateUser()}}
                >
                    Update
                </Button>
              </Grid>
        </Grid>
        </Box>
        </Box>
        <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                <Modal.Title> {error ?  "Warning!" : "Success"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {
                  error ? <div>an Error Ocurred!</div> : success?
                  <div> User Updated Successfully!</div> : <></>

                }
                </Modal.Body>
                <Modal.Footer>
                  {<button onClick={()=>{
                    if(success||error){
                      setSuccess(false);
                      seterror(false);
                      handleClose();
                      if(success){
                        navigate('/admin/users');
                      }
                      else{
                        window.location.reload();
                      }
                    }
                    else{
                      handleClose();
                    }
                  }} className="btn btn-outline-dark"> Okay</button>  
                  }
                </Modal.Footer>
        </Modal>   
        </>
    )
}
