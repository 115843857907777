export function convertDateToTime(dateStr) {
    const date = new Date(dateStr);

    // Convert UTC time to Turkey Time (TRT) which is UTC+3
    const hours = (date.getUTCHours() + 3) % 24;  // Adjust hours to TRT
    const minutes = date.getUTCMinutes();
    
    // Format hours and minutes to ensure two digits
    const hoursFormatted = hours.toString().padStart(2, '0');
    const minutesFormatted = minutes.toString().padStart(2, '0');
    
    // Format the time as HH:mm
    return `${hoursFormatted}:${minutesFormatted}`;
}