import React, { useState,useEffect} from 'react';
import './styles.css';
import { Footer,Navbar,LoadingModal} from '../../common';
import { useTranslation } from 'react-i18next';
import { Domain } from '../../Domain';
import Modal from 'react-bootstrap/Modal';

export const ContactUs = () => {
    const { t } = useTranslation();
    const [email,setEmail] = useState();
    const [name,setName] = useState();
    const [lastname,setLast] = useState();
    const [description,setDesc] = useState();
    const [visible,setVisible] = useState(true);
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [error,seterror] = useState(false);
    const [success,setSuccess] = useState(false);
    const [message,setMessage] = useState('');

    const SubmitForm = ()=>{
        setVisible(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

        var raw = JSON.stringify({
        "email": email,
        "firstName": name,
        "lastName": lastname,
        "description": description
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${Domain}/api/contacts`, requestOptions)
        .then(response => response.text())
        .then(result => {
            setVisible(false);
            setTimeout(handleShow,500);
            if(result==='Forbidden'){
                seterror(true);
                setMessage('NoAuthMessage');
            }
            else{
                setSuccess(true);
                setMessage('ContactMessage');
            }

            
        })
        .catch(error => console.log('error', error));
    }
    useEffect(() => {
        // Your code here
        setVisible(false);
    }, []);

    return (
        <div className='bg-main'>
        <Navbar></Navbar>
        <div  style={{backgroundColor:"#F8F9FA", borderRadius:20}} className="container p-5 mt-5 mb-5">
            <div className="wrapper">
                <div className="form">
                    <h4 className='text-uppercase'>{t('getIntouch')}</h4>
                    <h2 className="form-headline"> {t('sendUSMessage')}</h2>
                    <div className='mt-4 contact-us-form' id="submit-form">
                    <p>
                        <input id="name" onChange={(e)=>{setName(e.target.value)}} className="form-input" style={{border: '1px solid #bdbdbd',borderRadius: 5}} type="text"  placeholder={t('FirstName')}/>
                        <small className="name-error"></small>
                    </p>
                    <p>
                    <input id="name"  onChange={(e)=>{setLast(e.target.value)}} className="form-input" style={{border: '1px solid #bdbdbd',borderRadius: 5}} type="text" placeholder={t('LastName')}/>
                        <small className="name-error"></small>
                    </p>
                    <p className="full-width">
                    <input id="email"  onChange={(e)=>{setEmail(e.target.value.toLowerCase())}} className="form-input" type="email" placeholder={t('placeHolderEmail')}/>
                        <small></small>
                    </p>
                    <p className="full-width">
                        <textarea onChange={(e)=>{setDesc(e.target.value)}}  minlength="20" id="message" cols="30" rows="7"  placeholder={t('message')} required></textarea>
                        <small></small>
                    </p>
                    <p className="full-width">
                        <button  className="btn w-100 btn-danger submit-btn" onClick={()=>{SubmitForm();}}>{t("Submit")}</button>
                    </p>
                    </div>
                </div>
                <div className="contacts contact-wrapper ">

                    <ul>
                    <li>{t('motive')}</li>
                    <span className="hightlight-contact-info">
                        <li className="email-info"><i className="fa fa-envelope" aria-hidden="true"></i> info@kamplasana.com</li>
                        <li><i className="fa fa-phone" aria-hidden="true"></i> <span className="highlight-text">+90 531 836 60 20</span></li>
                    </span>
                    </ul>
                </div>
            </div>
        </div>  
        <LoadingModal visible={visible}/>
        <Footer current={'home'}/>
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
        <Modal.Header closeButton>
        <Modal.Title> {error ?  t("Error") : t("ThankYou")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {
            <div className='text-center'>{t(message)}  </div>

        }
        </Modal.Body>
        <Modal.Footer>
            {<button onClick={()=>{
            if(success||error){
                setSuccess(false);
                seterror(false);
                handleClose();
                if(success){
                }
                else{
                window.location.reload();
                }
            }
            else{
                handleClose();
            }
            }} className="btn btn-outline-dark"> {t('Ok')}</button>  
            }
        </Modal.Footer>
          </Modal>   
        </div>
    )
}
