import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CabinIcon from '@mui/icons-material/Cabin';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import TourIcon from '@mui/icons-material/Tour';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import ErrorIcon from '@mui/icons-material/Error';
import HomeIcon from '@mui/icons-material/Home';

export const MainListItems = ()=> {

  return(
  <React.Fragment>
    <ListItemButton href='/admin/dashboard'>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <ListItemButton href='/home'>
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText primary="Home" />
    </ListItemButton>
    
    <ListItemButton href='/admin/users'>
      <ListItemIcon>
        <PeopleAltIcon/>
      </ListItemIcon>
      <ListItemText primary="Users" />
    </ListItemButton>
    <ListItemButton href='/admin/hosts'>
      <ListItemIcon>
        <SupervisedUserCircleIcon/>
      </ListItemIcon>
      <ListItemText primary="Hosts" />
    </ListItemButton>
    <ListItemButton href='/admin/camps'>
      <ListItemIcon>
        <CabinIcon/>
      </ListItemIcon>
      <ListItemText primary="Camps" />
    </ListItemButton>
    <ListItemButton href='/admin/reservations'>
      <ListItemIcon>
        <EventSeatIcon/>
      </ListItemIcon>
      <ListItemText primary="Reservations" />
    </ListItemButton>
    <ListItemButton href='/admin/categories'>
      <ListItemIcon>
        <LocationCityIcon/>
      </ListItemIcon>
      <ListItemText primary="Categories" />
    </ListItemButton>
    <ListItemButton href='/admin/tours'>
      <ListItemIcon>
        <TourIcon/>
      </ListItemIcon>
      <ListItemText primary="Tours" />
    </ListItemButton>
    <ListItemButton href='/admin/tickets'>
      <ListItemIcon>
        <ConfirmationNumberIcon/>
      </ListItemIcon>
      <ListItemText primary="Tickets" />
    </ListItemButton>
    <ListItemButton href='/admin/transaction/error'>
      <ListItemIcon>
        <ErrorIcon/>
      </ListItemIcon>
      <ListItemText primary="Transaction Errors" />
    </ListItemButton>
    <ListItemButton href='/admin/transaction/dump'>
      <ListItemIcon>
        <SettingsApplicationsIcon/>
      </ListItemIcon>
      <ListItemText primary="Transaction Dump" />
    </ListItemButton>
    <ListItemButton href='/admin/blogs'>
      <ListItemIcon>
        <RssFeedIcon/>
      </ListItemIcon>
      <ListItemText primary="Blog" />
    </ListItemButton>

    <ListItemButton href='/home' onClick={()=>{
      localStorage.clear();
    }}>

      <ListItemIcon>
        <ExitToAppIcon/>
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItemButton>
  </React.Fragment>
)
  }
