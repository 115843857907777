import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

export const StyledInputBase = styled(TextField)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create('width'),
    borer:'0px solid',
    width: '100%',
    fontSize:   '11px',

    [theme.breakpoints.up('sm')]: {
      width: '14ch',
      '&:focus': {
        width: '14ch',
      },
    },
  },
}));

export const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fff',
    border: '1px solid',
    borderColor:'#fff',
    borderRadius: 30,
    marginLeft: 0,
    width: 'auto',
    fontSize:   '12px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));