import React, { useEffect, useState } from 'react';
import { Domain } from '../../../Domain';
import { useNavigate,useLocation } from 'react-router-dom';
import {AdminDashboard,TourDashboard,HostDashboard} from '../../../common';

import { Toolbar,Grid,Box,Typography } from '@mui/material';
import { CommentSection } from '../../CampingArea/components/CommentSection';


export const ToursComments = () => {
  const [comments,setComments] = useState([]);
  const location = useLocation();

  const getTourDetails = () =>{
    var myHeaders = new Headers();

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    const temp = location.state.tour;

    fetch(`${Domain}/api/campingareas/${temp.campingTourId}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setComments(result.comments);
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    getTourDetails();
  }, [])


    let navigate = useNavigate();


    return (
        <>
          <Box sx={{ display: 'flex' }}>
            {location.state.role==="admin" ?  <AdminDashboard /> : location.state.role==="tourhost" ? <TourDashboard/> :<HostDashboard/> }
          <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            padding: 3,
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Grid sx={{backgroundColor:'#fff',paddingBottom:5,paddingRight:5, marginTop:3,width:'auto',borderRadius:2,marginLeft:2}} container spacing={4}>
            <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h4" >
                    Camping Tour Comments
                </Typography>
            </Grid>
            <Grid xs ={12}>
            <ul id="comments-list" className="comments-list">
              {comments.length > 0 && comments.map(comment => (
                <CommentSection campingTourId={location.state.camp.campingTourId} campingAreaId={'empty'} role={location.state.role} comment={comment}/>
              ))}
          </ul>
            </Grid>
        </Grid>
        </Box>
        
        </Box>
        </>
    )
}
