import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CabinIcon from '@mui/icons-material/Cabin';
import ForestTwoToneIcon from '@mui/icons-material/ForestTwoTone';
import FestivalIcon from '@mui/icons-material/Festival';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
export const Category = ({name,selectedTypes,onClick,code}) => {
 
  const Icon = ()=>{
    switch(name){
      case "Glamping":
        return <ForestTwoToneIcon/>;
      case "Bungalov":
        return <CabinIcon/>;
      case "Çadır":
        return <FestivalIcon/>;
      case "Karavan":
        return <AirportShuttleIcon/>;
      default:
        return <CabinIcon/>;
    }
  }
  return (
  <Button onClick={onClick} className='category'  sx={[selectedTypes.indexOf(code) > -1 ? {background: "#e4e4e4"} :{background: "#fff"} ,{ width: 'auto',  minWidth:'auto', p: '5px 20px', display: 'flex',justifyContent:'flex-start', alignItems: 'center', width:'fit-content', height:'auto', marginInline:1 }]}
   variant={selectedTypes.indexOf(code) > -1 ? 'contained' : "outlined" }
    startIcon={<Icon />}>
    {name}
  </Button>
  )
}
