import React, { useState,useEffect } from 'react'
import { LoggedNavbar } from './LoggedNavbar';
import { GetMe } from '../../services/GetMe';
import { NonLoggedNavbar } from './NonLoggedNavbar';

export const Navbar = ({isSearchVisible}) => {

    const [logged,setLogged] = useState(false);
    const [userInfo,setUserInfo] = useState("empty");
    useEffect(() => {
        if(localStorage.getItem('accessToken')){
            GetMe(setUserInfo);
            setLogged(true);  
        }
    }, [])

    return (
        <>
            { !logged ? <NonLoggedNavbar isSearchVisible={isSearchVisible} /> : 
                <LoggedNavbar isSearchVisible={isSearchVisible} userInfo={userInfo}/>}        
        </>
  )
}
