import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Provider as ReduxProvider} from 'react-redux';
import { Store } from './StateManagement/store';
import './i18n.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ReduxProvider store ={Store}>
    <App />
  </ReduxProvider>
);
