import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import { Button } from '@mui/material';
import { formatDate } from '../../../utils/FormatDate';

export default function ReservationCard({reserve,goToCamp}) {
  

  return (
    <Card variant="outlined" sx={{ width: 320 }}>
      <CardOverflow>
        <AspectRatio ratio="2">
          <img
            src="https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318"
            srcSet="https://images.unsplash.com/photo-1532614338840-ab30cf10ed36?auto=format&fit=crop&w=318&dpr=2 2x"
            loading="lazy"
            alt=""
          />
        </AspectRatio>
      </CardOverflow>
      <CardContent>
        <Typography fontWeight={700} level="title-md">{reserve.title}</Typography>
        <Typography  paddingTop={1} level="body-sm"> <b>CheckIn:</b> {formatDate(reserve.checkIn)}  </Typography>
        <Typography level="body-sm"> <b> CheckOut: </b>{formatDate(reserve.checkOut)}</Typography>

        <Typography fontWeight={500} color='#000' paddingTop level="body-sm">Booked for <b> {reserve.numberOfPerson} Adults </b> and <b> {reserve.numberOfChild} Children </b> </Typography>
        <Typography level="body-sm"></Typography>

      </CardContent>
      <CardOverflow variant="soft" sx={{ bgcolor: 'background.level1' }}>
        <Divider inset="context" />
        <CardContent orientation="horizontal">
          <Typography level="body-xs" fontWeight="md" textColor="text.secondary">
            Total Paid
          </Typography>
          <Divider orientation="vertical" />
          <Typography level="body-xs" fontWeight="md" textColor="text.secondary">
                {reserve.totalPrice} TL
          </Typography>
          <Typography level="body-xs" fontWeight="md" textColor="text.secondary">
          </Typography>
          <Button sx={{bgcolor:"#EF791F",color:'#FFF'}} onClick={goToCamp}>
            Go to Camp
          </Button>
        </CardContent>
      </CardOverflow>
    </Card>
  );
}