import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Footer ,Navbar} from '../../common';
import {Card,CardActions,CardContent,Divider,FormControl,FormLabel,Input,Typography,Button} from '@mui/joy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Link from '@mui/material/Link';
import { Domain } from '../../Domain';
import Modal from 'react-bootstrap/Modal';
import LockResetIcon from '@mui/icons-material/LockReset';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';

export const ResetPassword = () => {
    let navigate = useNavigate();
    const [email,setEmail] = useState('');
    const [message,setMessage] = useState('');
    const [show,setShow] = useState(false);
    const sendResetRequest = ()=>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "email": email
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${Domain}/api/users/forgotpassword`, requestOptions)
        .then(response => response.json())
        .then(result => {
            setMessage('If a user with that email is registered you will receive a password reset email!');
            setShow(true);
        })
        .catch(error =>{
            console.log('error: ', error);
        });
    }
    return (
        <>
        <Navbar isSearchVisible={false}/>
        <div className='bg-main'>
            <div className='p-5 container'>
                <div className='row'>
                    <Card
                        variant="outlined"
                        sx={{
                            maxHeight: 'max-content',
                            maxWidth: '900px',
                            mx: 'auto',
                            paddingLeft:'60px',
                            paddingRight:'60px',
                            paddingTop:'30px',
                            paddingBottom:'30px'
                        }}
                        >
                        <Typography textColor={'gray'} textAlign={'center'} level="h2" fontSize="xl" >
                            <LockResetIcon style={{width:'50px',height:'50px'}} className="border rounded "/>
                        </Typography>
                        <Typography textAlign={'center'} level="h2"  >
                            Reset Password
                        </Typography>
                        <Divider inset="none" />
                        <CardContent
                            sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, minmax(80px, 1fr))',
                            gap: 1.5,
                            }}
                        >
                            <FormControl sx={{ gridColumn: '1/-1' }}>
                            <FormLabel>New Password</FormLabel>
                            <Input onChange={(e)=>{setEmail(e.target.value.toLowerCase());}} endDecorator={<LockOpenIcon />} />
                            </FormControl>
                            
                            <FormControl sx={{ gridColumn: '1/-1' }}>
                            <FormLabel>Confirm Password</FormLabel>
                            <Input onChange={(e)=>{setEmail(e.target.value.toLowerCase());}} endDecorator={<LockIcon />} />
                            </FormControl>
                            
                            <CardActions sx={{ gridColumn: '1/-1' }}>
                            <Button onClick={()=>{ navigate('../home')}} variant="solid">
                                Reset Password
                            </Button>
                            </CardActions>
                    
                        </CardContent>
                        <Link className='mt-3' href="../../../login" underline='none' color="inherit">

                        <Typography justifyContent={'center'} textColor={'gray'} textAlign={'center'} level="h5"  startDecorator={<ArrowBackIcon />} >
                            Head Back to login
                        </Typography>
                        </Link>
                    </Card>
                </div>
            </div>
        </div>
        <Footer current={'home'}/>
        <Modal
        show={show}
        onHide={()=>{setShow(false)}}
        backdrop="static"
        keyboard={false}
        >
            <Modal.Header>
            <Modal.Title>Kamplasana</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h4>{message}</h4>
            </Modal.Body>
            <Modal.Footer>
            <button onClick={()=>{
                setShow(false);
                
            }} className="btn btn-outline-dark">Okay</button>
            </Modal.Footer>
        </Modal>  
        </>
  )
}
