import React from 'react'
import { Navbar,Footer } from '../../common'
import notFoundImg from './403.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const UnauthPage = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className='bg-main'>
        <Navbar isSearchVisible={false}></Navbar>
        <div className='container p-5'>
            <div className='row g-5 p-4'>
              <img alt='Page Not Found' src={notFoundImg} className='col-6 h-75 img-fluid'/>
              <div style={{display:'flex',   flexDirection: 'column',alignItems:'center',justifyContent:'center'}} className='col-6'>
                <h3 style={{textAlign:'center',fontWeight:700}}> <u>{t('403Page1')}</u></h3>
                <button onClick={()=>navigate('../home')} className='w-100 p-3 mt-3 btn btn-outline-dark'> {t('returnHome')}</button>                
              </div>
            </div>
            
        </div>
        <Footer current={'home'}/>
        
    </div>
  )
}
