import React, { useEffect, useState } from 'react';
import { Domain } from '../../../Domain';
import { useNavigate,useLocation } from 'react-router-dom';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import {AdminDashboard,LoadingModal} from '../../../common';
import Box from '@mui/material/Box';
import { Button, Toolbar } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

export const EditTickets = () => {

    let navigate = useNavigate();
    const location = useLocation();

    const data = location.state;
    
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [error,seterror] = useState(false);
    const [success,setSuccess] = useState(false);
    const [edit,setEdit] = useState(false);
    const [id,setId] = useState('');

    const [title,setTitle] = useState("");
    const [TotalPrice,setTotalPrice] = useState("");
    const [NumberPerson,setNumberPerson] = useState("");
    const [NumberChild,setNumberChild] = useState("");
    const [CheckInDate,setCheckInDate] = useState("");
    const [CheckOutDate,setCheckOutDate] = useState("");
    const [status,setStatus] = useState("");

    const [pnr,setPnr] = useState("");

    const getCampAreaDetails = () =>{
      var myHeaders = new Headers();

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      const temp = location.state;

      fetch(`${Domain}/api/tickets/${temp.pnr}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            setNumberPerson(result.numberOfPerson);
            setNumberChild(result.numberOfChild);
            setTitle(result.title);
            setCheckInDate( new Date(result.checkIn));
            setCheckOutDate(new Date(result.checkOut));
            setStatus(result.status);
            setTotalPrice(result.totalPrice);
            setPnr(result.pnr);
        })
        .catch(error => console.log('error', error));
    }

    const UpdateReservations = () =>{
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

      var raw = JSON.stringify({
        "numberOfPerson": NumberPerson,
        "numberOfChild": NumberChild,
        "checkIn": CheckInDate,
        "checkOut": CheckOutDate,
        "totalPrice": TotalPrice,
        "title": title,
        "status": status
      });
      handleShow();

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

        fetch(`${Domain}/api/tickets/${pnr}`, requestOptions)
        .then(response => response.json())
        .then(result =>{
            if( result.pnr === pnr){
              handleClose();
              window.location.reload();
            }
            else{
              handleClose();
              window.location.reload();

            }
        })
        .catch(error => console.log('error', error));
    }

    useEffect(() => {
      getCampAreaDetails();

    }, [])
    console.log(CheckOutDate);
    

    return (
        <>
          { show && <LoadingModal/>}
          <Box sx={{ display: 'flex' }}>
          <AdminDashboard /> 
          <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            padding: 3,
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Grid sx={{backgroundColor:'#fff',paddingBottom:5,paddingRight:5, marginTop:3,width:'auto',borderRadius:2,marginLeft:2}} container spacing={4}>
            <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h4" >
                    Edit Tickets
                </Typography>
            </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e)=>{setTitle(e.target.value);}}
            value={title}
            required
            id="Title"
            name="Title"
            label="Title"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={TotalPrice}
            onChange={(e)=>{setTotalPrice(e.target.value);}}
            required
            id="TotalPrice"
            name="TotalPrice"
            label="Total Price"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            value={NumberPerson}
            onChange={(e)=>{setNumberPerson(e.target.value);}}
            required
            label="Number of Persons"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
          value={NumberChild}
            onChange={(e)=>{setNumberChild(e.target.value);}}
            required
            label="Number of Children"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root css-wb57ya-MuiFormControl-root-MuiTextField-root">
            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-standard MuiFormLabel-colorPrimary MuiFormLabel-filled Mui-required MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-standard css-1c2i806-MuiFormLabel-root-MuiInputLabel-root" data-shrink="true" htmlFor=":r4:" id=":r4:-label">Check Out Date
            <span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk css-wgai2y-MuiFormLabel-asterisk"> *</span></label><div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl css-l4u8b9-MuiInputBase-root-MuiInput-root">
            <input value={CheckOutDate} onChange={(e)=>{setCheckOutDate(e.target.value)}} className='MuiInputBase-input MuiInput-input css-1x51dt5-MuiInputBase-input-MuiInput-input' type='date' />
          </div>
          </div>


        </Grid>

        <Grid item xs={12} sm={6}>
        <div className="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root css-wb57ya-MuiFormControl-root-MuiTextField-root">
            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-standard MuiFormLabel-colorPrimary MuiFormLabel-filled Mui-required MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-standard css-1c2i806-MuiFormLabel-root-MuiInputLabel-root" data-shrink="true" htmlFor=":r4:" id=":r4:-label">Check In Date
            <span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk css-wgai2y-MuiFormLabel-asterisk"> *</span></label><div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl css-l4u8b9-MuiInputBase-root-MuiInput-root">
            <input value={CheckInDate} onChange={(e)=>{setCheckInDate(e.target.value)}} className='MuiInputBase-input MuiInput-input css-1x51dt5-MuiInputBase-input-MuiInput-input' type='date' />
          </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
          value={status}
          onChange={(e)=>{setStatus(e.target.value)}}
            required
            label="Status"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  onClick={()=>{UpdateReservations();}}
                  sx={{ mt: 3, ml: 1 , width:'100%'}}
                >
                    Edit
                </Button>
              </Grid>
        </Grid>
        </Box>
        </Box>
        </>
    )
}
