import React, { useEffect, useState } from 'react';
import { Domain } from '../../../Domain';
import { useNavigate,useLocation } from 'react-router-dom';
import {TourDashboard} from '../../../common';
import { Box,Toolbar,Grid,Typography} from '@mui/material';
import { Calendar } from 'react-date-range';
import TicketCard from './Ticket';


export const TicketsTour = () => {

  function getDayOfMonth(date) {
    return date.getDate();
  }  

  function customDayContent(day) {
    let extraDot = null;
    if (isDateBetween(day)) {
      extraDot = (
        <div
          style={{
            height: "5px",
            width: "5px",
            borderRadius: "100%",
            background: "orange",
            position: "absolute",
            top: 2,
            right: 2,
          }}
        />
      )
    }
  
    return (
      <div>
        {extraDot}
        <span>{getDayOfMonth(day)}</span>
      </div>
    )
  }

  function isDateBetween(temp) {
    let flag = false;
    Tickets.forEach(element => {
      const startDate = new Date(element.checkOut);
      const endDate = new Date(element.checkIn);
      const dateToCheck = new Date(temp);
      if(dateToCheck >= endDate  && dateToCheck <=startDate){
        flag=true;
      }
      
    });
    return flag;
  }


    let navigate = useNavigate();
    const location = useLocation();
    const [date, setDate] = useState(null);
    const [Tickets,setTickets] = useState([]);
    const [SelectedTickets,setSelectedTickets] = useState([]);

    useEffect(() => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      const temp = JSON.parse(localStorage.getItem('user'));


      fetch(`${Domain}/api/tickets/host/${temp._id}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          if(result==="Forbidden") { 
            localStorage.clear();
            navigate('/login');
          }           
          else{
            const res = JSON.parse(result);
            setTickets(res);
          }
      })
      .catch(error => console.log('error', error));
    }, [])
    

    return (
        <>
          <Box sx={{ display: 'flex' }}>
          <TourDashboard /> 
          <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            padding: 3,
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Grid sx={{backgroundColor:'#fff',paddingBottom:5,paddingRight:5, marginTop:3,width:'auto',borderRadius:2,marginLeft:2}} container spacing={4}>
            <Grid xs={12}>  
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h4" >
                    Current Tickets for your tours
                </Typography>
            </Grid>
          </Grid>
          <Grid sx={{paddingBottom:5,paddingRight:5, marginTop:3,width:'auto',borderRadius:2,marginLeft:2}} container spacing={4}>
            <Grid sx={{backgroundColor:'#fff',paddingBottom:5,paddingRight:5,paddingLeft:2, marginTop:3,width:'auto',height:'350px',borderRadius:2}}  xs={12} md={4}>
            <Calendar
              
              dayContentRenderer={customDayContent}
              onChange={(item)=>{
                const temp = [];
                for (let i = 0; i < Tickets.length; i++) {
                  const element = Tickets[i];
                  const startDate = new Date(element.checkOut);
                  const endDate = new Date(element.checkIn);
                  const dateToCheck = new Date(item);
                  if(dateToCheck >= endDate  && dateToCheck <=startDate){
                    temp.push(element);
                  }
                }
                setSelectedTickets(temp);
              }}
                />
            </Grid>
            <Grid xs={12} md={8} container>
              {
                SelectedTickets.map((ticket)=>(
                  <Grid sx={{alignContent:'flex-end',paddingBottom:5,paddingRight:5,paddingLeft:2, marginTop:3,width:'auto',borderRadius:2}} xs={12} md ={6}>
                  <TicketCard goToTour={()=>{navigate(`/tour/${ticket.campingTourId}`)}} ticket={ticket}/>
                </Grid>

                ))
              }
            </Grid>
        </Grid>
        </Box>
        
        </Box>
        </>
    )
}