import React, { useEffect, useState } from 'react';
import { Domain } from '../../../Domain';
import { useNavigate,useLocation } from 'react-router-dom';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import {AdminDashboard} from '../../../common';
import Box from '@mui/material/Box';
import { Button, Toolbar } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';


export const NewReservation = () => {

    let navigate = useNavigate();
    const location = useLocation();

    const data = location.state;
    
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [error,seterror] = useState(false);
    const [success,setSuccess] = useState(false);
    const [edit,setEdit] = useState(false);
    const [id,setId] = useState('');

    const [title,setTitle] = useState("");
    const [TotalPrice,setTotalPrice] = useState("");
    const [NumberPerson,setNumberPerson] = useState("");
    const [NumberChild,setNumberChild] = useState("");
    const [CheckInDate,setCheckInDate] = useState("");
    const [CheckOutDate,setCheckOutDate] = useState("");


    return (
        <>
          <Box sx={{ display: 'flex' }}>
          <AdminDashboard /> 
          <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            padding: 3,
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Grid sx={{backgroundColor:'#fff',paddingBottom:5,paddingRight:5, marginTop:3,width:'auto',borderRadius:2,marginLeft:2}} container spacing={4}>
            <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h4" >
                    Create Reservation
                </Typography>
            </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e)=>{setTitle(e.target.value);}}
            value={title}
            required
            id="Title"
            name="Title"
            label="Title"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={TotalPrice}
            onChange={(e)=>{setTotalPrice(e.target.value);}}
            required
            id="TotalPrice"
            name="TotalPrice"
            label="Total Price"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            value={NumberPerson}
            onChange={(e)=>{setNumberPerson(e.target.value);}}
            required
            label="Number of Persons"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
          value={NumberChild}
            onChange={(e)=>{setNumberChild(e.target.value);}}
            required
            label="Number of Children"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={CheckInDate}
            onChange={(e)=>{setCheckInDate(e.target.value);}}
            required
            label="Check In Date"
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
          value={CheckOutDate}
            onChange={(e)=>{setCheckOutDate(e.target.value);}}
            required
            label="Check Out Date"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
          value={CheckOutDate}
            onChange={(e)=>{setCheckOutDate(e.target.value);}}
            required
            label="Camping Area Id"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
          value={CheckOutDate}
            onChange={(e)=>{setCheckOutDate(e.target.value);}}
            required
            label="Owner Id"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  sx={{ mt: 3, ml: 1 }}
                >
                    Create
                </Button>
              </Grid>
        </Grid>
        </Box>
        </Box>
        </>
    )
}
