// Reducers
import {GET_ALL_CAMPS,GET_ALL_MAP_CAMPS_SUCCESS,GET_ALL_CAMPS_SUCCESS,GET_ALL_CAMPS_FAILURE} from '../actions/camps'

const CampsReducer = (state = { camps: [], loaded: false, error: null,mapCamps:[] }, action) => {
    switch (action.type) {
      case GET_ALL_CAMPS:
        return { ...state, loaded: false };
      case GET_ALL_CAMPS_SUCCESS:
        return { ...state, camps: action.payload, loaded: true };
      case GET_ALL_MAP_CAMPS_SUCCESS:
        return { ...state, mapCamps: action.payload, loaded: true };
      case GET_ALL_CAMPS_FAILURE:
        return { ...state, error: action.payload, loaded: false };
      default:
        return state;
    }
  };
export default CampsReducer;