import React from 'react';
import Rating from '@mui/material/Rating';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Domain } from '../../../Domain';
import { Avatar } from '@mui/material';
import { stringAvatar } from '../../../utils';



export const CommentSection = ({campingAreaId,campingTourId,comment,role}) => {

    const deleteComment = () =>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

        var raw = JSON.stringify({
        "id": comment.id
        });

        var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${Domain}/api/campingareas/delete/comment/${campingAreaId}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            if(result==="Forbidden"){
                localStorage.clear();
            }
            window.location.reload();
        })
        .catch(error => console.log('error', error));
    }

    const deleteCommentTour = () =>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

        var raw = JSON.stringify({
        "id": comment.id
        });

        var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${Domain}/api/campingtours/delete/comment/${campingTourId}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            if(result==="Forbidden"){
                localStorage.clear();
            }
            window.location.reload();
        })
        .catch(error => console.log('error', error));
    }
    
    return (
        <li className="row">
            <div className="comment-main-level ">
 
                <div className="comment-box">
                    <div className="comment-head">
                        <div className="comment-avatar">
                            { comment.author &&

                            
                            <Avatar style={{cursor:'pointer'}} {...stringAvatar(comment.author?.firstName ,comment.author?.lastName)}  />
                            }

                        </div>
                        <h6 className="comment-name by-author" style={{textTransform:'capitalize',marginRight:8}}>{comment.author?.firstName +" "+ comment.author?.lastName}</h6>
                        <Rating name="simple-controlled" disabled value={comment.rate}/>
                        {role==="admin" && 
                        <IconButton onClick={()=>{
                            if(campingAreaId==="empty"){
                                deleteCommentTour();
                            }
                            else{
                                deleteComment();
                            }
                            }} style={{float:'right'}} className='align' color="error" aria-label="add to shopping cart">
                            <DeleteIcon />
                        </IconButton>
                        }
                        
                    
                    </div>
                    <div className="comment-content">
                    {comment.text}
                    </div>
                </div>
            </div>
        </li>
    )
}
