import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import TourIcon from '@mui/icons-material/Tour';
import HomeIcon from '@mui/icons-material/Home';
import { t } from 'i18next';
export const MainListItems = () => {
  

  return(
  <React.Fragment>
    <ListItemButton href='/tour/dashboard'>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary={t('Dashboard')} />
    </ListItemButton>
    <ListItemButton href='/home'>
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText primary={t("Home")} />
    </ListItemButton>
    <ListItemButton href='/tour/profile'>
      <ListItemIcon>
        <PersonIcon/>
      </ListItemIcon>
      <ListItemText primary={t("Profile")} />
    </ListItemButton>
    <ListItemButton href='/tour/tours'>
      <ListItemIcon>
        <TourIcon/>
      </ListItemIcon>
      <ListItemText primary={t("Tours")} />
    </ListItemButton>
    {/* <ListItemButton href='/tour/tickets'>
      <ListItemIcon>
        <ConfirmationNumberIcon/>
      </ListItemIcon>
      <ListItemText primary="Tickets" />
    </ListItemButton> */}
    <ListItemButton href='/home' onClick={()=>{
      localStorage.clear();
    }}>
      <ListItemIcon>
        <ExitToAppIcon/>
      </ListItemIcon>
      <ListItemText primary={t('logout')} />
    </ListItemButton>
  </React.Fragment>
);
  }