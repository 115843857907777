import React, { useEffect, useState } from 'react';
import { Domain } from '../../../Domain';
import { useNavigate } from 'react-router-dom';
import {AdminDashboard, CloudinaryWidget} from '../../../common';
import Modal from 'react-bootstrap/Modal';
import { GetMe } from '../../../services/GetMe';
import { Button, Toolbar,InputLabel,MenuItem,FormControl,Select,Box,
          Grid,TextField,Autocomplete,Typography} from '@mui/material';
import { t } from 'i18next';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import { GetCities } from '../../../services/GetCities';
import { GetIlce } from '../../../services/GetIlce';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const customIcon = new L.Icon({
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});


export const NewTourAdmin = () => {

    let navigate = useNavigate();
    
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [error,seterror] = useState(false);
    const [success,setSuccess] = useState(false);
    const [districts,setDistricts] = useState([]);

    const [title,setTitle] = useState("");
    const [description,setDescription] = useState("");
    const [price,setPrice] = useState("");
    const [capacity,setCapacity] = useState("");
    const [checkinRule,setCheckInRule] = useState("");
    const [checkOutRule,setCheckOutRule] = useState("");
    const [minimumDays,setMinimumDays] = useState("");
    const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
    const [errorMesage,setErrorMsg] = useState(t('ErrorMessage'));

    const [additionalSupport,setAdditionalSupport] = useState([]);
    const [selectedAdditionalSupport,setSelectedAdditionalSupport] = useState([]);
    const [selectedAdditionalSupportImages,setSelectedAdditionalSupportImages] = useState([]);

    const [standardAmenities,setStandardAmenities]= useState([]);
    const [standardAmenitiesSelected,setStandardAmenitiesSelected]= useState([]);
    const [standardAmenitiesImages,setStandardAmenitiesImages]= useState([]);


    const [publicArea,setPublicArea]= useState([]);
    const [publicAreaSelected,setPublicAreaSelected]= useState([]);
    const [publicAreaImages,setPublicAreaImages]= useState([]);

    const [activities,setActivities]= useState([]);
    const [activitiesSelected,setActivitiesSelected]= useState([]);
    const [activitiesImages,setActivitiesImages]= useState([]);

    const [terrain,setTerrain]= useState([]);
    const [terrainSelected,setTerrainSelected]= useState([]);
    const [terrainImages,setTerrainImages]= useState([]);

    const [province,setProvince] = useState("");
    const [district,setDistrict] = useState("");
    const [userInfo,setUserInfo] = useState("");
    const [cities,setCities] = useState("");


    async function handleChange (event){
      setProvince(event.target.value);
      const Districts = await GetIlce(event.target.value);
      setDistricts(Districts);
    };

    useEffect(() => {
      if(!localStorage.getItem('accessToken')){
        navigate('/login');
      }
      else{
        GetMe(setUserInfo);
      }
    }, [])

    const handleUploadSuccess = (url) => {
      setUploadedImageUrl(url);
    };

    useEffect(() => {
      if(localStorage.getItem('accessToken')){
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
    

      fetch(`${Domain}/api/category`, requestOptions)
        .then(response => response.text())
        .then(result => {
          if(result==='Forbidden'){
              navigate('/home');
          }
          else{
              const resp = JSON.parse(result);
              const temp2 = resp.filter(obj => obj.feature !== "88");
              const publicArea = temp2.filter(obj => obj.feature === "33");
              const aminities = temp2.filter(obj => obj.feature === "44");
              const additionalSupport = temp2.filter(obj => obj.feature === "55");
              const activites = temp2.filter(obj => obj.feature === "66");
              const terrain = temp2.filter(obj => obj.feature === "77");
              setPublicArea(publicArea);
              setStandardAmenities(aminities);
              setAdditionalSupport(additionalSupport);
              setActivities(activites);
              setTerrain(terrain);
          }
        }
        )
        .catch(error =>{
          console.log('error', error)
        } );
      }
    }, [])

    useEffect(() => {
      async function setAsyncValue() {
        let response = await GetCities();
        setCities(response);
      }
      setAsyncValue()
    }, []);



    const createTour =() =>{
      if(markerPosition?.lat === "undefined" || markerPosition?.lng === "undefined" || 
        markerPosition?.lat === undefined || markerPosition?.lng === undefined){
          setShow(true)
          seterror(true);
          setErrorMsg("Kampınızın veya turunuzun konumunu belirlemek için lütfen haritadan seçin !");
          return
      }

      var myHeaders = new Headers();
      myHeaders.append("x-refresh", `Bearer ${localStorage.getItem('refreshToken')}`);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

      const categoriesCodes = [];
      let combinedImages = [];

      for (const obj of standardAmenities) {
        if (obj.hasOwnProperty('code')) {
          categoriesCodes.push(String(obj.code));
        }
      }
      for (const obj of terrainSelected) {
        if (obj.hasOwnProperty('code')) {
          categoriesCodes.push(String(obj.code));
        }
      }
      for (const obj of activitiesSelected) {
        if (obj.hasOwnProperty('code')) {
          categoriesCodes.push(String(obj.code));
        }
      }
      for (const obj of publicAreaSelected) {
        if (obj.hasOwnProperty('code')) {
          categoriesCodes.push(String(obj.code));
        }
      }
      for (const obj of selectedAdditionalSupport) {
        if (obj.hasOwnProperty('code')) {
          categoriesCodes.push(String(obj.code));
        }
      }
      for (let i = 0; i < publicAreaImages.length; i++) {
        const element = publicAreaImages[i];
        combinedImages.push({
          "featureCategory": "33",
          "path":element
        })
      }
      for (let i = 0; i < standardAmenitiesImages.length; i++) {
        const element = standardAmenitiesImages[i];
        combinedImages.push({
          "featureCategory": "44",
          "path":element
        })
      }
      for (let i = 0; i < selectedAdditionalSupportImages.length; i++) {
        const element = selectedAdditionalSupportImages[i];
        combinedImages.push({
          "featureCategory": "55",
          "path":element
        })
      }
      for (let i = 0; i < activitiesImages.length; i++) {
        const element = activitiesImages[i];
        combinedImages.push({
          "featureCategory": "66",
          "path":element
        })
      }
      for (let i = 0; i < terrainImages.length; i++) {
        const element = terrainImages[i];
        combinedImages.push({
          "featureCategory": "77",
          "path":element
        })
      }
      const body_province = cities.find(element => element.value === province);
      const body_disctrict = districts.find(element => element.value === district);
      const standardAmenitiesSelected1 = standardAmenitiesSelected.map(({ icon, name }) => ({
        iconUrl: icon,
        name
      }));
      const selectedAdditionalSupport1 = selectedAdditionalSupport.map(element => (element.name)) ?? [];
      const activitiesSelected1 = activitiesSelected.map(({ icon,name }) => ({
        iconUrl: icon,
        name
      }));
      const terrainSelected1 = terrainSelected.map(({ icon, name }) => ({
        iconUrl: icon,
        name
      }));
      const publicAreaSelected1 = publicAreaSelected.map(({ icon, name}) => ({
        iconUrl: icon,
        name
      }));


      var raw = JSON.stringify({
        "role": "campingTour",
        "title": title,
        "description": description,
        "price": parseInt(price),
        "amount": parseInt(price),
        "rate": 0,
        "cancelationPolicy": "30 gün",
        "type": "paid",
        "image": combinedImages,
        "capacity": parseInt(capacity),
        "standardAmenities":standardAmenitiesSelected1,
        "additionalSupport": selectedAdditionalSupport1,
        "publicArea": publicAreaSelected1,
        "checkInRule": checkinRule,
        "checkOutRule": checkOutRule,
        "minimumDays": minimumDays,
        "activities": activitiesSelected1,
        "terrain": terrainSelected1,
        "coordinate": {
          "latitude": String(markerPosition.lat),
          "longitude": String(markerPosition.lng)
        },
        "category":  categoriesCodes,
        "location": {
          "province": body_province.text,
          "district": body_disctrict.text,
        }
      });
      seterror(false);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${Domain}/api/campingtours`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if(result.hasOwnProperty("owner")){
            setSuccess(true)
          }
          else{
            seterror(true)
          }
          handleShow();
        })
        .catch(error => console.log('error', error));

    }
    const [markerPosition, setMarkerPosition] = useState(null);

    const MapClickHandler = () => {
      useMapEvents({
        click(e) {
          setMarkerPosition(e.latlng);
        },
      });
      return null;
    };

    return (
        <>
          <Box sx={{ display: 'flex' }}>
          <AdminDashboard /> 
          <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            padding: 3,
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Grid sx={{backgroundColor:'#fff',paddingBottom:5,paddingRight:5, marginTop:3,width:'auto',borderRadius:2,marginLeft:2}} container spacing={4}>
            <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h4" >
                  {t('CreationTour')}
                </Typography>
            </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e)=>{setTitle(e.target.value);}}
            value={title}
            required
            label={t("Title")}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e)=>{setPrice(e.target.value);}}
            value={price}
            type='number'
            required
            label={t("Price1")}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
        <TextField
            onChange={(e)=>{setCapacity(e.target.value);}}
            value={capacity}
            required
            label={t("Capacity")}
            type='number'
            fullWidth
            variant="standard"
          />

        </Grid>
        <Grid item sm={6} xs={12}>
        <TextField
            onChange={(e)=>{setMinimumDays(e.target.value);}}
            value={minimumDays}
            required
            label={t("Minimum Days1")}
            type='number'
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
        <TextField
          id="standard-textarea"
          label={t("Description")}
          placeholder={t("writeADesc")}
          multiline
          fullWidth
          rows={4}
          value={description}
          onChange={(e)=>{setDescription(e.target.value);}}
          variant="standard"
        />
        </Grid>
        <Grid item sm={6} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['TimePicker']}>
            <TimePicker ampm={false} sx={{width:'100%'}} onChange={(e)=>{setCheckInRule(e)}} label={t("CheckInRule1")} />
          </DemoContainer>
        </LocalizationProvider>
        </Grid>
        <Grid item sm={6} xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['TimePicker']}>
            <TimePicker ampm={false} sx={{width:'100%'}} onChange={(e)=>{setCheckOutRule(e)}} label={t("CheckOutRule1")} />
          </DemoContainer>
        </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} style={{height:50}}>
        <Box sx={{ minWidth: 120 }}>

        <FormControl variant="standard" fullWidth style={{height:50}}>
            <InputLabel id="demo-simple-select-label">{t("Province")}</InputLabel>
            <Select
            style={{height:50}}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={province}
              label={t("Province")}
              onChange={(handleChange)}
            >
              { cities.length > 0 && cities.map((city) =>(
                  <MenuItem key={city._id} value={city.value} > {city.text}</MenuItem>
              ))

              }
            </Select>
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
        <Box sx={{ minWidth: 120 }}>

        <FormControl variant="standard" fullWidth style={{height:50}}>

        <InputLabel id="demo-simple-select-label">{t("District Name")}</InputLabel>

        <Select
            style={{height:50}}
            disabled={districts.length === 0}
              labelId="demo-simple-select-label-2"
              id="demo-simple-select-2"
              value={district}
              label={t("District Name")}
              onChange={(e)=>{
                setDistrict(e.target.value)}}
            >
              { districts.length > 0 && districts.map((dist) =>(
                  <MenuItem key={dist._id} value={dist.value} > {dist.text}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          </Box>

        </Grid>

        <Grid xs={12}>
          <Typography paddingLeft={4} paddingTop={3} fontWeight={500} style={{marginBottom:-25}} >
              {t('googlemapss1')}
          </Typography>
        </Grid>
        <Grid item xs={12 } style={{zIndex:2}}>
        <MapContainer center={[38.9637,35.2433]} zoom={5} style={{width:'auto',height:'100%',minHeight:'400px',}}>
          <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {markerPosition && <Marker position={markerPosition} icon={customIcon} />}
        <MapClickHandler />
      </MapContainer>
        </Grid>

        <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h6" >
                    {t("Camps Public Area")}
                </Typography>
        </Grid>      
        <Grid item xs={12}>
        <Autocomplete
              multiple
              id="tags-standard"
              options={publicArea}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) =>{
                  setPublicAreaSelected(newValue);
              }}
              
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={t("Tours Public Area")}
                />
              )}
            />
        </Grid>
        <Grid xs={12} padding={4}>
          <div>
                  <CloudinaryWidget
                    apiKey="359728572815919"
                    cloudName="dyku0cdi3"
                    folder={"host/tour/PublicAreas"}
                    maxWidth={800}
                    maxHeight={600}
                    setImages={setPublicAreaImages}
                    images={publicAreaImages}
                    onUploadSuccess={handleUploadSuccess}
                  />
          </div>
        </Grid>
        <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h6" >
                {t("Additional support")}
                </Typography>
        </Grid>
        <Grid item xs={12}>
        <Autocomplete
              multiple
              id="tags-standard"
              options={additionalSupport}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) =>{
                  setSelectedAdditionalSupport(newValue);
              }}
              
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={t("Additional support")}
                />
              )}
            />
        </Grid>
        <Grid xs={12} padding={4}>
          <div>
                  <CloudinaryWidget
                    apiKey="359728572815919"
                    cloudName="dyku0cdi3"
                    folder={"host/tour/AdditionalSupport"}
                    maxWidth={800}
                    maxHeight={600}
                    setImages={setSelectedAdditionalSupportImages}
                    images={selectedAdditionalSupportImages}
                    onUploadSuccess={handleUploadSuccess}
                  />
          </div>
        </Grid>


        <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h6" >
                    {t("Tours Aminities")}
                </Typography>
        </Grid>
        <Grid item xs={12}>
        <Autocomplete
              multiple
              id="tags-standard"
              options={standardAmenities}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) =>{
                  setStandardAmenitiesSelected(newValue);
              }}
              
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={t("Tours Aminities")}
                />
              )}
            />
        </Grid>
        <Grid xs={12} padding={4}>
          <div>
                  <CloudinaryWidget
                    apiKey="359728572815919"
                    cloudName="dyku0cdi3"
                    folder={"host/tours/ToursAminities"}
                    maxWidth={800}
                    maxHeight={600}
                    setImages={setStandardAmenitiesImages}
                    images={standardAmenitiesImages}
                    onUploadSuccess={handleUploadSuccess}
                  />
          </div>
        </Grid>
        <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h6" >
                    {t("Tours Activities")}
                </Typography>
        </Grid>
        <Grid item xs={12}>
        <Autocomplete
              multiple
              id="tags-standard"
              options={activities}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) =>{
                  setActivitiesSelected(newValue);
              }}
              
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={t("Tours Activities")}
                />
              )}
        />
        </Grid>
        <Grid xs={12} padding={4}>
          <div>
                  <CloudinaryWidget
                    apiKey="359728572815919"
                    cloudName="dyku0cdi3"
                    folder={"host/tours/ToursActivities"}
                    maxWidth={800}
                    maxHeight={600}
                    setImages={setActivitiesImages}
                    images={activitiesImages}
                    onUploadSuccess={handleUploadSuccess}
                  />
          </div>
        </Grid>


        <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h6" >
                    {(t('Tours Terrain'))}
                </Typography>
        </Grid>
        <Grid item xs={12}>
        <Autocomplete
              multiple
              id="tags-standard"
              options={terrain}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) =>{
                  setTerrainSelected(newValue);
              }}
              
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={(t('Tours Terrain'))}
                />
              )}
            />
        </Grid>
        <Grid xs={12} padding={4}>
          <div>
                  <CloudinaryWidget
                    apiKey="359728572815919"
                    cloudName="dyku0cdi3"
                    folder={"host/tour/ToursTerrain"}
                    maxWidth={800}
                    maxHeight={600}
                    setImages={setTerrainImages}
                    images={terrainImages}
                    onUploadSuccess={handleUploadSuccess}
                  />
          </div>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  sx={{ mt: 3, ml: 1 }}
                  onClick={()=>{createTour()}}
                >
                    {t("CreationTour")}
                </Button>
              </Grid>
        </Grid>
        </Box>
          </Box>
          <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                <Modal.Title> {error ?  t("WarningTitle"): t("CreationTour")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {
                  error ? <div>{errorMesage}</div> : success?
                  <div>{t('sucasf1')}</div> : <></>

                }
                </Modal.Body>
                <Modal.Footer>
                  {<button onClick={()=>{
                    if(success||error){
                      setSuccess(false);
                      seterror(false);
                      handleClose();
                      if(success){
                        navigate('/tour/tours');
                      }
                      else{
                        window.location.reload();
                      }
                    }
                    else{
                      handleClose();
                    }
                  }} className="btn btn-outline-dark"> {success||error ? "Okay" : t("CreationTour")}</button>  
                  }
                </Modal.Footer>
                </Modal>   
        </>
    )
}
