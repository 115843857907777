import React from 'react';
import { useTranslation } from 'react-i18next';
import nicePic from './nicePic.png'

export const Safety = () => {
const { t, i18n } = useTranslation();
  return (
    <div className='safety-text container p-2'>
        <div className='row mb-3'>
            <h4 style={{fontWeight:700}}>{t('SafetyHeader')}</h4>
        </div>
        <div className='row m-0'>
            <div className='bg-lighty p-5 col-lg-8 col-xl-8 col-12 '>
                <h4 style={{fontWeight:600}} >{t('SafetySubtitleone')}</h4>
                <p style={{fontWeight:500,fontSize:16}} className='text-justify'>
                {t('SafetyDesceone')}
                </p>
                <button className='btn btn-dark'> {t('Learnmore')} </button>
            </div>
            <div className='bg-lighty p-5 col-4 col-lg-4 col-xl-4 img-bye'>
                <img className='img-fluid ms-auto d-block ' width={200} src={nicePic}/>
            </div>
        </div>
        <div className='row mt-3 g-5'>
            <div className='col-lg-6 col-xl-6 col-12'>
                <div className='bg-lighty p-3 w-100 safety-box '>
                <h4 style={{fontWeight:700}}> {t('Safety4')}</h4>
                <p style={{fontWeight:500,fontSize:16}}> 
                {t('Safety3')}
                </p>
                <button className='btn btn-dark'>{t('Learnmore')}</button>
                </div>
            </div>
            <div className='col-lg-6 col-xl-6 col-12 '>
            <div className='bg-lighty p-3 w-100 safety-box '>
                <h4 style={{fontWeight:700}}> {t('SafetySubtitletwo')}</h4>
                <p style={{fontWeight:500,fontSize:16}}> 
                {t('SafetyDescetwo')}
                </p>
                <button className='btn btn-dark'>{t('Learnmore')}</button>
                </div>
            </div>
        </div>
        
    </div>
  )
}
