import React, { useState } from 'react'
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';
import Rating from '@mui/material/Rating';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import "./style.css"
import { useNavigate } from 'react-router-dom';
import { Domain } from '../../../Domain';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

export const LodgeCard = ({props,handleNavigate}) => {
  const data = props;
  const navigate = useNavigate();
  const [show,setShow] = useState(false);
  const [success,setSuccess] = useState(false);
  const handleClose = () => setShow(false);
  const [message,setMessage] = useState("");
  const { t, i18n } = useTranslation();
  function getRandomImage() {
    const {image} = data;
    const randomIndex = Math.floor(Math.random() * image.length);
    return image[randomIndex]?.path;
  }
  const addToFavorite = () =>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

    var raw = JSON.stringify({
      "favCampingAreaId": data.campingAreaId,
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${Domain}/api/users/favorites`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setShow(true);
        setMessage("Camp Added to Favorites");
        setSuccess(true);
      })
      .catch(error => {
        console.log('error', error);
        setShow(true);
        setMessage("Error While adding to Favorites");
      });
  }
  const RemoveFav = () =>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization",`Bearer ${localStorage.getItem('accessToken')}`);
    var raw = JSON.stringify({
      "favCampingAreaId": data.campingAreaId,
    });

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(`${Domain}/api/users/favorites`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setShow(true);
          setMessage("Camp Removed From Favorites");
          setSuccess(true);
        })
        .catch(error => {
          console.log('error', error);
          setSuccess(false);
          setShow(true);
          setMessage("Error While removing from Favorites");
        });
  }
  return (
    <>
    <Card variant="outlined" sx={{ width: 'auto' }}>
        <div>
          <Typography level="h2" fontSize="md" sx={{ mb: 0.5 }}>
            {data.title}
          </Typography>
          <Typography level="body2">{data.location.province} {", "} {data.location.district}</Typography>
          <IconButton
            aria-label="bookmark Bahamas Islands"
            variant="plain"
            color="neutral"
            size="sm"
            sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
            onClick={() =>{
              if(data.page != 'fav'){
                addToFavorite();
              }
              else{
                RemoveFav();
              }
            }}
          >
            {data.page==='fav' ? <BookmarkRemoveIcon/> : <BookmarkAdd/>}
          </IconButton>
        </div>
        <AspectRatio minHeight="120px" maxHeight="200px">
          <img
            src={ getRandomImage() ? getRandomImage() : "https://images.unsplash.com/photo-1527549993586-dff825b37782?auto=format&fit=crop&w=286"
}            loading="lazy"
            alt=""
          />
        </AspectRatio>
        <CardContent orientation="horizontal">
          <div>
          <Rating name="read-only" value={data.rate} readOnly />
            <Typography fontSize="lg" fontWeight="lg">
                {data.price} {"TL / "} {t('day')}
            </Typography>
          </div>
          <Button 
            variant="solid"
            size="sm"
            aria-label="Explore Area"
            className='butn--fill butn'
            sx={{ ml: 'auto', fontWeight: 600 ,minWidth:70, backgroundColor: "#e67e22"
          }}
            onClick={()=>handleNavigate(data.title)}
          >
            {t('explore')}
          </Button>
        </CardContent>
    </Card>
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title>{success ? "Success" : "Warning"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={()=>{
          handleClose();
          if(success === false){
            navigate("../login")
          }
          if(data.page === 'fav'){
            window.location.reload();
          }
        }}>
            Close
        </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
