export function isAtLeast16YearsOld(dateInput) {
    // Parse the input date
    const inputDate = new Date(dateInput);
    // Get the current date
    const currentDate = new Date();
    // Calculate the date 16 years ago from today
    const sixteenYearsAgo = new Date();
    sixteenYearsAgo.setFullYear(currentDate.getFullYear() - 16);
    
    // Compare the input date with the date 16 years ago
    return inputDate <= sixteenYearsAgo;
}