import React, { useEffect, useState } from 'react';
import { Domain } from '../../../Domain';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {AdminDashboard} from '../../../common';
import Box from '@mui/material/Box';
import { Toolbar } from '@mui/material';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';

export const TransactionError = () => {

    let navigate = useNavigate();
    const [search,setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const { t, i18n } = useTranslation();
    const [show,setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [ticketDetails,setTicketDetails] = useState("");

    console.log(ticketDetails);

    const [users,setUsers] = useState([]);

    
    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);
  
        var raw = JSON.stringify({
          "startDate": "2020-05-01 00:00:00",
          "endDate": "2020-05-01 23:59:59"
        });
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        
        //Fetch Users
        fetch(`${Domain}/api/payments/errors`, requestOptions)
          .then(response => response.text())
          .then(result => {
              if (result === 'Forbidden'){
                navigate('/login');
              }
              else{
                const users = JSON.parse(result);
                console.log(users);
  
                setUsers(users.list);
              }
          })
          .catch(error => console.log('error', error));
        
      }, []);
    const handleSearch = (event) => {
        setSearch(event.target.value);
        const results = users.filter(
          (item) =>
            item.email.toLowerCase().includes(event.target.value.toLowerCase())
        );
        setSearchResults(results);
    };
    
    return (
        <>
          <Box sx={{ display: 'flex' }}>
          <AdminDashboard /> 
          <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            padding: 3,
            overflow: 'auto',
          }}
        >
          <Toolbar />
            <div className='container p-5 rounded '>
                <div className="events-header">
                    <h1 style={{fontWeight:'600'}} className="ehtitle"> Transaction Errors </h1>
                   
                </div>
                <div className=" row no-gutters justify-content-center">
                <div className='col-12 bar'>
                            <div className="input-group mb-3">
                            <input type="text" className="ps-5 form-control search-inpt " placeholder="Search" aria-label="Search" aria-describedby="basic-addon2" onChange={(e)=>handleSearch(e)}/>
                            <div className="input-group-append">
                            <button className="btn btn-outline-secondary my-0 search-btn" type="button"><i className="fas fa-search fa-lg fa-fw"></i></button>
                            </div>
                            </div>
                        </div>
                </div>
                <div className='container rounded bg-dark p-5'>
                    <div style={{fontWeight:'600'}} className='row p-3 text-light border-bottom border-white'>
                            <div className='col-8'> Reference Number</div>
                            <div className='col-2 text-end'> Actions</div>
                    </div>  
                    {
                        search.length>0 ? 
                          users.length> 0 && searchResults.map((camp)=>(
                          <div className='row p-3 text-light border-bottom border-white'>
                          <div className='col-8'> {camp.ref_no} </div>
                          <div className='col-2 text-end'>      
                              <ButtonGroup  size="sm">
                              <Button onClick={()=>{ 
                                setTicketDetails(camp);handleShow();
                                }}  variant="light" ><i className="fa fa-eye" aria-hidden="true"></i></Button>                          
                               </ButtonGroup>
                          </div>
                      </div>               
                      )):
                    users.length> 0 && users.map((camp)=>(
                      <div className='row p-3 text-light border-bottom border-white'>
                          <div className='col-8'> {camp.ref_no} </div>
                          <div className='col-2 text-end'>      
                              <ButtonGroup  size="sm">
                              <Button onClick={()=>{                                 setTicketDetails(camp); handleShow();
                              }}  variant="light" ><i className="fa fa-eye" aria-hidden="true"></i></Button>                          
                               </ButtonGroup>
                          </div>
                      </div>             
                    ))
                    }

                </div>
            </div>
            </Box>
          </Box>
          <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{ticketDetails.ref_no}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Date Detected </Form.Label>
                <Form.Control
                    placeholder={ticketDetails.date_detected}
                    disabled
                />
                </Form.Group>
  
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Date Reimbursed:</Form.Label>
                <Form.Control
                    placeholder={ticketDetails.date_reimbursed}
                    disabled
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label> Reimburse Reason</Form.Label>
                <Form.Control
                    placeholder={ticketDetails.reimburse_reason}
                    disabled
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>transfer Amount</Form.Label>
                <Form.Control
                    placeholder={ticketDetails.transfer_amount + " " + ticketDetails.transfer_currency}
                    disabled
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>transfer Name</Form.Label>
                <Form.Control
                    placeholder={ticketDetails.transfer_name}
                    disabled
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>transfer Date</Form.Label>
                <Form.Control
                    placeholder={ticketDetails.transfer_date}
                    disabled
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>transfer IBAN</Form.Label>
                <Form.Control
                    placeholder={ticketDetails.transfer_iban}
                    disabled
                />
                </Form.Group>

            </Form>    
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
          {t('close')}
          </Button>
          
        </Modal.Footer>
      </Modal>
        </>
    )
}
