import { Domain } from "../../Domain";
export const GET_ALL_TOURS= 'GET_ALL_TOURS';
export const GET_ALL_TOURS_SUCCESS= 'GET_ALL_TOURS_SUCCESS';
export const GET_ALL_TOURS_FAILURE= 'GET_ALL_TOURS_FAILURE';
export const GET_ALL_MAP_TOURS_SUCCESS= 'GET_ALL_TOURS_MAP';

export const getTours = (checkin='',checkout='',province='',) => {
    return async dispatch =>{
        dispatch(
        {
            type : GET_ALL_TOURS,
        });

        let myHeaders = new Headers();
        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };  
        province = province.toLowerCase();
        const limit = 4;
        let skip = localStorage.getItem('skipTour') ? localStorage.getItem('skipTour') - 1 : 0 ;
        let sortby = localStorage.getItem('sortby') ||'' ;
        let sortOrder = localStorage.getItem('sortorder') || '';
        let pricemin = localStorage.getItem('cacheMinPriceTour') || '' ;
        let title = localStorage.getItem('titleTour') || '' ;
        let pricemax = localStorage.getItem('cacheMaxPriceTour') || '' ;
        let AdditionalCamps = localStorage.getItem('AdditionalTours') ? JSON.parse(localStorage.getItem('AdditionalTours')) :[] ;
        let AmenitiesCamps = localStorage.getItem('AmenitiesTours') ? JSON.parse(localStorage.getItem('AmenitiesTours')) :[] ;
        let ActivitiesCamps = localStorage.getItem('ActivitiesTours') ? JSON.parse(localStorage.getItem('ActivitiesTours')) :[] ;
        let TerrainCamps = localStorage.getItem('TerrainTours') ? JSON.parse(localStorage.getItem('TerrainTours')) :[] ;
        let publicAreaCamps = localStorage.getItem('publicAreaTours') ? JSON.parse(localStorage.getItem('publicAreaTours')) :[] ;
        let categories = [...AdditionalCamps,...publicAreaCamps, ...TerrainCamps, ...AmenitiesCamps,...ActivitiesCamps];

        await fetch(`${Domain}/api/campingtours?checkin=${checkin}&checkout=${checkout}&province=${province}&sortby=${sortby}&search=${title}&sortorder=${sortOrder}&pricemin=${pricemin}&pricemax=${pricemax}&limit=${limit}&skip=${limit*skip}&categories=${categories}`,
         requestOptions)
        .then(response => response.json())
        .then(result => {
            dispatch(
                {
                  type : GET_ALL_TOURS_SUCCESS,
                  payload:result,
                }
            );
        })
        .catch(error =>{ 
            console.log("err",error);
            dispatch(
                {
                  type : GET_ALL_TOURS_FAILURE,
                  payload:false,
                }
            );        
        });
    }
}
export const getMapTours = () =>{
    return async dispatch =>{
        dispatch(
        {
            type : GET_ALL_TOURS,
        });

        let myHeaders = new Headers();
        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };  
        await fetch(`${Domain}/api/campingtours`, requestOptions)
        .then(response => response.json())
        .then(result => {
            dispatch(
                {
                  type : GET_ALL_MAP_TOURS_SUCCESS,
                  payload:result,
                }
            );
        })
        .catch(error =>{ 
            console.log("err",error);
            dispatch(
                {
                  type : GET_ALL_TOURS_FAILURE,
                  payload:false,
                }
            );        
        });
    }

}