import React,{useState,useEffect} from 'react';
import PersonalHeader from '../common/PersonalHeader';
import "./styles.css";
import { Domain } from '../../../Domain';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {Form,Modal,Button} from 'react-bootstrap';
import { Footer,Navbar} from '../../../common';
import { formatDate } from '../../../utils';

export default function MyTickets() {
    const navigate = useNavigate();
    const [MyTickets,setMyTickets] = useState([]);
    const [show,setShow] = useState(false);
    const [ticketsCounter, setTicketsCounter] = useState(0);
    const [ticketDetails,setTicketDetails] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { t } = useTranslation();

    useEffect(() => {
        if(localStorage.getItem('accessToken')){
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("accessToken")}`);
            var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
            };
            const user = JSON.parse(localStorage.getItem('user'));
            fetch(`${Domain}/api/tickets/user/${user._id}`,requestOptions)
            .then(response => response.text())
            .then(result =>{
                const temp = JSON.parse(result);
                setMyTickets(temp);
                setTicketsCounter(temp.length)
            })
            .catch(error => console.log('error', error));
        }
        else{
            navigate('/login');
        }
    }, [])
    
    const getTicketDetails = (ticket) =>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
    
        fetch(`${Domain}/api/tickets/${ticket.pnr}`, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setTicketDetails(result)
            // setCounter(counter+1);
          })
          .catch(error => console.log('error', error));
    }

    

    const RefundReservation = () =>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);
    
        var raw = JSON.stringify({
            "merchantOid": ticketDetails.pnr,
            "returnAmount": ticketDetails.totalPrice,
            "referenceNo": "9119898"
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
          
    
        fetch(`${Domain}/api/payments/return`, requestOptions)
          .then(response => response.json())
          .then(result =>{
          })
          .catch(error => console.log('error', error));
    }
    


    const tabName = "MyTickets";  
    return (
        <>
        <Navbar isSearchVisible={false} currentpage={'MyTickets'}/>
        <PersonalHeader tab={tabName}/>
        <div style={{ minheight:"100vh",backgroundColor:"#f8f9fa"}}>
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <div className="global-header">
                        <div className="global-header-number">{ticketsCounter}</div>
                        <div className="global-header-body">
                            <div className="global-header-title">{t('Tickets')}</div>
                            <p>{t('TicketsHeader')}</p>
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <div className="profile-favorites">
                    { MyTickets.length > 0 ? 
                        MyTickets.map((item,index) => (
                            <div key={item._id} className="event-sm m-3">
                                <div className="event-sm-image">
                                    <img onClick={()=>{ }} src='https://cdn.prod.v2.camping.info/media/campsites/scout-camp-poznan/cMurLXB38Y2i.jpg' alt="Yüzyüzeyken Konuşuruz Konseri" />
                                </div>
                                <div className="event-sm-title">
                                    <a style={{cursor:'pointer'}}>{item.title}  {formatDate(item.checkIn)}</a>
                                </div>
                                <div className="event-sm-title">
                                    <Button variant='danger' onClick={()=>{handleShow(); getTicketDetails(item);}}>{t('details')}</Button>
                                </div>
                            </div>
                        ))
                        :
                        <h4>{t('NOTickets')}</h4>
                        
                    }
                    </div>
                </div>
                </div>
            </div>
        </div>
        <Footer/>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{ticketDetails.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Check In:</Form.Label>
                <Form.Control
                    placeholder={formatDate(ticketDetails.checkIn)}
                    disabled
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Check Out:</Form.Label>
                <Form.Control
                    placeholder={formatDate(ticketDetails.checkOut)}
                    disabled
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>{t('ticketCount')}</Form.Label>
                <Form.Control
                    placeholder={ticketDetails.amount}
                    disabled
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>{t('paidAmount')}</Form.Label>
                <Form.Control
                    placeholder={ticketDetails.price}
                    disabled
                />
                </Form.Group>

            </Form>    
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
          {t('close')}
          </Button>
          <Button variant="primary" onClick={()=> {handleClose(); navigate(`/tour/${ticketDetails.campingTourId}`)}}>
                {t('goToTour')}
          </Button>
          <Button onClick={()=>{RefundReservation()}}>
            Cancel Booking
          </Button>
        </Modal.Footer>
      </Modal>
    </>
    )
}