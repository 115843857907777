import React, { useEffect, useState } from 'react';
import { Domain } from '../../../Domain';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {AdminDashboard} from '../../../common';
import Box from '@mui/material/Box';
import { Toolbar } from '@mui/material';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';

export const TransactionDump = () => {

    let navigate = useNavigate();
    const [search,setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const { t, i18n } = useTranslation();
    const [show,setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [ticketDetails,setTicketDetails] = useState("");

    console.log(ticketDetails);

    const [users,setUsers] = useState([]);

    useEffect(() => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

      var raw = JSON.stringify({
        "startDate": "2020-05-01 00:00:00",
        "endDate": "2020-05-01 23:59:59"
      });
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      
      //Fetch Users
      fetch(`${Domain}/api/payments/dump`, requestOptions)
        .then(response => response.text())
        .then(result => {
            if (result === 'Forbidden'){
              navigate('/login');
            }
            else{
              const users = JSON.parse(result);
              console.log(users);

              setUsers(users.list);
            }
        })
        .catch(error => console.log('error', error));
      
    }, []);

    const handleSearch = (event) => {
        setSearch(event.target.value);
        const results = users.filter(
          (item) =>
            item.email.toLowerCase().includes(event.target.value.toLowerCase())
        );
        setSearchResults(results);
    };
    
    return (
        <>
          <Box sx={{ display: 'flex' }}>
          <AdminDashboard /> 
          <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            padding: 3,
            overflow: 'auto',
          }}
        >
          <Toolbar />
            <div className='container p-5 rounded '>
                <div className="events-header">
                    <h1 style={{fontWeight:'600'}} className="ehtitle"> Transaction Dump </h1>
                 
                </div>
                <div className=" row no-gutters justify-content-center">
                <div className='col-12 bar'>
                            <div className="input-group mb-3">
                            <input type="text" className="ps-5 form-control search-inpt " placeholder="Search" aria-label="Search" aria-describedby="basic-addon2" onChange={(e)=>handleSearch(e)}/>
                            <div className="input-group-append">
                            <button className="btn btn-outline-secondary my-0 search-btn" type="button"><i className="fas fa-search fa-lg fa-fw"></i></button>
                            </div>
                            </div>
                        </div>
                </div>
                <div className='container rounded bg-dark p-5'>
                    <div style={{fontWeight:'600'}} className='row p-3 text-light border-bottom border-white'>
                            <div className='col-8'> Transaction Number</div>
                            <div className='col-2 text-end'> Actions</div>
                    </div>  
                    {
                        search.length>0 ? 
                          users.length> 0 && searchResults.map((camp)=>(
                          <div className='row p-3 text-light border-bottom border-white'>
                          <div className='col-8'> {camp.siparis_no} </div>
                          <div className='col-2 text-end'>      
                              <ButtonGroup  size="sm">
                              <Button onClick={()=>{ setTicketDetails(camp); handleShow();
                                }}  variant="light" ><i className="fa fa-eye" aria-hidden="true"></i></Button>                          
                              
                              </ButtonGroup>
                          </div>
                      </div>               
                      )):
                    users.length> 0 && users.map((camp)=>(
                      <div className='row p-3 text-light border-bottom border-white'>
                           <div className='col-8'> {camp.siparis_no} </div>
                          <div className='col-2 text-end'>      
                              <ButtonGroup  size="sm">
                              <Button onClick={()=>{  setTicketDetails(camp); handleShow();
                              }}  variant="light" ><i className="fa fa-eye" aria-hidden="true"></i></Button>                          
                             
                              </ButtonGroup>
                          </div>
                      </div>             
                    ))
                    }

                </div>
            </div>
            </Box>
          </Box>
          <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{ticketDetails.siparis_no}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Islem Tarihi </Form.Label>
                <Form.Control
                    placeholder={ticketDetails.islem_tarihi}
                    disabled
                />
                </Form.Group>
  
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Islem Tipi </Form.Label>
                <Form.Control
                    placeholder={ticketDetails.islem_tipi}
                    disabled
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Islem Tutari </Form.Label>
                <Form.Control
                    placeholder={ticketDetails.islem_tutari}
                    disabled
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Odeme Tutari</Form.Label>
                <Form.Control
                    placeholder={ticketDetails.odeme_tutari + " " + ticketDetails.para_birimi}
                    disabled
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Kart Marka</Form.Label>
                <Form.Control
                    placeholder={ticketDetails.kart_marka}
                    disabled
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Kart NO§</Form.Label>
                <Form.Control
                    placeholder={ticketDetails.kart_no}
                    disabled
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>kesinti_orani</Form.Label>
                <Form.Control
                    placeholder={ticketDetails.kesinti_orani}
                    disabled
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>kesinti_tutari</Form.Label>
                <Form.Control
                    placeholder={ticketDetails.kesinti_tutari}
                    disabled
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>net_tutar</Form.Label>
                <Form.Control
                    placeholder={ticketDetails.net_tutar}
                    disabled
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>odeme_tipi</Form.Label>
                <Form.Control
                    placeholder={ticketDetails.odeme_tipi}
                    disabled
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>taksit</Form.Label>
                <Form.Control
                    placeholder={ticketDetails.taksit}
                    disabled
                />
                </Form.Group>

            </Form>    
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
          {t('close')}
          </Button>
          
        </Modal.Footer>
      </Modal>
        </>
    )
}
