import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';

export const TotalUsers = ({users,hosts,goToHost}) => {
  return (
    <React.Fragment>
      <Title>Total Users</Title>
      <Typography component="p" variant="h4">
            {users}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
      </Typography>
      <Title> Total Hosts </Title>
      <Typography component="p" variant="h4">
            {hosts}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
      </Typography>
      <div>
        <Link color="primary" href="#" onClick={goToHost}>
          View Hosts
        </Link>
      </div>
    </React.Fragment>
  );
}