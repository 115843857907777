import React,{useState,useEffect} from 'react';
import PersonalHeader from '../common/PersonalHeader';
import "./styles.css";
import { Domain } from '../../../Domain';
import { useNavigate } from 'react-router-dom';
import { LodgeCard } from '../../AvailableLodges/comp/LodgeCard';
import {Footer,Navbar} from '../../../common';
import { useTranslation } from 'react-i18next';


export default function FavoritesPage() {
    const navigate = useNavigate();
    const [favorites,setFavorites] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if(localStorage.getItem('accessToken')){
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);
            
            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
            };
            
            fetch(`${Domain}/api/users/favorites`, requestOptions)
              .then(response => response.json())
              .then(result =>{
                setFavorites(result);
              })
              .catch(error => {
                navigate('../login')
              });
        }
        else{
            navigate('/login');
        }

    }, [])
    

    const tabName = "Fav";  
    return (
        <>
        <Navbar isSearchVisible={false} currentpage={'favtickets'}/>
        <PersonalHeader tab={tabName}/>
        <div style={{ minheight:"100vh",backgroundColor:"#f8f9fa"}}>
            <div  className="container px-2">
                <div className="row">
                <div className="col-12">
                    <div className="global-header">
                        <div className="global-header-number">{favorites.length}</div>
                        <div className="global-header-body">
                            <div className="global-header-title">{t('favTitle')}</div>
                            <p>{t('favHeader')}</p>
                        </div>
                    </div>
                </div>
                
                <div className="col-12 mb-4">
                    <div className="profile-favorites">
                    { favorites.length > 0 ?
                        favorites.map(fav => ( fav.page = 'fav' ,
                            <div key={fav._id} className="col-12 col-lg-4 p-2 col-md-6 col-sm-8 ">
                                <LodgeCard props={fav}/>
                            </div>
                        ))
                        :<h4>{t('campNotFound')}</h4>

                    }
                    </div>
                </div>
                </div>
            </div>
        </div>
        <Footer/>
    </>
    )
}