import { Domain } from "../Domain";


export const RefreshToken = ()=>{
  var myHeaders = new Headers();
  myHeaders.append("x-refresh", localStorage.getItem("refreshToken"));
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("accessToken")}`);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow'
  };
  fetch(`${Domain}/api/sessions/refresh`, requestOptions)
    .then(response => response.text())
    .then(result => {
      if(result.accessToken) {
          localStorage.setItem("accessToken", result.accessToken);
      }
      else{
          localStorage.clear();
          window.location.reload();
      }
    })
    .catch(error => {
      console.log("error : ",error);
      localStorage.clear();
      window.location.reload();
    });
}