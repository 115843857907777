import { Domain } from "../Domain";

export async function GetCities() {
    const requestOptions = {
        method: "GET",
        redirect: "follow"
      };
      
    return fetch(`${Domain}/api/cities`, requestOptions)
      .then((response) => response.text())
        .then((result) =>{
            const cities = JSON.parse(result);
            return cities;
        })
        .catch((error) => console.error(error));
}
