import React from 'react'
import { CheckoutNav } from './comp/CheckoutNav';
import { useNavigate } from 'react-router-dom'
import { Footer} from '../../common';
import {Card,Button,Typography,CardContent} from '@mui/joy';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';

export const CheckoutSuccess = () => {
  let navigate = useNavigate();

  return (
    <>
      <CheckoutNav/>
      <div className='bg-main'>
          <div className='p-5 pb-2 container'>
            <div className='row'>
              <Card
                variant="outlined"
                sx={{
                    maxHeight: 'max-content',
                    maxWidth: '900px',
                    mx: 'auto',
                    paddingLeft:'60px',
                    paddingRight:'60px',
                    paddingTop:'30px',
                    paddingBottom:'60px'
                }}
                >
                <Typography textAlign={'center'} level="h3"  >
                <CelebrationOutlinedIcon color={'#da5c40'} style={{width:'100px',height:'80px',color:'#ffd6cc' }} />
                <CelebrationOutlinedIcon color={'#da5c40'} style={{width:'100px',height:'80px',color:'#e8a674' }} />
                <CelebrationOutlinedIcon style={{width:'100px',height:'80px',color:'#e79150' }} />
                <CelebrationOutlinedIcon style={{width:'100px',height:'80px',color:'#EF791F' }} />
                <CelebrationOutlinedIcon style={{width:'100px',height:'80px',color:'#da5c40' }} />
                <CelebrationOutlinedIcon style={{width:'100px',height:'80px',color:'#b74c34' }} />
                </Typography>
                <Typography padding={4} style={{color:'#da5c40'}} fontWeight={"800"} textAlign={'center'} level="h2"  >
                  HELL YEAH!
                </Typography>
                <Typography paddingBottom={5} paddingTop={5} textColor={'#9d9d9d'} textAlign={'center'} level="h5"  >
                Your reservation has been booked. Check your email for confirmation details, or go to <u style={{fontWeight:"700",color:'#EF791F'}}>My Trips!</u>
                </Typography>
                <CardContent
                    sx={{
                    display: 'flex',
                    }}
                >
                    <Button  sx={{alignContent:'center',justifyContent:'center',alignSelf:'center',width:'450px'}} onClick={()=>{ navigate('../home')}} variant="solid">
                        Head to Homepage
                    </Button>
                </CardContent>
              </Card>
          </div>
        </div>
      </div>
      <Footer current={'home'}/>

    </>
  )
}
