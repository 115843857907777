import React, { useEffect, useState } from 'react';
import Fade from '@mui/material/Fade';
import Modal from 'react-bootstrap/Modal';
import Animation from "../../resources/lottiefiles/Caravan-grey-animation.json"
import Lottie from "lottie-react";

export const LoadingModal = ({visible}) => {

    const [fade,setFade] = useState(false);
    useEffect(() => {
        setTimeout(()=>{
            setFade(!fade);
        },1000)
    })
    
    return (
        <div className='loadingModal'>
        <Modal className='loadingModal' fullscreen style={{alignItems:'center',display:'flex',width:'auto',justifyContent:'center'}}  backdrop="static" show={visible}>
            <Modal.Body >
                <div style={{alignItems:'center',marginLeft:60,display:'flex',width:'auto',justifyContent:'center',height:'100%',flexDirection:'column'}}>               
                <Lottie  style={{maxWidth:400}}  animationData={Animation} />
                <Fade timeout={{enter:600,exit:600}} in={fade} style={{ transformOrigin: '0 0 0' }} >
                    <h2 style={{color:"#fff",margin:-50}}> Loading ...</h2>
                </Fade>
                </div>
            </Modal.Body>
        </Modal>
        </div>    
    )
}
