export function formatDateMonth(dateStr){
    const date = new Date(dateStr);

    // Get the individual date components
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    
    // Format the date with leading zeros if necessary
    const formattedDate = `${day.toString().padStart(2, '0')} ${month}`;

    return formattedDate;
}