import React, { useEffect, useState } from 'react';
import { Domain } from '../../../Domain';
import { useNavigate,useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import {AdminDashboard} from '../../../common';
import Box from '@mui/material/Box';
import { Button, Checkbox, FormControlLabel, Toolbar } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
export const EditCategory= () => {

    let navigate = useNavigate();
    const location = useLocation();

    const data = location.state;
    const options = [
      { label: 'feature', value: 22 },
      { label: 'co-working area', value: 33 },
      { label: 'standard amenities', value: 44 },
      { label: 'additional support', value: 55 },
      { label: 'activities', value: 66 },
      { label: 'terrain', value: 77 },
    ];

    const [value, setValue] = React.useState(options[0]);
    const [inputValue, setInputValue] = React.useState('');

    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [error,seterror] = useState(false);
    const [success,setSuccess] = useState(false);

    const [Name,setName] = useState(data.name);
    const [EnName,setEnName] = useState(data.EnName ?? '');
    const [Icon,setIcon] = useState(data.icon);


    const [isTop,setIsTop] = useState(data.isTop);
    const [feature,setFeature] = useState(data.feature);
    const id = data.code;
    console.log( data);

    const editCat = ()=>{
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

      var raw = JSON.stringify({
          name: Name,
          feature:feature,
          Nameen:EnName,
          icon: Icon,
          isTop: isTop,
      });

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${Domain}/api/category/${id}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          if(result==="Forbidden") {
            localStorage.clear();
            navigate('/login');

          }
          else{
              const res = JSON.parse(result);
              if(res.name === Name){
                setSuccess(true);
              }
              else{
                seterror(true);
              }
              handleShow();
          }
        })
        .catch(error => console.log('error', error));
    }


    return (
        <>
          <Box sx={{ display: 'flex' }}>
          <AdminDashboard /> 
          <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            padding: 3,
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Grid sx={{backgroundColor:'#fff',paddingBottom:5,paddingRight:5, marginTop:3,width:'auto',borderRadius:2,marginLeft:2}} container spacing={4}>
            <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h4" >
                    Edit Category
                </Typography>
            </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e)=>{setName(e.target.value);}}
            value={Name}
            required
            id="Name"
            name="Name"
            label="Name"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
              <TextField
                onChange={(e)=>{setEnName(e.target.value);}}
                value={EnName}
                required
                id="EnName"
                name="English Name"
                label="English Name"
                fullWidth
                variant="standard"
              />
            </Grid>
        <Grid item xs={6}>
          <TextField
            value={Icon}
            onChange={(e)=>{setIcon(e.target.value);}}
            required
            id="Icon"
            name="Icon"
            label="Icon"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Autocomplete
          value={options.find((option) => option.value === value) || null} // Set selected option by value
          onChange={(event, newValue) => {
            setFeature(newValue ? newValue.value : null); // Update with the underlying value (number)
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id="controllable-states-demo"
          options={options}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Features" />}
        />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel  control={<Checkbox checked={isTop} value={isTop} onChange={(event, newValue)=>{setIsTop(newValue)}}/>} label="is Top?" />

        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  sx={{ mt: 3, ml: 1 }}
                  onClick={()=>{editCat()}}
                >
                    Edit
                </Button>
              </Grid>

        </Grid>
        </Box>
        </Box>

        <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                <Modal.Title> {error ?  "Warning!" : "Success"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {
                  error ? <div>an Error Ocurred!</div> : success?
                  <div> Category Updated Successfully!</div> : <></>

                }
                </Modal.Body>
                <Modal.Footer>
                  {<button onClick={()=>{
                    if(success||error){
                      setSuccess(false);
                      seterror(false);
                      handleClose();
                      if(success){
                        navigate('/admin/categories');
                      }
                      else{
                        window.location.reload();
                      }
                    }
                    else{
                      handleClose();
                    }
                  }} className="btn btn-outline-dark"> {success||error ? "Okay" : "Create Camp"}</button>  
                  }
                </Modal.Footer>
        </Modal>   
        </>
    )
}
