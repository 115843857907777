import { Domain } from "../Domain";
import { RefreshToken } from "./RefreshToken";


export const GetMe = (setUserInfo) =>{
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("accessToken")}`);
    var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
    };

    fetch(`${Domain}/api/users/me`, requestOptions)
    .then(response => response.text())
    .then(result => {
        if(result === "Forbidden"){
            RefreshToken();
        }
        else{
            if(setUserInfo !== undefined){
                const userInfo = JSON.parse(result);
                setUserInfo(userInfo);
                localStorage.setItem("user",result)
            }
        }

    })
    .catch(error => {console.log('error', error);});    

}