import React, { useEffect, useState } from 'react'
import {HostDashboard,Deposits} from '../../../common';
import {Grid,Box,Toolbar,Paper,Container} from '@mui/material';
import { Domain } from '../../../Domain';
import { useNavigate } from 'react-router-dom';
import { ProfitChart } from '../../../common';
import { GetMe } from '../../../services/GetMe';
import { useTranslation } from 'react-i18next';


export const HostDashboardMain = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const today = new Date();
  const [MonthPayout,setMonthlyPayout] = useState(0);
  const [YearDataSet,setYearlyDataSet] = useState({});
  const [reserves,setReserves] = useState([])
  const [user,setUser] = useState('');

  const GetMonthlyPayout = () => {
    const monthsOfYear = {"January": 0, "February": 0, "March": 0, "April": 0, "May": 0, "June": 0, "July": 0, "August": 0, "September":0, "October":0, 
          "November":0, "December":0}
    let monthlyIncome = 0;
    reserves.forEach(element => {
      const dateTobeChecked = new Date(element.createdAt);
      if(dateTobeChecked.getFullYear()===today.getFullYear()){
        switch(dateTobeChecked.getMonth()){
            case(0):
                monthsOfYear.January = monthsOfYear.January + element.totalPrice;
                break;
            case(1):
                monthsOfYear.February = monthsOfYear.February + element.totalPrice;
                break;
            case(2):
                monthsOfYear.March = monthsOfYear.March + element.totalPrice;
                break;
            case(3):
                monthsOfYear.April  = monthsOfYear.April + element.totalPrice;
                break;
            case(4):
                monthsOfYear.May = monthsOfYear.May + element.totalPrice;
                break;
            case(5):
                monthsOfYear.June = monthsOfYear.June + element.totalPrice;
                    break;
            case(6):
                monthsOfYear.July = monthsOfYear.July + element.totalPrice;
                    break;
            case(7):
                monthsOfYear.August = monthsOfYear.August + element.totalPrice;
                  break;
            case(8):
                monthsOfYear.September = monthsOfYear.September + element.totalPrice;
              break;
            case(9):
                monthsOfYear.October = monthsOfYear.October + element.totalPrice;
                break;
            case(10):
                monthsOfYear.November = monthsOfYear.November + element.totalPrice;
                break;
            case(11):
                monthsOfYear.December = monthsOfYear.December + element.totalPrice;
        }
        if(dateTobeChecked.getMonth()===today.getMonth()) {
          monthlyIncome += element.totalPrice;
        }
      }

    });
    setYearlyDataSet(monthsOfYear);
    setMonthlyPayout(monthlyIncome);
  }
  
  useEffect(() => {
    GetMe(setUser);
    const temp = JSON.parse(localStorage.getItem('user'));
    if(temp.role === "campingSeller"){
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      fetch(`${Domain}/api/reserves/host/${temp._id}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          if(result==="Forbidden"){
            localStorage.clear();
            navigate('/login');
          }
          else if(result !== "Not Found"){
            setReserves(JSON.parse(result));
            GetMonthlyPayout();
          }
        })
        .catch(error => console.log('error', error));
    }
    else{
      navigate('/login');
    }
  }, [])
  

  return (
    <Box sx={{ display: 'flex' }}>
      <HostDashboard />
      <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* Chart */}
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 140,
                  }}
                >
                  <h3 className='text-center pt-2'>{t('TitleHostDashboard')}</h3>
                  <h3 style={{color:'#EF791F'}} className='text-center pt-2'> {user?.firstName} {user?.lastName} 	&#128075; &#x1F44B; </h3>

                  
                </Paper>
              </Grid>
            
              <Grid item xs={12} md={4} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <Deposits reserveLength={reserves.length} Income={MonthPayout}/>
                </Paper>
              </Grid>
              <Grid item xs={12} md={8} lg={8}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <ProfitChart dataSet={YearDataSet}/>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
    </Box>
  )
}
