import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import Select from '@mui/material/Select';

export function LanguageSelector() {
  const { i18n } = useTranslation();
  const [language,setLanguage] = React.useState(
    localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng')  === "tr" ? "🇹🇷": "🇬🇧" : "🇹🇷"
    );

  const options = [
    { value: '1', label: 'Turkish', src: '🇹🇷' },
    { value: '2', label: 'English', src: '🇬🇧' },
  ];

  function renderValue(option) {
    if (!option) {
      return null;
    }
    return (
        <MenuItem key={option.value} style={{width:10}}  >{option}</MenuItem>
    );
  }

  const handleChange = (event) => {
      if(event.target.value==="Türkçe" || event.target.value=== "🇹🇷Türkçe" || event.target.value=== "🇹🇷" ){
        i18n.changeLanguage("tr");
        setLanguage("🇹🇷");
      }
      else{
        i18n.changeLanguage("en");  
        setLanguage("🇬🇧");
    
      }
    window.location.reload();  
}

  return (
    <Select
      value={language}
      onChange={handleChange}
      renderValue={renderValue}
      style={{fontSize:14,height:40,maxWidth: 80,padding:0,margin:0,background:'#FFF'}} 
    >
    {
      options.map((option) => (
        <MenuItem key={option.value} value={option.src}>{option.src} {option.label}</MenuItem>
      ))
    }
    </Select>
  );
}