import React from 'react';
import heroImage from './heroImg.png';
import { useTranslation } from 'react-i18next';
export const TourHero = () => {
  const { t } = useTranslation();

  return (
    <div className='container-fluid'>
        <section className="section-hero">
        <h1 className="heading-primary">
          {t('SecondHeroTitle')}
          </h1>
      <div className="row">
          <div className='col-md-7' >
              <img style={{borderRadius: 20, width: '100%', height: 350, objectFit:'cover'}}
                src='https://cdn.pixabay.com/photo/2020/03/01/06/43/spring-4891823_1280.jpg'
              alt="Tent on a cliffside overlooking the pacific ocean" />

          </div>
          <div className='col-md-5 mt-3'>
          <p className="hero-description"> {t('SecondHeroDescription')} </p>

            <a href="/tours" className="butn butn--fill margin-right-btn w-100 " style={{textAlign:'center'}}> {t('SearchTours')}</a>  
          </div>
      </div>
    </section>
    </div>
  )
}
