import React from 'react'
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import Rating from '@mui/material/Rating';
import "./style.css"
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const MapItem = (props) => {
  const data = props.props;
  const navigate = useNavigate();
  const { t } = useTranslation();


  return (
    <>
    <Card variant="outlined" sx={{ width: 'auto' }}>
        <div>
          <Typography level="h1" fontSize="md" sx={{ mt:0}}>
            {data.title}
          </Typography>
          <Typography level="body2" marginTop={0}>{data.location.province} {", "} {data.location.district}</Typography>
        </div>
        <AspectRatio height="120px">
          <img
            src={data.image[0].path}
            loading="lazy"
            alt=""
          />
        </AspectRatio>
        <CardContent orientation="horizontal">
            <div>
            <Typography level="h1" fontSize="sm" fontWeight="lg">
                {data.price} {"TL / "} {t('day')}
            </Typography>
            <Rating size='small' name="read-only" value={data.rate} readOnly />
            </div>
          <Button
            variant="solid"
            size="sm"
            color="neutral"
            aria-label="Explore Area"
            sx={{ ml: 'auto', fontSize:12, height:40,  fontWeight: 600 }}
            onClick={()=>{navigate(`../camp/${data.title}`)}}
          >
            {t('explore')}
          </Button>
        </CardContent>
    </Card>
    </>
  )
}
