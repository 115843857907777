import React , { useEffect }from 'react';
import "./home.css";
import { FirstInfo } from './Components/FirstInfo';
import { Second_Info } from './Components/Second_Info';
import { Poi } from './Components/Poi';
import { Hero } from './Components/Hero';
import { Safety } from './Components/Safety';
import { Footer,LoadingModal,Navbar } from '../../common';
import { useDispatch, useSelector } from 'react-redux';
import { getMapCamps } from '../../StateManagement/actions/camps';
import { TourHero } from './Components/TourHero';
import { Cooperates } from './Components/Cooperates';

export const Home = () => {
    let dispatch = useDispatch(); 
    useEffect(() => {
      dispatch(getMapCamps());
    }, []);
    const {loaded} = useSelector(state => state.CampsReducer);

    return (
        <>
            <LoadingModal visible={!loaded}/>
            <div className='bg-main'>
                <div className='home-body overflow-hidden'>
                    <Navbar isSearchVisible={false}/>
                    <Hero/>
                    <Cooperates/>
                    <TourHero/>
                    <FirstInfo/>
                    <Second_Info/>
                    <Poi/>
                </div>
            </div>
            <div className='bg-lgreen py-5'>
                <div className='home-body overflow-hidden'>
                    <Safety/>
                </div>
            </div>
            <div className='bg-light'>
                <div className='home-body overflow-hidden'>
                </div>    
            </div>
            <Footer current={''}/>
        </>
  )
}