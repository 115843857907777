import React, { useState, useEffect, useRef} from 'react';
import { CheckoutNav } from './comp/CheckoutNav';
import CheckoutCard from '../CampingTour/components/CheckoutCard';
import CreditCardForm from './comp/CreditCardForm';
import UserDetailsCardForm from './comp/UserDetailsCardForm';
import { Domain } from '../../Domain';
import Modal from 'react-bootstrap/Modal';
import { useLocation, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/joy/CircularProgress';

export const CheckoutTourInitial = () => {
  const [dateofbirth,setDob] = useState("");
  const [name,setName] = useState("");
  const [email,setemail] = useState("");
  const [phone,setPhone] = useState("");
  const [MinDate,setMinDate] = useState("");
  const [MaxDate,setMaxDate] = useState("");
  const [step1_success,setStep1_success] = useState(false);
  const [pay_token,setPayToken] = useState('');
  const [show, setShow] = useState(false);
  const [message,setMessage] = useState("");
  const navigate = useNavigate();
  const [wait,setWait] = useState(true);
  const location = useLocation();
  const [ticket,setTicket] = useState('');
  const data = location.state;
  const [isPaid,setIspaid] = useState(false);


  const getByPnr = (pnr)=>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${Domain}/api/tickets/${pnr}`, requestOptions)
      .then(response => response.json())
      .then(result =>{
          setIspaid(result.status);
      })
      .catch(error => console.log('error', error));
  }
  


  const Checkout = () =>{
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

      var raw = JSON.stringify({
        "campingTourId": data.tour.campingTourId,
        "owner": data.tour.owner._id,
        "title": data.title,
        "price": data.total,
        "checkIn": data.checkin,
        "checkOut": data.checkout,
        "amount": data.person + data.children,
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      fetch(`${Domain}/api/payments/ticket`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setShow(false);
          setWait(false);
          if(result.data.status==="success"){
            setPayToken(result.data.token);
            setTicket(result.ticket);
            setStep1_success(true);
          }
          else{
            setStep1_success(false);
            setPayToken('');
          }
        })
        .catch(error => {
          setShow(false);
          console.log("error, ", error);
          localStorage.removeItem('accessToken');
          setWait(false);
          setShow(true)
        });
  }
  const Iframe_PayTR = () =>{
      
    return(
      <iframe 

      onLoad={()=>{
          getByPnr(ticket.pnr);
          if(isPaid==='paid'){
            navigate('/checkout/success');
          }
      }} 
 
      src={`https://www.paytr.com/odeme/guvenli/${pay_token}`} id="paytriframe" 
        style={{
          height:'100vh'
        }} width={"100%"}>
        </iframe>
    )
  }

  return (
    <>
      {step1_success ?  <Iframe_PayTR/>
      :
      <>
      <CheckoutNav/>
      <div className='bg-main'>
        <div className='container'>
          <div className='row'>
            <div className='py-5 col-md-8 col-12'>
                  <UserDetailsCardForm/>
                  <CreditCardForm/>
            </div>
            <div className=' py-5 col-md-4 col-12'>
              <CheckoutCard data={data} Checkout={Checkout} stage={'final'}/>
            </div>
          </div>
        </div>
      </div>
      </>
      }
      
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title> { wait ? "Please Wait" : "Error"} </Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center'>
        { wait ?
             <CircularProgress
             color="danger"
             determinate={false}
             size="lg"
           />
           :
                <h5> Please Login Again</h5> 
        }
        </Modal.Body>
        {wait ? <></> :
        
        <Modal.Footer>
          <button onClick={()=>{
            setShow(false);
            navigate('../../login');
          }} className="btn btn-outline-dark">Okay</button>
        </Modal.Footer>}
      </Modal>   
    </>
  )
}
