import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Lottie from 'lottie-react';
import Animation from "../../../resources/lottiefiles/comingSoon.json"
import { useTranslation } from 'react-i18next';

export const ComingSoon = () => {
  const {t} = useTranslation();

  return (
    <Card  sx={{maxWidth:'auto'}} >
    <CardContent>
      <h5 className='px-3 pt-3 fw-bolder safety-text'>
          {t("Reservation")}
      </h5>
      <Lottie p style={{maxWidth:400}}  animationData={Animation} />

    </CardContent>
    </Card>
  )
}
