import * as React from 'react';
import Checkbox from '@mui/joy/Checkbox';
import Done from '@mui/icons-material/Done';

export default function HoverCheckbox({text,checked,onChange}) {
    return (
        <div>
            <Checkbox
                uncheckedIcon={<Done />}
                label={text}
                onChange={onChange}
                checked = {checked}
                slotProps={{
                root: ({ checked, focusVisible }) => ({
                    sx: !checked
                    ? {
                        '& svg': { opacity: focusVisible ? 1 : 0 },
                        '&:hover svg': {
                            opacity: 1,
                        },
                        }
                    : undefined,
                }),
                }}
            />
        </div>

    );
}