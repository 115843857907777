import React, { useEffect, useRef, useState } from 'react';
import { Domain } from '../../../Domain';
import { useLocation, useNavigate } from 'react-router-dom';
import {CloudinaryWidget,AdminDashboard} from '../../../common';
import Modal from 'react-bootstrap/Modal';
import { GetMe } from '../../../services/GetMe';
import { Button, Toolbar,InputLabel,MenuItem,FormControl,Select,Box,
          Grid,TextField,Autocomplete,Typography} from '@mui/material';
import { t } from 'i18next';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import { GetCities } from '../../../services/GetCities';
import { GetIlce } from '../../../services/GetIlce';

import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import dayjs from 'dayjs';

const customIcon = new L.Icon({
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});



export const EditCamp = () => {


    const location = useLocation();
    

    const [data,setData] = useState("empty");

    let navigate = useNavigate();
    
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [error,seterror] = useState(false);
    const [success,setSuccess] = useState(false);
    const [edit,setEdit] = useState(false);
    const [id,setId] = useState('');
    const [glamping,setGlamping] = useState(data.glampingLeanDayCost ? true : false)
    const [bungalow,setBungalow] = useState(data.bungalovLeanDayCost ? true : false)
    const [caravan,setCaravan] = useState(data.caravanLeanDayCost ? true : false)
    const [districts,setDistricts] = useState([]);
    const [tent,setTent] = useState(data.tentLeanDayCost);
    const [errorMesage,setErrorMsg] = useState(t('ErrorMessage'));

    const [title,setTitle] = useState(data.title);
    const [description,setDescription] = useState(data.description);
    const [price,setPrice] = useState(data.price);
    const [type,setType] = useState(data.type);
    const [rate,setRate] = useState(data.rate);
    const [capacity,setCapacity] = useState(data.capacity);
    console.log(data);
    const [markerPosition, setMarkerPosition] = useState('');
    const [checkinRule,setCheckInRule] = useState("");
    const [checkOutRule,setCheckOutRule] = useState("");
    const [minimumDays,setMinimumDays] = useState("");
    const [tentLeanDayCost,setTentLeanDayCost] = useState("");
    const [glampinLeanDayCost,setglampinLeanDayCost] = useState("");
    const [categories,setCategories] = useState([]);
    const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
    const [CaravanDayCost,setCaravanCost] = useState(0);
    const [bunglowDayCost,setBungalowDayCost] = useState(0);


    const [additionalSupport,setAdditionalSupport] = useState([]);
    const [selectedAdditionalSupport,setSelectedAdditionalSupport] = useState([]);
    const [selectedAdditionalSupportImages,setSelectedAdditionalSupportImages] = useState([]);

    const [standardAmenities,setStandardAmenities]= useState([]);
    const [standardAmenitiesSelected,setStandardAmenitiesSelected]= useState([]);
    const [standardAmenitiesImages,setStandardAmenitiesImages]= useState([]);


    const [publicArea,setPublicArea]= useState([]);
    const [publicAreaSelected,setPublicAreaSelected]= useState([]);
    const [publicAreaImages,setPublicAreaImages]= useState([]);

    const [campType,setCampTypes] = useState([]);
    const [selectedcampType,setSelectedCampTypes] = useState([]);


    const [activities,setActivities]= useState([]);
    const [activitiesSelected,setActivitiesSelected]= useState([]);
    const [activitiesImages,setActivitiesImages]= useState([]);

    const [terrain,setTerrain]= useState([]);
    const [terrainSelected,setTerrainSelected]= useState([]);
    const [terrainImages,setTerrainImages]= useState([]);

    const [province,setProvince] = useState("");
    const [district,setDistrict] = useState("");
    const [userInfo,setUserInfo] = useState("");
    const [cities,setCities] = useState("");

    async function handleChange (event){
      setProvince(event.target.value);
      const Districts = await GetIlce(event.target.value);
      setDistricts(Districts);
    };
    

    console.log(markerPosition);
    useEffect(() => {
      if(!localStorage.getItem('accessToken')){
        navigate('/login');
      }
      else{
        GetMe(setUserInfo);

      }
    }, [])

    const handleUploadSuccess = (url) => {
      setUploadedImageUrl(url);
    };

    useEffect(() => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
    

      fetch(`${Domain}/api/category`, requestOptions)
        .then(response => response.text())
        .then(result => {
          if(result==='Forbidden'){
              localStorage.clear();
              navigate('/login');
          }
          else{
              const resp = JSON.parse(result);
              const temp2 = resp.filter(obj => obj.feature !== "88" && obj.feature !== "22");
              const campType = resp.filter(obj => obj.feature === "22");
              const publicArea = temp2.filter(obj => obj.feature === "33");
              const aminities = temp2.filter(obj => obj.feature === "44");
              const additionalSupport = temp2.filter(obj => obj.feature === "55");
              const activites = temp2.filter(obj => obj.feature === "66");
              const terrain = temp2.filter(obj => obj.feature === "77");
              setCampTypes(campType);
              setPublicArea(publicArea);
              setStandardAmenities(aminities);
              setAdditionalSupport(additionalSupport);
              setActivities(activites);
              setTerrain(terrain);
              setCategories(temp2);
          }
        }
        )
        .catch(error =>{
          console.log('error', error)
        } );
    }, [])

    useEffect(() => {
      async function setAsyncValue() {
        let response = await GetCities();
        setCities(response);
      }
      setAsyncValue()
    }, []);




  
    async function getCampAreaDetails(){
      var myHeaders = new Headers();

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      const temp = location.state;

      fetch(`${Domain}/api/campingareas/${temp.campingAreaId}`, requestOptions)
        .then(response => response.json())
        .then(async result => {
            setData(result);
            setTitle(result.title);
            setPrice(result.price);
            setCapacity(result.capacity);
            setMinimumDays(result.minimumDays);
            const checkin = new dayjs(result.checkInRule);
            setCheckInRule( checkin);
            setCheckOutRule( new dayjs(result.checkOutRule));
            setType(result.type);
            setRate(result.rate);
            setDescription(result.description);
            setTentLeanDayCost(result.tentLeanDayCost);
            setCaravanCost(result.caravanLeanDayCost);
            setBungalowDayCost(result.bungalovLeanDayCost);
            setglampinLeanDayCost(result.glampingLeanDayCost);
            setDistrict(result.location.district);
            const lat = result.coordinate.latitude;
            const lng = result.coordinate.longitude;
            setMarkerPosition({lat:lat,lng:lng})
            setId(result.campingAreaId);
            const {category,image} = result;
            const matchedAdditionalSupport = additionalSupport.filter(item => result.additionalSupport.includes(item.name));
            setSelectedAdditionalSupport(matchedAdditionalSupport);
            var publics = result.publicArea;
            const matchedPublic = publicArea.filter(fruit =>
              publics.some(criteria => criteria.name === fruit.name)
            );
            setPublicAreaSelected(matchedPublic);
            var standardt = result.standardAmenities;
            const matchedStandards = standardAmenities.filter(fruit =>
              standardt.some(criteria => criteria.name === fruit.name)
            );
            setStandardAmenitiesSelected(matchedStandards);
            var activity = result.activities;
            const matchedactivities = activities.filter(fruit =>
              activity.some(criteria => criteria.name === fruit.name)
            );
            setActivitiesSelected(matchedactivities);
            var terrainsa = result.terrain;
            const matchedterrain = terrain.filter(fruit =>
              terrainsa.some(criteria => criteria.name === fruit.name)
            );
            setTerrainSelected(matchedterrain);
            const matchedProvince = cities.filter(item => item.text.includes(result.location.province));
            setProvince(matchedProvince[0].value); 
            const Districts = await GetIlce(matchedProvince[0].value);
            setDistricts(Districts);
            const matchedDistrict = Districts.filter(item => item.text.includes(result.location.district));
            setDistrict(matchedDistrict[0].value);

            const additionalImages = [];
            const publicAreaImages = [];
            const TerrainImages = [];
            const activitiesImages = [];
            const aminityImages = [];
            for (let i = 0; i < image.length; i++) {
              const element = image[i];

              if( element.featureCategory == '33'){
                additionalImages.push(element.path);
              }
              else if( element.featureCategory == '44'){
                aminityImages.push(element.path);
              }
              else if( element.featureCategory == '55'){
                publicAreaImages.push(element.path);
              }
              else if( element.featureCategory == '66'){
                activitiesImages.push(element.path);
              }
              else if( element.featureCategory == '77'){
                TerrainImages.push(element.path);
              }            
            }
            setSelectedAdditionalSupportImages(additionalImages);
            setPublicAreaImages(publicAreaImages);
            setTerrainImages(TerrainImages);
            setActivitiesImages(activitiesImages);
            setStandardAmenitiesImages(aminityImages);
            const match = category.filter(str => campType.find(obj => obj.code === str));
            const matchedObject = campType.filter(obj => obj.code === match);
            setSelectedCampTypes(matchedObject);
      
        })
        .catch(error => console.log('error', error));
    }

    useEffect(() => {
      getCampAreaDetails();
    }, [categories,publicArea,standardAmenities,additionalSupport,activities,terrain,cities,campType])
    
    const editCampDetails = ()=>{
      if(markerPosition?.lat === "undefined" || markerPosition?.lng === "undefined" || 
        markerPosition?.lat === undefined || markerPosition?.lng === undefined){
          setShow(true)
          seterror(true);
          setErrorMsg("Kampınızın veya turunuzun konumunu belirlemek için lütfen haritadan seçin !");
          return
      }
      var myHeaders = new Headers();
      myHeaders.append("x-refresh", `Bearer ${localStorage.getItem('refreshToken')}`);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

      const categoriesCodes = [];
      let combinedImages = [];
     
      for (const obj of standardAmenities) {
        if (obj.hasOwnProperty('code')) {
          categoriesCodes.push(String(obj.code));
        }
      }
      for (const obj of terrainSelected) {
        if (obj.hasOwnProperty('code')) {
          categoriesCodes.push(String(obj.code));
        }
      }
      for (const obj of activitiesSelected) {
        if (obj.hasOwnProperty('code')) {
          categoriesCodes.push(String(obj.code));
        }
      }
      for (const obj of publicAreaSelected) {
        if (obj.hasOwnProperty('code')) {
          categoriesCodes.push(String(obj.code));
        }
      }
      for (const obj of selectedAdditionalSupport) {
        if (obj.hasOwnProperty('code')) {
          categoriesCodes.push(String(obj.code));
        }
      }
      for (const obj of selectedcampType) {
        if (obj.hasOwnProperty('code')) {
          categoriesCodes.push(String(obj.code));
        }
      }
      
      for (let i = 0; i < publicAreaImages.length; i++) {
        const element = publicAreaImages[i];
        combinedImages.push({
          "featureCategory": "33",
          "path":element
        })
      }
      for (let i = 0; i < standardAmenitiesImages.length; i++) {
        const element = standardAmenitiesImages[i];
        combinedImages.push({
          "featureCategory": "44",
          "path":element
        })
      }
      for (let i = 0; i < selectedAdditionalSupportImages.length; i++) {
        const element = selectedAdditionalSupportImages[i];
        combinedImages.push({
          "featureCategory": "55",
          "path":element
        })
      }
      for (let i = 0; i < activitiesImages.length; i++) {
        const element = activitiesImages[i];
        combinedImages.push({
          "featureCategory": "66",
          "path":element
        })
      }
      for (let i = 0; i < terrainImages.length; i++) {
        const element = terrainImages[i];
        combinedImages.push({
          "featureCategory": "77",
          "path":element
        })
      }
      const body_province = cities.find(element => element.value === province);
      const body_disctrict = districts.find(element => element.value === district);
      const standardAmenitiesSelected1 = standardAmenitiesSelected.map(({ icon, name }) => ({
        iconUrl: icon,
        name
      }));
      const selectedAdditionalSupport1 = selectedAdditionalSupport.map(element => (element.name)) ?? [];
      const activitiesSelected1 = activitiesSelected.map(({ icon,name }) => ({
        iconUrl: icon,
        name
      }));
      const terrainSelected1 = terrainSelected.map(({ icon, name }) => ({
        iconUrl: icon,
        name
      }));
      const publicAreaSelected1 = publicAreaSelected.map(({ icon, name}) => ({
        iconUrl: icon,
        name
      }));


      var raw = JSON.stringify({
        "role": "admin",
        "title": title,
        "description": description,
        "price": parseInt(price),
        "rate": 0,
        "cancelationPolicy": "30 gün",
        "type": "paid",
        "image": combinedImages,
        "capacity": parseInt(capacity),
        "standardAmenities":standardAmenitiesSelected1,
        "additionalSupport": selectedAdditionalSupport1,
        "publicArea": publicAreaSelected1,
        "checkInRule": checkinRule,
        "checkOutRule": checkOutRule,
        "minimumDays": minimumDays,
        "tentLeanDayCost": tentLeanDayCost,
        "glampingLeanDayCost": glampinLeanDayCost,
        "bungalovLeanDayCost": bunglowDayCost,
        "caravanLeanDayCost": CaravanDayCost,
        "activities": activitiesSelected1,
        "terrain": terrainSelected1,
        "coordinate": {
          "latitude": String(markerPosition.lat),
          "longitude": String(markerPosition.lng)
        },
        "category":  categoriesCodes,
        "location": {
          "province": body_province.text,
          "district": body_disctrict.text,
        }
      });
      seterror(false);
      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${Domain}/api/admin/campingareas/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if(result.hasOwnProperty("owner")){
            setSuccess(true)
          }
          else{
            seterror(true)
          }
          handleShow();
        })
        .catch(error => console.log('error', error));

    }


    const MapClickHandler = () => {
      useMapEvents({
        click(e) {
          setMarkerPosition(e.latlng);
        },
      });
      return null;
    };

    return (
       data !== "empty" && categories.length > 0 &&
        <>
          <Box sx={{ display: 'flex' }}>
          <AdminDashboard /> 
          <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            padding: 3,
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Grid sx={{backgroundColor:'#fff',paddingBottom:5,paddingRight:5, marginTop:3,width:'auto',borderRadius:2,marginLeft:2}} container spacing={4}>
            <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h4" >
                  {t('UpdateCamp')}
                </Typography>
            </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e)=>{setTitle(e.target.value);}}
            value={title}
            required
            label={t("Title")}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e)=>{setPrice(e.target.value);}}
            value={price}
            type='number'
            required
            label={t("Price")}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
        <TextField
            onChange={(e)=>{setCapacity(e.target.value);}}
            value={capacity}
            required
            label={t("Capacity")}
            type='number'
            fullWidth
            variant="standard"
          />

        </Grid>
        <Grid item sm={6} xs={12}>
        <TextField
            onChange={(e)=>{setMinimumDays(e.target.value);}}
            value={minimumDays}
            required
            label={t("Minimum Days")}
            type='number'
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>

        <TextField
          id="standard-textarea"
          label={t("Description")}
          placeholder={t("writeADesc")}
          multiline
          fullWidth
          rows={4}
          value={description}
          onChange={(e)=>{setDescription(e.target.value);}}
          variant="standard"
        />
        </Grid>
        <Grid item sm={6} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['TimePicker']}>
            <TimePicker ampm={false} value={checkinRule} sx={{width:'100%'}} onChange={(e)=>{setCheckInRule(e)}} label={t("CheckInRule")} />
          </DemoContainer>
        </LocalizationProvider>
        </Grid>
        <Grid item sm={6} xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['TimePicker']}>
            <TimePicker ampm={false} value={checkOutRule} sx={{width:'100%'}} onChange={(e)=>{setCheckOutRule(e)}} label={t("CheckOutRule")} />
          </DemoContainer>
        </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          {
            tent ?         
            <div style={{display:'flex',flexDirection:'row'}}>
                           <TextField
            value={tentLeanDayCost}
            onChange={(e)=>{setTentLeanDayCost(e.target.value);}}
            required
            label={t("tentcost")}
            type='number'
            fullWidth
            variant="standard"
          />
           <Button
            variant="contained"
            color='error'
            sx={{ mt:3, ml: 1,width:50}}
            onClick={()=>{ setTent(false)}}
          >
          <RemoveCircle/>              
          </Button>  

            </div>
       
            :
            <Button
            variant="contained"
            sx={{ mt: 3, ml: 1 }}
            onClick={()=>{ setTent(true)}}
          >
              {t('addtentcost')}
          </Button>          
          }
        </Grid>
        <Grid item xs={12} sm={6}>
          {
            caravan ?         
            <div style={{display:'flex',flexDirection:'row'}}>
                           <TextField
            value={CaravanDayCost}
            onChange={(e)=>{setCaravanCost(e.target.value);}}
            required
            label={t("karavancost")}
            type='number'
            fullWidth
            variant="standard"
          />
           <Button
            variant="contained"
            color='error'
            sx={{ mt:3, ml: 1,width:50}}
            onClick={()=>{ setCaravan(false)}}
          >
          <RemoveCircle/>              
          </Button>  

            </div>
       
            :
            <Button
            variant="contained"
            sx={{ mt: 3, ml: 1 }}
            onClick={()=>{ setCaravan(true)}}
          >
              {t('addcaravancost')}
          </Button>          
          }
        </Grid>
        
        <Grid item xs={12} sm={6}>
          {
            bungalow ?         
            <div style={{display:'flex',flexDirection:'row'}}>
                           <TextField
            value={bunglowDayCost}
            onChange={(e)=>{setBungalowDayCost(e.target.value);}}
            required
            label={t("bungalowcost")}
            type='number'
            fullWidth
            variant="standard"
          />
           <Button
            variant="contained"
            color='error'
            sx={{ mt:3, ml: 1,width:50}}
            onClick={()=>{ setBungalow(false)}}
          >
          <RemoveCircle/>              
          </Button>  

            </div>
       
            :
            <Button
            variant="contained"
            sx={{ mt: 3, ml: 1 }}
            onClick={()=>{ setBungalow(true)}}
          >
              {t('addbungalovcost')}
          </Button>          
          }
        </Grid>
        <Grid item xs={12} sm={6}>
          {
            glamping > 0 ?         
            <div style={{display:'flex',flexDirection:'row'}}>
                   <TextField
            value={glampinLeanDayCost}
            onChange={(e)=>{setglampinLeanDayCost(e.target.value);}}
            required
            label={t('glampingcost')}
            type='number'
            fullWidth
            variant="standard"
          />
           <Button
            variant="contained"
            color='error'
            sx={{ mt:3, ml: 1,width:50}}
            onClick={()=>{ setGlamping(false)}}
          >
          <RemoveCircle/>              
          </Button>  

            </div>
       
            :
            <Button
            variant="contained"
            sx={{ mt: 3, ml: 1 }}
            onClick={()=>{ setGlamping(true)}}
          >
              {t('addglampingLeandaycost')}
          </Button>          
          }

        </Grid>
        <Grid item xs={12} sm={6} style={{height:50}}>
        <Box sx={{ minWidth: 120 }}>

        <FormControl variant="standard" fullWidth style={{height:50}}>
            <InputLabel id="demo-simple-select-label">{t("Province")}</InputLabel>
            <Select
            style={{height:50}}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={province}
              label={t("Province")}
              onChange={(handleChange)}
            >
              { cities.length > 0 && cities.map((city) =>(
                  <MenuItem selected={city.value == province} key={city._id} value={city.value} > {city.text}</MenuItem>
              ))

              }
            </Select>
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
        <Box sx={{ minWidth: 120 }}>

        <FormControl variant="standard" fullWidth style={{height:50}}>

        <InputLabel id="demo-simple-select-label">{t("District Name")}</InputLabel>

        <Select
            style={{height:50}}
            disabled={districts.length === 0}
              labelId="demo-simple-select-label-2"
              id="demo-simple-select-2"
              value={district}
              label={t("District Name")}
              onChange={(e)=>{
                setDistrict(e.target.value)}}
            >
              { districts.length > 0 && districts.map((dist) =>(
                  <MenuItem key={dist._id} value={dist.value} > {dist.text}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          </Box>

        </Grid>

        <Grid xs={12}>
          <Typography paddingLeft={4} paddingTop={3} fontWeight={500} style={{marginBottom:-25}} >
              {t('googlemapss')}
          </Typography>
        </Grid>
       
        <Grid item xs={12 } style={{zIndex:2}}>
          <MapContainer center={[38.9637,35.2433]} zoom={5} style={{width:'auto',height:'100%',minHeight:'400px'}}>
            <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {markerPosition && <Marker position={markerPosition} icon={customIcon} />}
          <MapClickHandler />
        </MapContainer>
        </Grid>
        <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h6" >
                {t("Camp Types")}
                </Typography>
        </Grid>
        <Grid item xs={12}>
        <Autocomplete
              multiple={true}
              id="tags-standard"
              options={campType}
              value={selectedcampType}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) =>{
                  setSelectedCampTypes(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={t("Camp Types")}
                />
              )}
            />
        </Grid>
        <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h6" >
                    {t("Camps Public Area")}
                </Typography>
        </Grid>      
        <Grid item xs={12}>
        <Autocomplete
              multiple
              id="tags-standard"
              options={publicArea}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) =>{
                  setPublicAreaSelected(newValue);
              }}
              value={publicAreaSelected}
              
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={t("Camps Public Area")}
                />
              )}
            />
        </Grid>
        <Grid xs={12} padding={4}>
          <div>
                  <CloudinaryWidget
                    apiKey="359728572815919"
                    cloudName="dyku0cdi3"
                    folder={"host/camp/PublicAreas"}
                    maxWidth={800}
                    maxHeight={600}
                    setImages={setPublicAreaImages}
                    images={publicAreaImages}
                    onUploadSuccess={handleUploadSuccess}
                  />
          </div>
        </Grid>
        <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h6" >
                {t("Additional support")}
                </Typography>
        </Grid>
        <Grid item xs={12}>
        <Autocomplete
              multiple
              id="tags-standard"
              options={additionalSupport}
              getOptionLabel={(option) => option.name}
              value={selectedAdditionalSupport}
              onChange={(event, newValue) =>{
                  setSelectedAdditionalSupport(newValue);
              }}
              
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={t("Additional support")}
                />
              )}
            />
        </Grid>
        <Grid xs={12} padding={4}>
          <div>
                  <CloudinaryWidget
                    apiKey="359728572815919"
                    cloudName="dyku0cdi3"
                    folder={"host/camp/AdditionalSupport"}
                    maxWidth={800}
                    maxHeight={600}
                    setImages={setSelectedAdditionalSupportImages}
                    images={selectedAdditionalSupportImages}
                    onUploadSuccess={handleUploadSuccess}
                  />
          </div>
        </Grid>


        <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h6" >
                    {t("Camps Aminities")}
                </Typography>
        </Grid>
        <Grid item xs={12}>
        <Autocomplete
              multiple
              id="tags-standard"
              options={standardAmenities}
              value={standardAmenitiesSelected}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) =>{
                  setStandardAmenitiesSelected(newValue);
              }}
              
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={t("Camps Aminities")}
                />
              )}
            />
        </Grid>
        <Grid xs={12} padding={4}>
          <div>
                  <CloudinaryWidget
                    apiKey="359728572815919"
                    cloudName="dyku0cdi3"
                    folder={"host/camp/CampsAminities"}
                    maxWidth={800}
                    maxHeight={600}
                    setImages={setStandardAmenitiesImages}
                    images={standardAmenitiesImages}
                    onUploadSuccess={handleUploadSuccess}
                  />
          </div>
        </Grid>
        <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h6" >
                    {t("Camps Activities")}
                </Typography>
        </Grid>
        <Grid item xs={12}>
        <Autocomplete
              multiple
              id="tags-standard"
              options={activities}
              value={activitiesSelected}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) =>{
                  setActivitiesSelected(newValue);
              }}
              
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={t("Camps Activities")}
                />
              )}
        />
        </Grid>
        <Grid xs={12} padding={4}>
          <div>
                  <CloudinaryWidget
                    apiKey="359728572815919"
                    cloudName="dyku0cdi3"
                    folder={"host/camp/CampsActivities"}
                    maxWidth={800}
                    maxHeight={600}
                    setImages={setActivitiesImages}
                    images={activitiesImages}
                    onUploadSuccess={handleUploadSuccess}
                  />
          </div>
        </Grid>


        <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h6" >
                    {(t('Camps Terrain'))}
                </Typography>
        </Grid>
        <Grid item xs={12}>
        <Autocomplete
              multiple
              id="tags-standard"
              options={terrain}
              getOptionLabel={(option) => option.name}
              value={terrainSelected}
              onChange={(event, newValue) =>{
                  setTerrainSelected(newValue);
              }}
              
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={(t('Camps Terrain'))}
                />
              )}
            />
        </Grid>
        <Grid xs={12} padding={4}>
          <div>
                  <CloudinaryWidget
                    apiKey="359728572815919"
                    cloudName="dyku0cdi3"
                    folder={"host/camp/CampsTerrain"}
                    maxWidth={800}
                    maxHeight={600}
                    setImages={setTerrainImages}
                    images={terrainImages}
                    onUploadSuccess={handleUploadSuccess}
                  />
          </div>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  sx={{ mt: 3, ml: 1 }}
                  onClick={()=>{editCampDetails()}}
                >
                    {t("UpdateCamp")}
                </Button>
              </Grid>
        </Grid>
        </Box>
          </Box>
          <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                <Modal.Title> {error ?  t("WarningTitle"): t("CreationCamp")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {
                  error ? <div>{errorMesage}</div> : success?
                  <div>{t('UpdateCampMsg')}</div> : <></>

                }
                </Modal.Body>
                <Modal.Footer>
                  {<button onClick={()=>{
                    if(success||error){
                      setSuccess(false);
                      seterror(false);
                      handleClose();
                      if(success){
                        navigate('/host/camps');
                      }
                      else{
                        window.location.reload();
                      }
                    }
                    else{
                      handleClose();
                    }
                  }} className="btn btn-outline-dark"> {success||error ? "Okay" : t("UpdateCampMsg")}</button>  
                  }
                </Modal.Footer>
                </Modal>   
        </>
    )
}
