import React,{useState,useEffect} from 'react';
import PersonalHeader from '../common/PersonalHeader';
import "./styles.css";
import { Domain } from '../../../Domain';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { Footer,Navbar} from '../../../common';
import { formatDate } from '../../../utils/FormatDate';

export default function MyReservations() {
    const navigate = useNavigate();
    const [MyReservations,setMyReservations] = useState([]);
    const [sort,setSort] = useState(localStorage.getItem('sortMyReservations')? localStorage.getItem('sortMyReservations'):'Filter');
    const [search,setSearch] = useState('');
    const[location,setLocation] = useState(localStorage.getItem('locationNameMyReservations')? localStorage.getItem('locationNameMyReservations'):'Location');
    const [show,setShow] = useState(false);
    const [reservationCounter, setReservationCounter] = useState(0);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [ticketDetails,setTicketDetails] = useState("");
    const { t, i18n } = useTranslation();
    


    const getReservationDetails = (ticket) =>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
    
        fetch(`${Domain}/api/reserves/${ticket.pnr}`, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setTicketDetails(result)
            // setCounter(counter+1);
          })
          .catch(error => console.log('error', error));
    }
    useEffect(() => {
    
        if(localStorage.getItem('accessToken')){
            var myHeaders = new Headers();
        
            const sort =     localStorage.getItem('sortMyReservations')?localStorage.getItem('sortMyReservations'):'';
            const search =   localStorage.getItem('searchMyReservations') ? localStorage.getItem('searchMyReservations') :'';
            const order =    localStorage.getItem('orderMyReservations') ? localStorage.getItem('orderMyReservations') : '';
            const location = localStorage.getItem('locationMyReservations') ? localStorage.getItem('locationMyReservations') :'';
            myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("accessToken")}`);
            var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
            };
            const user = JSON.parse(localStorage.getItem('user'));
    
            // fetch(`${Domain}/api/tickets?sortby?title=${search}&sortby=${sort}&sortorder=${order}&city=${location}`, requestOptions)
            fetch(`${Domain}/api/reserves/user/${user._id}`)
            .then(response => response.text())
            .then(result =>{
                const temp = JSON.parse(result);
                setMyReservations(temp);
                setReservationCounter(temp.length)
            })
            .catch(error => console.log('error', error));
        }
        else{
            navigate('/login');
        }

    }, []);

    
    const RefundReservation = () =>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);
    
        var raw = JSON.stringify({
            "pnr": ticketDetails.pnr,
            "returnAmount": ticketDetails.totalPrice,
            // "referenceNo": "9009898"
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
          
    
        fetch(`${Domain}/api/payments/return`, requestOptions)
          .then(response => response.json())
          .then(result =>{
            if(result.success){
                window.location.reload();
            }
            })
          .catch(error => console.log('error', error));
    }
    


    const tabName = "MyReservations";  
    return (
        <>
        <Navbar isSearchVisible={false} currentpage={'MyReservations'}/>
        <PersonalHeader tab={tabName}/>
        <div style={{ minheight:"100vh",backgroundColor:"#f8f9fa"}}>
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <div className="global-header">
                        <div className="global-header-number">{reservationCounter}</div>
                        <div className="global-header-body">
                            <div className="global-header-title">{t('Reservations')}</div>
                            <p>{t('MyRESERVATIONS')}</p>
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-5">
                    <div className="profile-favorites d-flex justify-content-center">
                    { MyReservations.length > 0 ? 
                        MyReservations.map((item,index) => (
                            <div key={item._id} style={{borderRadius:20}} className="event-sm p-2  border m-2">
                                
                                <div className="event-sm-image">
                                  
                                    <img src='https://cdn.prod.v2.camping.info/media/campsites/scout-camp-poznan/cMurLXB38Y2i.jpg' alt="Yüzyüzeyken Konuşuruz Konseri" />

                                </div>
                                <div className="event-sm-title">
                                <a style={{cursor:'pointer'}}>{item.title} {formatDate(item.checkIn)}</a>
                                </div>
                                <div className="event-sm-title">
                                    <Button variant='danger' onClick={()=>{handleShow(); getReservationDetails(item);}}>{t('details')}</Button>
                                </div>

                            </div>
                        ))
                        :
                        <h4>{t('NORESERVATIONS')}</h4>
                        
                    }
                    </div>
                </div>
                </div>
            </div>
        </div>
        <Footer/>

        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{ticketDetails.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Check In:</Form.Label>
                <Form.Control
                    placeholder={formatDate(ticketDetails.checkIn)}
                    disabled
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Check Out:</Form.Label>
                <Form.Control
                    placeholder={formatDate(ticketDetails.checkOut)}
                    disabled
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>{t('childrenCount')}</Form.Label>
                <Form.Control
                    placeholder={ticketDetails.numberOfChild}
                    disabled
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>{t('personCount')}</Form.Label>
                <Form.Control
                    placeholder={ticketDetails.numberOfPerson}
                    disabled
                />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">

                 <Form.Label>{t('paidAmount')}</Form.Label>
                <Form.Control
                    placeholder={ticketDetails.totalPrice + "₺"}
                    disabled
                />  
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                
                <Form.Label>{t('status')}</Form.Label>
                <Form.Control
                    placeholder={ticketDetails.status}
                    disabled
                /> 
                </Form.Group>
            </Form>    
        </Modal.Body>
        <Modal.Footer>
        <Button variant="danger" onClick={()=>{RefundReservation()}}>
            Cancel Reservation
          </Button>
          <Button variant="primary" onClick={()=> {handleClose(); navigate(`/camp/${ticketDetails.campingAreaId}`)}}>
                {t('goToCamps')}
          </Button>
          
        </Modal.Footer>
      </Modal>
    </>
    )
}