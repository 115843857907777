import { Domain } from "../../Domain";
export const GET_ALL_CAMPS= 'GET_ALL_CAMPS';
export const GET_ALL_CAMPS_SUCCESS= 'GET_ALL_CAMPS_SUCCESS';
export const GET_ALL_CAMPS_FAILURE= 'GET_ALL_CAMPS_FAILURE';
export const GET_ALL_MAP_CAMPS_SUCCESS= 'GET_ALL_CAMP_ID_FAILURE';

export const getCamps = (checkin='',checkout='',province='',) => {
    return async dispatch =>{
        dispatch(
        {
            type : GET_ALL_CAMPS,
        });

        let myHeaders = new Headers();
        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };  
        province = province.toLowerCase();
        const limit = 4;
        let skip = localStorage.getItem('skip') ? localStorage.getItem('skip') - 1 : 0 ;
        let sortby = localStorage.getItem('sortby') ||'' ;
        let sortOrder = localStorage.getItem('sortorder') || '';
        let pricemin = localStorage.getItem('pricemin') || '' ;
        let title = localStorage.getItem('title') || '' ;
        let pricemax = localStorage.getItem('pricemax') || '' ;
        let AdditionalCamps = localStorage.getItem('AdditionalCamps') ? JSON.parse(localStorage.getItem('AdditionalCamps')) :[] ;
        let AmenitiesCamps = localStorage.getItem('AmenitiesCamps') ? JSON.parse(localStorage.getItem('AmenitiesCamps')) :[] ;
        let ActivitiesCamps = localStorage.getItem('ActivitiesCamps') ? JSON.parse(localStorage.getItem('ActivitiesCamps')) :[] ;
        let TerrainCamps = localStorage.getItem('TerrainCamps') ? JSON.parse(localStorage.getItem('TerrainCamps')) :[] ;
        let publicAreaCamps = localStorage.getItem('publicAreaCamps') ? JSON.parse(localStorage.getItem('publicAreaCamps')) :[] ;
        let selectedcamptypes = localStorage.getItem('selectedcamptypes') ? JSON.parse(localStorage.getItem('selectedcamptypes')) : [];
        let categories = [...AdditionalCamps,...publicAreaCamps, ...TerrainCamps, ...AmenitiesCamps,...ActivitiesCamps,...selectedcamptypes];

        await fetch(`${Domain}/api/campingareas?checkin=${checkin}&checkout=${checkout}&province=${province}&sortby=${sortby}&search=${title}&sortorder=${sortOrder}&pricemin=${pricemin}&pricemax=${pricemax}&limit=${limit}&skip=${limit*skip}&categories=${categories}`,
         requestOptions)
        .then(response => response.json())
        .then(result => {
            dispatch(
                {
                  type : GET_ALL_CAMPS_SUCCESS,
                  payload:result,
                }
            );
        })
        .catch(error =>{ 
            console.log("err",error);
            dispatch(
                {
                  type : GET_ALL_CAMPS_FAILURE,
                  payload:false,
                }
            );        
        });
    }
}
export const getMapCamps = (checkin='',checkout='',province='',) =>{
    return async dispatch =>{
        dispatch(
        {
            type : GET_ALL_CAMPS,
        });

        let myHeaders = new Headers();
        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };  
        province = province.toLowerCase();
        const limit = 4;
        let skip = localStorage.getItem('skip') ? localStorage.getItem('skip') - 1 : 0 ;
        let sortby = localStorage.getItem('sortby') ||'' ;
        let sortOrder = localStorage.getItem('sortorder') || '';
        let pricemin = localStorage.getItem('pricemin') || '' ;
        let title = localStorage.getItem('title') || '' ;
        let pricemax = localStorage.getItem('pricemax') || '' ;
        let AdditionalCamps = localStorage.getItem('AdditionalCamps') ? JSON.parse(localStorage.getItem('AdditionalCamps')) :[] ;
        let AmenitiesCamps = localStorage.getItem('AmenitiesCamps') ? JSON.parse(localStorage.getItem('AmenitiesCamps')) :[] ;
        let ActivitiesCamps = localStorage.getItem('ActivitiesCamps') ? JSON.parse(localStorage.getItem('ActivitiesCamps')) :[] ;
        let TerrainCamps = localStorage.getItem('TerrainCamps') ? JSON.parse(localStorage.getItem('TerrainCamps')) :[] ;
        let publicAreaCamps = localStorage.getItem('publicAreaCamps') ? JSON.parse(localStorage.getItem('publicAreaCamps')) :[] ;
        let selectedcamptypes = localStorage.getItem('selectedcamptypes') ? JSON.parse(localStorage.getItem('selectedcamptypes')) : [];
        let categories = [...AdditionalCamps,...publicAreaCamps, ...TerrainCamps, ...AmenitiesCamps,...ActivitiesCamps,...selectedcamptypes];

        await fetch(`${Domain}/api/campingareas?checkin=${checkin}&checkout=${checkout}&province=${province}&sortby=${sortby}&search=${title}&sortorder=${sortOrder}&pricemin=${pricemin}&pricemax=${pricemax}&categories=${categories}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            dispatch(
                {
                  type : GET_ALL_MAP_CAMPS_SUCCESS,
                  payload:result,
                }
            );
        })
        .catch(error =>{ 
            console.log("err",error);
            dispatch(
                {
                  type : GET_ALL_CAMPS_FAILURE,
                  payload:false,
                }
            );        
        });
    }

}