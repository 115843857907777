import React, { useState } from 'react';
import { Domain } from '../../../Domain';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import {AdminDashboard} from '../../../common';
import Box from '@mui/material/Box';
import { Button, Checkbox, FormControlLabel, Toolbar } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


export const NewCategory = () => {

    let navigate = useNavigate();
    const options = [
      { label: 'feature', value: 22 },
      { label: 'co-working area', value: 33 },
      { label: 'standard amenities', value: 44 },
      { label: 'additional support', value: 55 },
      { label: 'activities', value: 66 },
      { label: 'terrain', value: 77 },
    ];
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [error,seterror] = useState(false);
    const [success,setSuccess] = useState(false);
    const [value, setValue] = React.useState(options[0]);
    const [inputValue, setInputValue] = React.useState('');


    const [Name,setName] = useState("");
    const [EnName,setEnName] = useState("");
    const [Icon,setIcon] = useState("");
    const [isTop,setIsTop] = useState(false);

    const CreateCat = () => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

      var raw = JSON.stringify({
        "name": Name,
        "icon": Icon,
        "Nameen":EnName,
        "feature": value,
        "isTop": isTop,

      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${Domain}/api/category`, requestOptions)
        .then(response => response.text())
        .then(result => {
          console.log(result);
          if(result==="Forbidden") {
            localStorage.clear();
            navigate('/login');

          }
          else{
              const res = JSON.parse(result);
              if(res.name === Name){
                setSuccess(true);
              }
              else{
                seterror(true);
              }
              handleShow();
          }
        })
        .catch(error => console.log('error', error));
    }



    return (
        <>
          <Box sx={{ display: 'flex' }}>
          <AdminDashboard /> 
          <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            padding: 3,
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Grid sx={{backgroundColor:'#fff',paddingBottom:5,paddingRight:5, marginTop:3,width:'auto',borderRadius:2,marginLeft:2}} container spacing={4}>
            <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h4" >
                    Create Category
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={(e)=>{setName(e.target.value);}}
                value={Name}
                required
                id="Name"
                name="Name"
                label="Turkish Name"
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                onChange={(e)=>{setEnName(e.target.value);}}
                value={EnName}
                required
                id="EnName"
                name="English Name"
                label="English Name"
                fullWidth
                variant="standard"
              />
            </Grid>
        <Grid item xs={6}>
          <TextField
            value={Icon}
            onChange={(e)=>{setIcon(e.target.value);}}
            required
            id="Icon"
            name="Icon"
            label="Icon"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Autocomplete
              value={options.find((option) => option.value === value) || null} // Set selected option by value
              onChange={(event, newValue) => {
                setValue(newValue ? newValue.value : null); // Update with the underlying value (number)
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id="controllable-states-demo"
              options={options}
              getOptionLabel={(option) => option.label} // Display the custom label
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} label="Feature" />}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel  control={<Checkbox checked={isTop} value={isTop} onChange={(event, newValue)=>{setIsTop(newValue)}}/>} label="is Top?" />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  sx={{ mt: 3, ml: 1 }}
                  onClick={()=>{CreateCat()}}
                >
                    Create
                </Button>

        </Grid>


              
        </Grid>
        </Box>
        </Box>
        <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                <Modal.Title> {error ?  "Warning!" : "Success"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {
                  error ? <div>an Error Ocurred!</div> : success?
                  <div> Category Created Successfully!</div> : <></>

                }
                </Modal.Body>
                <Modal.Footer>
                  {<button onClick={()=>{
                    if(success||error){
                      setSuccess(false);
                      seterror(false);
                      handleClose();
                      if(success){
                        navigate('/admin/categories');
                      }
                      else{
                        window.location.reload();
                      }
                    }
                    else{
                      handleClose();
                    }
                  }} className="btn btn-outline-dark"> {success||error ? "Okay" : "Create Camp"}</button>  
                  }
                </Modal.Footer>
        </Modal>   
        </>
    )
}
