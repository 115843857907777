import React, { useEffect, useState } from 'react';
import { Domain } from '../../../Domain';
import { useDispatch,useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import {AdminDashboard} from '../../../common';
import Box from '@mui/material/Box';
import { Button, Toolbar } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import { useTranslation } from 'react-i18next';


export const NewHost = () => {

    let navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [error,seterror] = useState(false);
    const [success,setSuccess] = useState(false);
    const [edit,setEdit] = useState(false);
    const [id,setId] = useState('');

    const [firstname,setFirstname] = useState("");
    const [lastName,setLastName] = useState("");
    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");
    const [password2,setPassword2] = useState("");
    const [role,setRole] = useState("");
    const [birthday,setBirthday] = useState("");
    const [iban,setIban] = useState("");
    const [accountName,setAccountName] = useState("");
    const [nationalId,setNationalId] = useState("");

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      };
      const CreateHost = ()=>{
        if(firstname.length > 3 && lastName.length > 3 && password.length > 7 && password === password2 && validateEmail(email) && birthday.length >7 && iban.length === 26 && accountName.length > 2 && nationalId.length == 11){
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          seterror(false);
          var raw = JSON.stringify({
            "firstName": firstname,
            "lastName": lastName,
            "email": email,
            "password": password,
            "passwordConfirmation": password2,
            "role": "campingSeller",
            "birthday": birthday,
            "bankAcountName": accountName,
            "IBAN":iban,
            "nationalID": nationalId
            });
          
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
          
          fetch(`${Domain}/api/users`, requestOptions)
            .then(response => response.text())
            .then(result => {
              if(result==="Acount already exists"){
                  seterror(true);
                  handleShow();
              }
              else{
                seterror(false);
                setSuccess(true);
                handleShow();
              }  
            })
            .catch(error => console.log('error', error));
        }
        else{
          seterror(true);
        }
      }
    return (
        <>
          <Box sx={{ display: 'flex' }}>
          <AdminDashboard /> 
          <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            padding: 3,
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Grid sx={{backgroundColor:'#fff',paddingBottom:5,paddingRight:5, marginTop:3,width:'auto',borderRadius:2,marginLeft:2}} container spacing={4}>
            <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h4" >
                    Create Host
                </Typography>
            </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e)=>{setFirstname(e.target.value);}}
            required
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            variant="standard"
          />
          { <>{(firstname.length>2 || !error)  ? <></> :<span style={{color: "red"}}>{t('warningName')} </span> }</>}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e)=>{setLastName(e.target.value);}}
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            variant="standard"
          />
          { <>{lastName.length>2 || !error ? <></> :<span style={{color: "red"}}>{t('warningName')}</span> }</>}
        </Grid>
        <Grid item sm={6} xs={12}>
        <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
          <Input
            id="standard-adornment-password"
            type={showPassword ? 'text' : 'password'}
            onChange={(e)=>{setPassword(e.target.value);}}
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
            { <>{password.length>7 || !error ? <></> :<span style={{color: "red"}}>{"password should be more than 7 characters"}</span> }</>}

        </Grid>
        <Grid item sm={6} xs={12}>
        <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
          <Input
            id="standard-adornment-password"
            type={showPassword ? 'text' : 'password'}
            onChange={(e)=>{setPassword2(e.target.value);}}
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          { <>{password===password2 || !error ? <></> :<span style={{color: "red"}}>{"Password is different from confirm password"}</span> }</>}


        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            
            onChange={(e)=>{setEmail(e.target.value.toLowerCase());}}
            required
            id="city"
            name="city"
            label="Email Address"
            fullWidth
            autoComplete="shipping address-level2"
            variant="standard"
          />
        </Grid>
        { <>{validateEmail(email) || !error ? <></> :<span style={{color: "red"}}>{"Enter a valid Email address!"}</span> }</>}

        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e)=>{setBirthday(e.target.value);}}
            required
            id="BirthDate"
            name="BirthDate"
            label={t('birthDate21')}
            fullWidth
            variant="standard"
          />
        </Grid>
        { <>{birthday.length> 7 || !error ? <></> :<span style={{color: "red"}}>{"Birthdate shouldn't be empty!"}</span> }</>}

        <Grid item xs={12} sm={6}>
          <TextField
            
            onChange={(e)=>{setAccountName(e.target.value);}}
            required
            id="city"
            name="city"
            label="Bank Account Name"
            fullWidth
            autoComplete="shipping address-level2"
            variant="standard"
          />
        </Grid>
          { <>{accountName.length > 3 || !error ? <></> :<span style={{color: "red"}}>{"Account Name isn't valid!"}</span> }</>}

        <Grid item xs={12} sm={6}>
          <TextField
            onChange={(e)=>{setNationalId(e.target.value);}}
            required
            id="BirthDate"
            name="T.C"
            label="T.C"
            fullWidth
            variant="standard"
          />
          { <>{nationalId.length !== 11 || !error ? <></> :<span style={{color: "red"}}>{"T.C isn't valid!"}</span> }</>}

        </Grid>

        <Grid item xs={12}>
          <TextField
            onChange={(e)=>{setIban(e.target.value);}}
            required
            id="BirthDate"
            name="BirthDate"
            label="IBAN"
            fullWidth
            variant="standard"
          />
            { <>{iban.length !== 26 || !error ? <></> :<span style={{color: "red"}}>{"Enter a valid IBAN!"}</span> }</>}
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  sx={{ mt: 3, ml: 1 }}
                  onClick={()=>{CreateHost();}}
                >
                    Create
                </Button>
              </Grid>
        </Grid>
        </Box>
          </Box>
          <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                <Modal.Title> {error ?  "Warning!" : edit?"Edit" :"Create Host"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {
                  error ? <div>an Error Ocurred!</div> : success?
                  <div> Host Created Successfully!</div> : <></>

                }
                </Modal.Body>
                <Modal.Footer>
                  {edit ? <button onClick={()=>{handleClose()}} className="check-btn"> {success||error ? "Okay" : "Create Host"}</button>
                  : <button onClick={()=>{

                    if(success||error){
                      setSuccess(false);
                      seterror(false);
                      handleClose();
                      if(success){
                        navigate('/admin/hosts');
                      }
                      else{
                        window.location.reload();
                      }
                    }
                    else{
                      handleClose();
                    }
                  }} className="btn btn-outline-dark"> {success||error ? "Okay" : "Create Host"}</button>  
                  }
                </Modal.Footer>
                </Modal>    
        </>
    )
}
