import React, { useEffect, useState } from 'react'
import { CheckoutNav } from './comp/CheckoutNav';
import { useNavigate } from 'react-router-dom'
import { Footer} from '../../common';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import DangerousIcon from '@mui/icons-material/Dangerous';
export const CheckoutFail = () => {
  let navigate = useNavigate();

  return (
    <>
    <CheckoutNav/>
    <div className='bg-main'>
        <div className='p-5 pb-2 container'>
          <div className='row'>
            <Card
              variant="outlined"
              sx={{
                  maxHeight: 'max-content',
                  maxWidth: '900px',
                  mx: 'auto',
                  paddingLeft:'60px',
                  paddingRight:'60px',
                  paddingTop:'30px',
                  paddingBottom:'60px'
              }}
              >
              <Typography textAlign={'center'} level="h3"  >
              <DangerousIcon color={'#da5c40'} style={{width:'100px',height:'80px',color:'#ffd6cc' }} />
              <DangerousIcon color={'#da5c40'} style={{width:'100px',height:'80px',color:'#e8a674' }} />
              <DangerousIcon style={{width:'100px',height:'80px',color:'#e79150' }} />
              <DangerousIcon style={{width:'100px',height:'80px',color:'#EF791F' }} />
              <DangerousIcon style={{width:'100px',height:'80px',color:'#da5c40' }} />
              <DangerousIcon style={{width:'100px',height:'80px',color:'#b74c34' }} />
              </Typography>
              <Typography padding={4} style={{color:'#da5c40'}} fontWeight={"800"} textAlign={'center'} level="h2"  >
                Payment Failed!
              </Typography>
              <Typography paddingBottom={5} paddingTop={5} textColor={'#9d9d9d'} textAlign={'center'} level="h5"  >
              Your reservation couldn't been booked. We couldn't Proceed your payment<u style={{fontWeight:"700",color:'#EF791F'}}> Please try again Later!</u>
              </Typography>
              <CardContent
                  sx={{
                  display: 'flex',
                  }}
              >
                  <Button  sx={{alignContent:'center',justifyContent:'center',alignSelf:'center',width:'450px'}} onClick={()=>{ navigate('../home')}} variant="solid">
                      Head to Homepage
                  </Button>
              </CardContent>
            </Card>
        </div>
      </div>
    </div>
    <Footer current={'home'}/>

  </>
    )
}
