import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import { useTranslation } from 'react-i18next';



export const Deposits = ({profit,reserveno,goToHost}) => {
  const {t} = useTranslation();
  return (
    <React.Fragment>
      <Title>{t('ProfitTitle')}</Title>
      <Typography component="p" variant="h4">
        {profit ? profit : '0.00'} TL
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
      </Typography>
      <Title> {t('Reservations')}</Title>
      <Typography component="p" variant="h4">
        {reserveno}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
      </Typography>
      <div>
        <Link color="primary" onClick={goToHost}>
        {t('ViewReservations')}
        </Link>
      </div>
    </React.Fragment>
  );
}