import React from 'react'
import { useTranslation } from 'react-i18next';

export const Second_Info = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className='container overflow-hidden my-5'>
      <div className='row my-5 g-5'>
  
        <div className='col-md-6 col-12 pb-5'>
          <h3 style={{fontSize:25, fontWeight:'700'}}> {t('SecondInfoTitle')} </h3>
          <p style={{textAlign:'justify'}} className='pt-2'> 
          {t('SecondInfoDescription')}
          </p>
        </div>
        <div className='col-md-6 col-12 side-img-cont'>
          <img src='https://cdn.pixabay.com/photo/2021/09/10/11/19/camping-6612823_1280.jpg' className='img-col-6'/>
        </div>
      </div>
    </div>
  )
}
