export function convertToDetailedDate(dateStr) {
    // Split the input date string into day, month, and year components
    const [day, month, year] = dateStr.split('-');
  
    // JavaScript Date object expects month in the range 0-11, so we need to subtract 1 from the month
    const date = new Date(year, month - 1, day);
  
    // Convert the date object to the desired string format
    // Example output: "Mon May 27 2024 00:00:00 GMT+0300 (GMT+03:00)"
    return date.toString();
  }