import React, { useEffect, useState } from 'react';
import { Domain } from '../../../Domain';
import { useNavigate,useLocation } from 'react-router-dom';
import {AdminDashboard} from '../../../common';
import Box from '@mui/material/Box';
import { IconButton, Toolbar } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ToggleButtonGroup from '@mui/joy/ToggleButtonGroup';
import Button from '@mui/joy/Button';
import {CloudinaryWidget} from '../../../common';

export const EditBlog = () => {

    let navigate = useNavigate();
    const location = useLocation();
    
    const data = location.state;

    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [error,seterror] = useState(false);
    const [success,setSuccess] = useState(false);
    const [id,setId] = useState(data.blogId);
    const [Categories, setCategories] = useState([]);
    const temp = {};
    data.category.forEach(element => {
      temp[element] = true;
    });
    const [SelectedCategories, setSelectedCategories] = useState(temp);
    const [title,setTitle] = useState(data.title);
    const [body,setBody] = useState(data.body);
    const [value, setValue] = React.useState(['default']);

    const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
    const [Images, setImages] = useState(data.image);

    const handleUploadSuccess = (url) => {
      setUploadedImageUrl(url);
    };

    function removeObjectsByParameter(objects, parameter, value) {
      return objects.filter(obj => obj[parameter] !== value);
    }

    useEffect(() => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`${Domain}/api/admin/category`, requestOptions)
        .then(response => response.text())
        .then(result => {
          if(result==='Forbidden'){
              localStorage.clear();
              navigate('/login');
          }
          else{
              const resp = JSON.parse(result);
              const filteredObjects = removeObjectsByParameter(resp, 'feature', '88');
              setCategories(filteredObjects);
          }
        })
        .catch(error => console.log('error', error));
    }, [])
    
    const updateBlog = () =>{
      var myHeaders = new Headers();      
      myHeaders.append("x-refresh", localStorage.getItem('refreshToken'));
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);
      const keysArray = Object.keys(SelectedCategories);

      var raw = JSON.stringify({
        "title": title,
        "body": body,
        "image": Images,
        "category": keysArray
      });

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${Domain}/api/admin/blogs/${id}`, requestOptions)
        .then(response => response.text())
        .then(result =>{
          if(result=== 'Forbidden'){
          }
          else{
            console.log(result);
          }
        })
        .catch(error => console.log('error', error));
    }

    
    return (
        <>
          <Box sx={{ display: 'flex' }}>
          <AdminDashboard /> 
          <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            padding: 3,
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Grid sx={{backgroundColor:'#fff',paddingBottom:5,paddingRight:5, marginTop:3,width:'auto',borderRadius:2,marginLeft:2}} container spacing={4}>
            <Grid xs={12}>
                <Typography paddingLeft={3} paddingTop={3} fontWeight={600} variant="h4" >
                    Update Blog
                </Typography>
            </Grid>
        <Grid item xs={12}>
          <TextField
            onChange={(e)=>{setTitle(e.target.value);}}
            value={title}
            required
            id="Title"
            name="Title"
            label="Title"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} >
          <TextField
            value={body}
            placeholder="Write a short description of 120 words about the Blog in a html format <h4> for heading and <p> for normal paragraphs"
            multiline
            fullWidth
            rows={7}
            onChange={(e)=>{setBody(e.target.value);}}
            required
            label="Body"
            variant="standard"
          />
        </Grid>
        <Grid xs={12}>
                <Typography paddingLeft={4} paddingTop={3} fontWeight={600} variant="h5" >
                    Categories
                </Typography>
            </Grid>
        <Grid item xs={12}>
          <div className='container-md' style={{flexWrap:'wrap'}}>
          <ToggleButtonGroup
            spacing={2}
            className="groupsButton"
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
             variant='outline'
          >
            {
              Categories.map(category =>  (
                <Button className="togglers"  variant="solid" onClick={(e)=>{
                  var temp = SelectedCategories;
                  var attribName = category.code;
                  if(temp[attribName]===undefined){
                    temp[attribName] = true
                  }
                  else{
                    delete temp[attribName];
                  }
                  setSelectedCategories(temp);
                }} value={category.code}>{category.name}</Button>

              ))
            }
          </ToggleButtonGroup>
          </div>

        </Grid>
        <Grid xs={12}>
                <Typography paddingLeft={4} paddingTop={3} fontWeight={600} variant="h5" >
                    Images
                </Typography>
            </Grid>
        <Grid xs={12} paddingLeft={4}>
          <CloudinaryWidget
            apiKey="359728572815919"
            cloudName="dyku0cdi3"
            folder={`Blogs/${title}`}
            maxWidth={800}
            maxHeight={600}
            setImages={setImages}
            images={Images}        
            onUploadSuccess={handleUploadSuccess}
            />
          </Grid>
        
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                variant="solid" 
                color='danger'
                  sx={{ mt: 3, ml: 1,}}
                  onClick={()=>{updateBlog();}}
                >
                    Update
                </Button>
              </Grid>
        </Grid>
        </Box>
        </Box>
        </>
    )
}
