import React, { useEffect, useState } from 'react';
import { Domain } from '../../../Domain';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import {AdminDashboard,LoadingModal} from '../../../common';
import Box from '@mui/material/Box';
import { Toolbar } from '@mui/material';
import { ReserveRow } from './components/ReserveRow';
export const ReservationManagement = () => {

    let navigate = useNavigate();
    const [search,setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [reserves, setReserves] = useState([]);
    const [loading,setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [error,seterror] = useState(false);
    const [success,setSuccess] = useState(false);
    const [message,setMessage] = useState('');
    console.log(reserves);

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(`${Domain}/api/admin/reserves`, requestOptions)
        .then(response => response.text())
        .then(result =>{ console.log(result);
            if(result =='Forbidden'){
                localStorage.clear();
                navigate('/login');
            }
            else{
                const res =JSON.parse(result);
                setReserves(res);
            }
        })
        .catch(error => console.log('error', error));
    }, []);


    const DeleteReservation = (id)=>{
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);
      seterror(false);
      var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`${Domain}/api/reserves/${id}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          console.log(result);
          if(result === 'Forbidden'){
              seterror(true);
              localStorage.clear();
              navigate('/login');
          }
          else{
              window.location.reload();
          }
        })
        .catch(error => console.log('error', error));
    }

    const handleSearch = (event) => {
        setSearch(event.target.value);
        const results = reserves.filter(
          (item) =>
            item.title.toLowerCase().includes(event.target.value.toLowerCase())
        );
        setSearchResults(results);
    };

    const transferMoney = (pnr)=>{
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);
      seterror(true);
      setSuccess(false);
      const raw = JSON.stringify({
        "pnr": pnr
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      setLoading(true);
      fetch(`${Domain}/api/payments/transfer`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          console.log("tranfer result",result);
          setLoading(false);
          if(result =='Forbidden'){
            localStorage.clear();
            navigate('/login');
          }
          else{
              const res =JSON.parse(result);
              if(res.status === "success"){
                  seterror(false);
                  setSuccess(true);
                  setMessage("user money transfer success");
              }
              else{
                setMessage("user money transfer failed");
              }
          }

        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          setMessage("user money transfer failed");
        });
    }

    const renderReserves = ()=>(
      <div className='container rounded bg-dark p-5'>
      <div style={{fontWeight:'600'}} className='row p-3 text-light border-bottom border-white'>
            <div className='col-3'> Reserve id</div>
            <div className='col-3 text-center'> Purchase Date</div>
            <div className='col-2  text-center'> Reserve Status </div>
            <div className='col-2 text-center'> Reserve Total Price</div>
            <div className='col-2 text-end'> Actions</div>
        </div> 
        {
          search.length>0 ? 
            reserves.length> 0 && searchResults.map((reserve)=>(
              <ReserveRow 
              reserve={reserve} 
              DeleteReservation={DeleteReservation} 
              transferMoney={transferMoney}
              navigate={navigate}
              />             
              ))
            :
            reserves.length> 0 && reserves.map((reserve)=>(
             <ReserveRow 
             reserve={reserve} 
             DeleteReservation={DeleteReservation} 
             transferMoney={transferMoney}
             navigate={navigate}
             />          
            ))
        }
      </div>
    )
    
    return (
        <>
          { loading && <LoadingModal/>}
          <Box sx={{ display: 'flex' }}>
          <AdminDashboard /> 
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              padding: 3,
              overflow: 'auto',
          }}
        >
          <Toolbar />
            <div className='container p-5 rounded '>
                <div className="events-header">
                    <h1 style={{fontWeight:'600'}} className="ehtitle"> Current Reserves </h1>
                    <button onClick={()=>{
                      navigate('new');
                    }} className='btn btn-outline-dark'> New Reserve </button>
                </div>
                <div className=" row no-gutters justify-content-center">
                <div className='col-12 bar'>
                    <div className="input-group mb-3">
                        <input type="text" className="ps-5 form-control search-inpt" placeholder="Search" aria-label="Search" aria-describedby="basic-addon2" onChange={(e)=>handleSearch(e)}/>
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary my-0 search-btn" type="button"><i className="fas fa-search fa-lg fa-fw"></i></button>
                        </div>
                    </div>
                </div>
                </div>
                {renderReserves()} 
            </div>
            </Box>
          </Box>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
        <Modal.Header closeButton>
        <Modal.Title> {error ?  "Error!" : "Thank you"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {
            <div className='text-center'> {message}  </div>

        }
        </Modal.Body>
        <Modal.Footer>
            {<button onClick={()=>{
            if(success||error){
                setSuccess(false);
                seterror(false);
                handleClose();
                if(success){
                }
                else{
                window.location.reload();
                }
            }
            else{
                handleClose();
            }
            }} className="btn btn-outline-dark"> OK</button>  
            }
        </Modal.Footer>
          </Modal>  
        </>
    )
}
